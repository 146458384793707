/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import './_requestDemo.scss';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SecondaryButton2 from 'shared-components/components/SecondaryButton2';
import HookTextField from 'shared-components/hooks/HookTextField';
import type { IFormValues } from 'formsTypes';
import { useMutation } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Footer from 'client/footer/Footer';
import type { IRequestDemoForm } from '../ClientFormTypes';

function TickIcon(): JSX.Element {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.875 10.3542L6.125 8.60417C5.95833 8.4375 5.75 8.35417 5.5 8.35417C5.26389 8.35417 5.0625 8.4375 4.89583 8.60417C4.71528 8.78472 4.625 9 4.625 9.25C4.625 9.48611 4.71528 9.6875 4.89583 9.85417L7.22917 12.1875C7.39583 12.3681 7.59722 12.4583 7.83333 12.4583C8.08333 12.4583 8.29861 12.3681 8.47917 12.1875L13.125 7.54167C13.2917 7.375 13.375 7.17361 13.375 6.9375C13.375 6.70139 13.2917 6.5 13.125 6.33333C12.9583 6.16667 12.75 6.08333 12.5 6.08333C12.2639 6.08333 12.0625 6.16667 11.8958 6.33333L7.875 10.3542ZM9 17.3333C7.84722 17.3333 6.76389 17.1181 5.75 16.6875C4.73611 16.2431 3.85417 15.6458 3.10417 14.8958C2.35417 14.1458 1.75694 13.2639 1.3125 12.25C0.881945 11.2361 0.666667 10.1528 0.666667 9C0.666667 7.84722 0.881945 6.76389 1.3125 5.75C1.75694 4.73611 2.35417 3.85417 3.10417 3.10417C3.85417 2.35417 4.73611 1.76389 5.75 1.33333C6.76389 0.888888 7.84722 0.666666 9 0.666666C10.1528 0.666666 11.2361 0.888888 12.25 1.33333C13.2639 1.76389 14.1458 2.35417 14.8958 3.10417C15.6458 3.85417 16.2361 4.73611 16.6667 5.75C17.1111 6.76389 17.3333 7.84722 17.3333 9C17.3333 10.1528 17.1111 11.2361 16.6667 12.25C16.2361 13.2639 15.6458 14.1458 14.8958 14.8958C14.1458 15.6458 13.2639 16.2431 12.25 16.6875C11.2361 17.1181 10.1528 17.3333 9 17.3333ZM9 15.5833C10.8194 15.5833 12.3681 14.9444 13.6458 13.6667C14.9375 12.375 15.5833 10.8194 15.5833 9C15.5833 7.18056 14.9375 5.63194 13.6458 4.35417C12.3681 3.0625 10.8194 2.41667 9 2.41667C7.18056 2.41667 5.625 3.0625 4.33333 4.35417C3.05556 5.63194 2.41667 7.18056 2.41667 9C2.41667 10.8194 3.05556 12.375 4.33333 13.6667C5.625 14.9444 7.18056 15.5833 9 15.5833Z' fill='#16A249' />
    </svg>
  );
}

const defaultValues: IRequestDemoForm = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company: '',
  units: 0,
};

const schema = yup.object().shape({
  firstname: yup.string().required('This field is required').matches(/[A-Za-z]/, 'Please enter a valid first name'),
  lastname: yup.string().required('This field is required').matches(/[A-Za-z]/, 'Please enter a valid last name'),
  email: yup
    .string()
    .required('This field is required')
    .email('Your Email is not valid')
    .min(6, 'Email length can not be less then 6 charecters'),
  phone: yup.string().required('This field is required').matches(
    /^[^a-zA-Z]*$/,
    'Please enter a valid phone number',
  ),
  company: yup.string().required('This field is required').matches(/[A-Za-z]/, 'Please enter a valid company name'),
  units: yup.string().required('This field is required'),
});

export default function RequestDemo(): JSX.Element {
  const [isSent, setIsSent] = useState(false);
  const {
    control, formState, handleSubmit, reset,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { mutate: handleRequestDemo, isLoading } = useMutation(async (data: IRequestDemoForm) => axios({
    url: '/api/request_demo/',
    method: 'POST',
    data,
  }), {
    onSuccess: () => {
      setIsSent(true);
    },
  });

  const { errors } = formState;

  const onSubmit = (payload: IRequestDemoForm): void => {
    handleRequestDemo(payload);
    reset(defaultValues);
  };

  return (
    <>
      <div className='demo-container'>
        <div className='demo-content'>

          {isSent && (
            <div className='thank-you-message'>
              <span className='me-2'><TickIcon /></span>
              Thank you! Message received. We’ll get back to you shortly!
            </div>
          )}
          {isSent ? <h1>Find out what Strada can do for you</h1>
            : <h1> Discover what Strada can do for you </h1>}
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <div className='input-wrapper'>
              <div className='input-wrap' style={{ marginRight: '32px' }}>
                <p className='input-label'>First name *</p>
                <HookTextField
                  name='firstname'
                  control={control}
                  errors={errors}
                  type='text'
                  maxLength={150}
                  notPreventDefault
                />
              </div>
              <div className='input-wrap'>
                <p className='input-label'>Last name *</p>
                <HookTextField
                  name='lastname'
                  control={control}
                  errors={errors}
                  type='text'
                  maxLength={150}
                  notPreventDefault
                />
              </div>
            </div>
            <div className='input-wrapper'>
              <div className='input-wrap' style={{ marginRight: '32px' }}>
                <p className='input-label'>Work email address *</p>
                <HookTextField
                  name='email'
                  control={control}
                  errors={errors}
                  type='text'
                  maxLength={150}
                  notPreventDefault
                />
              </div>
              <div className='input-wrap'>
                <p className='input-label'>Phone number *</p>
                <HookTextField
                  name='phone'
                  placeholder='+1 (555) 000-0000'
                  control={control}
                  errors={errors}
                  type='text'
                  maxLength={16}
                  notPreventDefault
                />
              </div>

            </div>
            <div className='input-wrapper'>
              {/* className='input-wrap' */}
              <div style={{ width: '100%' }}>
                <p className='input-label'>Company name *</p>
                <HookTextField
                  name='company'
                  control={control}
                  errors={errors}
                  type='text'
                  maxLength={150}
                  notPreventDefault
                />
              </div>
              {/* <div className='input-wrap'>
                <p className='input-label'>Total units managed in portfolio *</p>
                <HookTextField
                  name='units'
                  control={control}
                  errors={errors}
                  type='number'
                />
              </div> */}
            </div>
            {!isLoading
              ? (
                <SecondaryButton2
                  style={{
                    marginTop: '16px', textTransform: 'initial',
                  }}
                  type='submit'
                  fullWidth
                >
                  {' '}
                  Request a Demo
                </SecondaryButton2>
              )
              : (
                <div className='progress-div'>
                  <CircularProgress style={{ width: '20px', height: '20px', color: '#A1A1AA' }} />
                </div>
              )}
          </form>
        </div>

      </div>
      <Footer />
    </>
  );
}
