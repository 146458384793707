/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  Button, Autocomplete, TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import type { RootState } from 'mainStore';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import type { IFormValues } from 'formsTypes';
import HookTextField from 'shared-components/hooks/HookTextField';

interface IOption {
  id: number | null;
  full_name: string;
  email: string;
  vendor_company: string | undefined;
}

interface IAddContactDialog {
  openAddVendorForm: boolean;
  setOpenAddVendorForm: (option: boolean) => void;
  inputValue: string;
  selectedOptions: IOption[];
  setSelectedOptions: (option: IOption[]) => void;
}
interface ISchedulingVendor {
  id: number;
  job: string;
  name: string;
  label: string;
}
interface IContactForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  vendor: ISchedulingVendor | number | null;
  full_name: string;
  email: string;
  phone: string;
  role: string;
  note?: string;
}
interface Iresponse {
  message: string;
}
interface Idata {
  response: Iresponse;
}
interface IErrorResponse {
  data: Idata;
}
interface Idetail {
  created_at: string;
  email: string;
  full_name: string;
  id: number;
  note: string;
  phone: string;
  role: string;
  unique_position_key: string;
  updated_at: string;
  vendor: number;
}
interface Iresdata {
  detail: Idetail;
}
interface IContactResponse {
  data: Iresdata;
}

export default function AddContactDialog(AddContactDialogProps: IAddContactDialog): JSX.Element {
  const {
    openAddVendorForm, setOpenAddVendorForm, inputValue, selectedOptions, setSelectedOptions,
  } = AddContactDialogProps;
  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );
  const { enqueueSnackbar } = useSnackbar();

  const [vendorList, setVendorList] = useState<ISchedulingVendor[]>([]);
  const [vendor, setVendor] = useState<ISchedulingVendor | null>(null);
  const queryClient = useQueryClient();
  // eslint-disable-next-line no-useless-escape
  const emailValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  useQuery(
    'get-vendor-contact',
    async () => axios({
      url: `api/filter/add-vendor-contact/?workspace=${currentWorkspace.id}`,
      method: 'GET',
    }),
    {
      onSuccess: (res) => {
        if (res.data?.detail !== undefined && res.data.detail.length !== 0) {
          const data: ISchedulingVendor[] = res.data.detail;
          setVendorList(data);
        }
      },
    },
  );
  const { mutate: handlePost } = useMutation(async (dataToPost: IContactForm) => axios({
    url: `/api/filter/add-vendor-contact/?workspace=${currentWorkspace.id}`,
    method: 'POST',
    data: dataToPost,
  }), {
    onSuccess: async (res: IContactResponse, payload): Promise<void> => {
      enqueueSnackbar('Vendor contact was added');
      setSelectedOptions([...selectedOptions, {
        id: res.data.detail.id, full_name: res.data.detail.full_name, email: res.data.detail.email, vendor_company: typeof payload.vendor !== 'number' ? payload.vendor?.name : '',
      }]);
      setOpenAddVendorForm(false);
      await queryClient.invalidateQueries('get-vendor-contact-list').then();
    },
    onError: (err: IErrorResponse) => {
      enqueueSnackbar(err.data.response.message, {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  const defaultValues: IContactForm = {
    vendor: null,
    full_name: '',
    email: '',
    phone: '',
    role: '',
    note: '',
  };

  const schema = yup.object().shape({
    // vendor: yup.string().trim().required('Please Select Vendor'),
    full_name: yup.string().trim().required('Please enter your Full Name').matches(/[A-Za-z]/, 'Please enter a valid Full Name'),
    email: yup.string().trim().email('Enter a valid email').required('Please enter your Email'),
    phone: yup.string().trim().optional().matches(
      /^[^a-zA-Z]*$/,
      'Enter a valid phone number',
    ),
    role: yup.string().trim().required('Please enter your Role'),
  });

  const {
    control, formState, setValue, watch,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  useEffect(() => {
    setValue('email', inputValue, { shouldDirty: true, shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddContact = (): void => {
    const payload: IContactForm = {
      vendor: watch('vendor'),
      full_name: watch('full_name'),
      email: watch('email'),
      phone: watch('phone'),
      role: watch('role'),
      note: watch('note') !== undefined ? watch('note') : '',
    };
    handlePost(payload);
  };

  const getVendorDisabledCheck = (): boolean => {
    if (watch('full_name') === '' || !emailValidation.test(watch('email'))
      || watch('role') === '' || !vendor) {
      return true;
    }
    return false;
  };
  const handleVendorValue = (value: ISchedulingVendor): void => {
    setVendor(value);
    setValue('vendor', value, { shouldDirty: true });
  };
  return (
    <form>
      <Dialog
        open={openAddVendorForm}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          elevation: 0,
          style: {
            width: '600px',
          },
        }}
      >
        <DialogTitle>
          <div>
            <p style={{ fontSize: '20px', color: 'rgba(33, 33, 33, 0.87)' }}> Add contact </p>
          </div>
        </DialogTitle>

        <DialogContent>
          <Autocomplete
            // disablePortal
            disableClearable
            options={vendorList}
            // defaultValue={vendor}
            value={vendor !== null ? vendor : undefined}
            style={{ marginBottom: errors.vendor ? '0px' : '20px', marginTop: '5px' }}
            onChange={(obj: React.SyntheticEvent, value): void => { handleVendorValue(value); }}
            renderInput={(params): JSX.Element => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                placeholder='Search by name'
                name='vendor'
                label='Choose vendor*'
              />
            )}
          />
          {errors.vendor
            && (
              <label className='error-message'>
                Select Vendor
              </label>
            )}
          <HookTextField
            name='full_name'
            label='Full Name'
            control={control}
            errors={errors}
            maxLength={150}
          />
          <HookTextField
            name='email'
            label='Email'
            control={control}
            errors={errors}
            maxLength={150}
          />
          <HookTextField
            name='phone'
            label='Phone'
            control={control}
            errors={errors}
            maxLength={16}
          />
          <HookTextField
            name='role'
            label='Role'
            control={control}
            errors={errors}
            maxLength={150}
          />
          <HookTextField
            name='note'
            label='Type your notes(optional)'
            control={control}
            errors={errors}
            multiline
            rows={3}
          />

        </DialogContent>

        <DialogActions>

          <Button
            className='text-transform-none'
            style={{ color: '#00CFA1' }}
            onClick={(): void => { setOpenAddVendorForm(false); }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            onClick={(): void => { handleAddContact(); }}
            variant='contained'
            disabled={getVendorDisabledCheck()}
            className='ms-2 text-transform-none'
            style={{ color: getVendorDisabledCheck() ? '212121' : 'white', background: getVendorDisabledCheck() ? 'E4E4E4' : '#00CFA1' }}
          >
            Save contact
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
