import React from 'react';
import Content from './Content';
import './_leaseView.scss';
import Sidebar from '../../sidebar';

function index(): JSX.Element {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar variant='main' activeLink='lease' />
      <Content />
    </div>
  );
}

export default index;
