/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Checkbox, Popover, Button, Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import Papa from 'papaparse';
import LinearProgress from '@mui/material/LinearProgress';
import { useSnackbar } from 'notistack';
import CustomLoader from 'shared-components/components/CustomLoader';
import PrimayButton from 'shared-components/components/PrimayButton';

interface AllCSVs {
  file: File | FormData | null;
  user_columns: ColumnMappings;
  ignore_columns: IColOptions[];
  errorIndex: number;
}
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ColumnMappings = Record<string, string>;

interface IColOptions {
  columnName: string;
  isChecked: boolean;
}
interface IMessage {
  message: string;
}
interface IDetail {
  message: string;
  result: IMessage;
  task_id: string;
}
interface IData {
  detail: IDetail;
}
interface IResponse {
  data: IData;
}

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#eeeeee',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00CFA1',
  },
}))(LinearProgress);

export default function ImportVendorCSV(): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);

  const [allCSVs, setAllCSVs] = useState<AllCSVs[]>([]);
  const [currentCSV, setCurrentCSV] = useState<AllCSVs | null>(null);
  const [currentCSVIndex, setCurrentCSVIndex] = useState<number>(-1);
  const [csvData, setCSVData] = useState<string[][] | null>(null);
  const [columnMappings, setColumnMappings] = useState({});
  const [dafaultHeadings, setDafaultHeadings] = useState<string[]>([]);
  const [options, setOptions] = useState<string[][]>([]);
  const [errorIndex, setErrorIndex] = useState<number>(-1);
  const [columnOptions, setColumnOptions] = useState<IColOptions[]>([]);
  const [selectedColumnOptions, setSelectedColumnOptions] = useState<IColOptions[]>([]);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const defaultOption = [
    'vendor',
    'vendor_job',
    'vendor_phone',
    'vendor_email',
    'vendor_note',
    'contact_full_name',
    'contact_email',
    'contact_phone',
    'contact_note',
    'contact_role',
  ];

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentCSV !== null) {
      setAllCSVs(allCSVs.map((item, index): AllCSVs => {
        if (index === currentCSVIndex) {
          item.user_columns = columnMappings;
          setCurrentCSV(item);
        }
        return item;
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnMappings]);
  useEffect(() => {
    if (currentCSV !== null) {
      setAllCSVs(allCSVs.map((item, index): AllCSVs => {
        if (index === currentCSVIndex) {
          item.ignore_columns = selectedColumnOptions;
          item.errorIndex = errorIndex;
          setCurrentCSV(item);
        }
        return item;
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorIndex]);

  const { isLoading } = useQuery(
    'get-default-headings',
    async () => axios({
      url: `/api/vendor-contact/import/?workspace=${currentWorkspace.id}`,
      method: 'get',
    }),
    {
      onSuccess: (res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setDafaultHeadings(res.data.detail.columns);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const objectArray: IColOptions[] = res.data.detail.columns.map((str: string): IColOptions => {
          const columnName = str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
          return { columnName, isChecked: true };
        });
        const filteredArray: IColOptions[] = objectArray.filter((obj: IColOptions) => obj.columnName !== 'Vendor' && obj.columnName !== 'Full Name' && obj.columnName !== 'Email');
        setColumnOptions(filteredArray);
      },
    },
  );
  const { mutate: getCeleryProgress } = useMutation(async (taskID: string) => axios({
    url: `/api/vendor-contact/import/get_task_progress/?task_id=${taskID}`,
    method: 'get',
  }), {
    onSuccess: (res, taskID) => {
      setProgressValue(Number(res.data.detail.progress.percent));
      if (res.data.detail.progress.percent !== 100 || res.data.detail.result === undefined) {
        setTimeout((): void => {
          getCeleryProgress(taskID);
        }, 1000);
      } else if (res.data.detail.result.message !== undefined) {
        enqueueSnackbar(res.data.detail.result.message);
        navigate('/workspace/settings/vendors');
      } else if (res.data.detail.success === false) {
        const msg = res.data.detail.result ? `Import Unsuccessful: ${res.data.detail.result}` : 'Error in importing data. Please check your file and try again.';

        enqueueSnackbar(msg, {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });

        setOpenImportDialog(false);
        // navigate('/workspace/settings/vendors');
      } else {
        enqueueSnackbar('CSV files are Imported');
        navigate('/workspace/settings/vendors');
      }
    },
    onError: (err: IResponse) => {
      enqueueSnackbar(err.data.detail.result.message, {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });

      setOpenImportDialog(false);
      // navigate('/workspace/settings/vendors');
    },
  });

  const { mutate: importAllCSVs } = useMutation(async (data: FormData) => axios({
    url: `/api/vendor-contact/import/?workspace=${currentWorkspace.id}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  }), {
    onSuccess: (res: IResponse) => {
      setOpenImportDialog(true);
      getCeleryProgress(res.data.detail.task_id);
    },
    onError: (err: IResponse) => {
      setOpenImportDialog(false);
      enqueueSnackbar(err.data.detail.message, {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  async function parseCsvFile(file: File): Promise<Papa.ParseResult<unknown>> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (result) => {
          resolve(result);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const handlePayload = async (): Promise<void> => {
    const formData = new FormData();
    async function processAllCSVs(allItems: AllCSVs[]): Promise<void> {
      for (const item of allItems) {
        if (item.file !== null && !(item.file instanceof FormData)) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const result = await parseCsvFile(item.file);
            const updatedData: string[][] = result.data as string[][];
            const numbersWithUnnamed: number[] = Object.keys(item.user_columns).filter((val) => !defaultOption.includes(val ? val.trim().toLowerCase() : '')).map((key) => Number(key.split(' ')[1]));
            numbersWithUnnamed.forEach((columnIndex) => {
              updatedData[0][columnIndex - 1] = `Unnamed ${columnIndex}`;
            });
            const currentFileName = item.file.name || 'updatedFile.csv';
            const csvBlob = new Blob([Papa.unparse(updatedData)], { type: 'text/csv' });
            const csvFile = new File([csvBlob], currentFileName, { type: 'text/csv' });
            formData.append('files', csvFile);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error parsing CSV file:', error);
          }
        }
        formData.append('user_columns', JSON.stringify(item.user_columns));
        formData.append('ignore_columns', JSON.stringify(item.ignore_columns));
      }
    }

    await processAllCSVs(allCSVs).then(() => {
      importAllCSVs(formData);
    });
  };
  const getTemplate = (): void => {
    if (dafaultHeadings.length > 0) {
      const dataArray = [dafaultHeadings];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const csvContent = `data:text/csv;charset=utf-8,${dataArray.map((row): string => row.join(',')).join('\n')}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'template.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleCSVFile = (singleFile: AllCSVs, index: number): void => {
    setCurrentCSV(singleFile);
    setCurrentCSVIndex(index);
    if (singleFile.file !== null && !(singleFile.file instanceof FormData)) {
      Papa.parse(singleFile.file, {
        complete: (result) => {
          const parsedData = result.data as string[][];
          setCSVData(parsedData);
          setColumnMappings(singleFile.user_columns);
          setSelectedColumnOptions(singleFile.ignore_columns);
          setErrorIndex(singleFile.errorIndex);
          const tmpOptions = parsedData[0].map((op, indx): string[] => {
            if (op === '' || op.includes('Unnamed:') || !defaultOption.includes(op.trim().toLowerCase())) {
              return [`Unnamed ${indx + 1}`, ...dafaultHeadings];
            }

            const filteredHeadings = dafaultHeadings.filter((heading) => heading.toLowerCase().trim() !== op.toLowerCase().trim());

            return [op, ...filteredHeadings];
          });
          setOptions(tmpOptions);
        },
      });
    }
  };

  const handleColumnMapping = (column: string, selectedTemplateColumn: string, index: number): void => {
    const regex = /event|\W|\s/i;
    if (regex.test(selectedTemplateColumn)) {
      setErrorIndex(index);
    }
    setColumnMappings((prevMappings) => ({
      ...prevMappings,
      [column]: selectedTemplateColumn,
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop: async (accepted: File[]) => {
      const allData: AllCSVs[] = [];
      let missingFiles = 0;

      const parseCSVFile = async (file: File): Promise<{ parsedFile: File | null }> => new Promise((resolve) => {
        Papa.parse(file, {
          skipEmptyLines: 'greedy',
          complete: (result) => {
            const parsedData = result.data as string[][];
            if (parsedData.length >= 2) {
              resolve({ parsedFile: file });
            } else {
              resolve({ parsedFile: null });
            }
          },
        });
      });

      for (const file of accepted) {
        const processData = async (file: File): Promise<void> => {
          const { parsedFile } = await parseCSVFile(file);
          if (parsedFile) {
            const obj = {
              file,
              user_columns: {},
              errorIndex: -1,
              ignore_columns: columnOptions.map((option) => ({ ...option })),
            };
            allData.push(obj);
          } else {
            missingFiles += 1;
          }
        };

        await processData(file);
      }

      if (missingFiles !== 0) {
        enqueueSnackbar(
          missingFiles === 1 ? '1 file has 0 or fewer rows' : `${missingFiles} files have 0 or fewer rows`,
          {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
            ),
          },
        );
      }

      setAllCSVs([...allCSVs, ...allData]);
    },
  });

  const deleteCSVFile = (index: number): void => {
    const updatedArray = allCSVs.filter((_, i) => i !== index);
    setAllCSVs(updatedArray);
  };

  // eslint-disable-next-line no-nested-ternary
  const getCurrentOption = (option: string): string => (!currentCSV ? option : Object.keys(currentCSV.user_columns).find((key) => key.toLowerCase().includes(option.toLowerCase())) != null ? currentCSV.user_columns[option] : option);

  const handleCheckboxChange = (index: number): void => {
    setSelectedColumnOptions((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].isChecked = !newArray[index].isChecked;
      return newArray;
    });
  };

  return (
    <div className='import-csv-wrapper'>
      <div className='import-csv-header'>
        <p>Import Vendor Contacts from CSV</p>
        <div
          aria-hidden='true'
          onClick={(): void => {
            navigate('/workspace/settings/vendors');
          }}
          className='cursor-pointer'
        >
          <CloseIcon />
        </div>

      </div>
      {isLoading ? <div className='d-flex justify-center align-items-center w-100' style={{ height: '90vh' }}><CustomLoader /></div> : (
        <div>
          {currentCSV === null
            ? (
              <div className='import-csv-body'>
                <p className='intro-text'>
                  Download
                  {' '}
                  <span aria-hidden='true' onClick={(): void => { getTemplate(); }}>template</span>
                  {' '}
                  to import the vendor contacts
                </p>
                <div {...getRootProps({ className: 'my-4 csv-dropzone' })}>
                  <input {...getInputProps()} />
                  <UploadIcon />
                  <p className='heading'>Select CSV files to import </p>
                  <p className='sub-heading'>or drag and drop here </p>
                </div>
                {allCSVs.map((singleFile, index): JSX.Element => (
                  <div className='single-csv-wrap'>
                    <div className='single-csv-div'>
                      <p>{singleFile.file !== null && !(singleFile.file instanceof FormData) ? singleFile.file.name : ''}</p>
                    </div>
                    <div className='remove-csv' aria-hidden='true' onClick={(): void => { deleteCSVFile(index); }}>Remove</div>
                  </div>
                ))}
                {allCSVs.length > 0
            && <PrimayButton style={{ width: '130px', margin: '15px 0px' }} onClick={(): void => { handleCSVFile(allCSVs[0], 0); }}> Upload </PrimayButton> }
              </div>
            )
            : (
              <div>
                {csvData !== null && csvData.length > 0 && (
                  <div>
                    <div className='files-heading-wrap'>
                      <div className='file-name-div'>
                        <p>{currentCSV.file !== null && !(currentCSV.file instanceof FormData) ? currentCSV.file.name : ''}</p>
                      </div>
                      <div className='file-count-div'>
                        <p>{`${currentCSVIndex + 1} of ${allCSVs.length} files`}</p>
                        <div
                          className='me-3'
                          style={{ cursor: currentCSVIndex !== 0 ? 'pointer' : 'auto', color: currentCSVIndex !== 0 ? 'black' : '#B1B1B1' }}
                          aria-hidden='true'
                          onClick={(): void => { currentCSVIndex !== 0 ? handleCSVFile(allCSVs[currentCSVIndex - 1], currentCSVIndex - 1) : null; }}
                        >
                          <ArrowBackIcon />
                        </div>
                        <div
                          style={{ cursor: currentCSVIndex !== allCSVs.length - 1 ? 'pointer' : 'auto', color: currentCSVIndex !== allCSVs.length - 1 ? 'black' : '#B1B1B1' }}
                          aria-hidden='true'
                          onClick={(): void => { currentCSVIndex !== allCSVs.length - 1 ? handleCSVFile(allCSVs[currentCSVIndex + 1], currentCSVIndex + 1) : null; }}
                        >
                          <ArrowForwardIcon />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p className='single-csv-heading'>Here&apos;s your data that will be imported. We&apos;ll create a vendor contact for each of the row showing below.</p>
                      <div className='d-flex'>
                        <div className='adjust-import'>
                          <div className='d-flex align-items-center cursor-pointer' aria-hidden='true' onClick={(e): void => { handleClick(e); }}>
                            <SettingsIcon />
                            <div className='import-text'>Adjust your import</div>
                          </div>
                          <Popover
                            open={anchorEl !== null}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <div className='import-popover'>
                              {selectedColumnOptions.length > 0 && selectedColumnOptions.map((option, index) => (
                                <div className='single-option' key={`${option.columnName}`}>
                                  <Checkbox checked={option.isChecked} onChange={(): void => { handleCheckboxChange(index); }} />
                                  <div className='name-side'>
                                    <p>
                                      {' '}
                                      {option.columnName}
                                      {' '}
                                    </p>
                                  </div>
                                </div>
                              ))}

                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                    <div />
                    <div className='table-container'>
                      <table className='csv-table'>
                        <thead>
                          <tr>
                            {options.map((selectOptions, index) => (
                              <th>
                                <select
                                  onChange={(event): void => { handleColumnMapping(selectOptions[0], event.target.value, index); }}
                                  value={getCurrentOption(selectOptions[0])}
                                >
                                  {
                                    selectOptions.map((option) => <option value={option}>{option}</option>)
                                  }
                                </select>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {csvData.slice(1).map((row, index) => (
                            <tr key={index} className='table-row'>
                              {row.map((value, rowIndex) => (
                                <td key={rowIndex}>{value}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className='csv-buttons-wrapper'>
                      <span>
                        <Button
                          style={{
                            color: 'white', background: '#00CFA1', width: '130px', margin: '0 50px', textTransform: 'inherit',
                          }}
                          onClick={async (): Promise<void> => {
                            await handlePayload();
                          }}
                        >
                          Import
                        </Button>
                      </span>
                      <p className='back-btn' aria-hidden='true' onClick={(): void => { setCurrentCSV(null); }}>Back</p>
                    </div>
                  </div>
                )}
              </div>
            ) }
        </div>
      )}
      <Dialog
        open={openImportDialog}
        keepMounted
      >
        <DialogContent style={{ width: 500, padding: '24px' }}>
          <div className='dialog-heading'>
            Importing
            {' '}
            {allCSVs.length}
            {' '}
            CSV files
          </div>
          <div style={{ margin: '10px 0px' }}><BorderLinearProgress variant='determinate' value={progressValue} /></div>
          <span
            className='dialog-body'
            style={{
              fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)', fontWeight: '400', marginTop: '20px',
            }}
          >
            This import will continue in the background, by returning to Vendor Contacts.
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button variant='contained' onClick={(): void => { navigate('/workspace/settings/vendors'); }} style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }} color='primary' autoFocus>
            Return to the Vendor Contacts
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
