/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable  @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useState } from 'react';
import {
  Checkbox, List, ListItem, ListItemText, ListItemIcon, IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import type { CheckboxLeaseTreeProps } from '../types';

function CheckboxLeaseTree(props: CheckboxLeaseTreeProps): JSX.Element {
  const {
    data, selectedItems, handleCheckboxChange, indeterminateStates,
  } = props;

  const [expandedStates, setExpandedStates] = useState<Record<string, boolean>>({});

  const handleExpandClick = (id: string): void => {
    setExpandedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <List>
      {data.map((heading) => {
        const isMainHeadingChecked = heading.sub_headings.length === 0
          ? selectedItems.includes(heading.id.toString())
          : heading.sub_headings.every((sub) => selectedItems.includes(`${heading.id}-${sub.id}`));
        const isIndeterminate = indeterminateStates[heading.id.toString()] || false;
        const isExpanded = expandedStates[heading.id.toString()] || false;

        return (
          <React.Fragment key={heading.id}>
            <ListItem>
              <ListItemIcon style={{ minWidth: 'auto' }}>
                <Checkbox
                  edge='start'
                  checked={isMainHeadingChecked}
                  indeterminate={isIndeterminate}
                  tabIndex={-1}
                  disableRipple
                  onClick={(): void => { handleCheckboxChange(heading.id.toString(), false); }}
                />
              </ListItemIcon>
              <ListItemText primary={heading.heading_name} />
              {heading.sub_headings.length > 0 && (
                <IconButton onClick={(): void => { handleExpandClick(heading.id.toString()); }}>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </ListItem>
            {isExpanded && heading.sub_headings.length > 0 && heading.sub_headings.map((sub) => (
              <ListItem key={sub.id} onClick={(): void => { handleCheckboxChange(`${heading.id}-${sub.id}`, true); }} style={{ paddingLeft: '3em' }}>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  <Checkbox
                    edge='start'
                    checked={selectedItems.includes(`${heading.id}-${sub.id}`)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={sub.sub_heading_name} />
              </ListItem>
            ))}
          </React.Fragment>
        );
      })}
    </List>
  );
}

export default CheckboxLeaseTree;
