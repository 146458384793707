/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Button, Checkbox, Autocomplete, TextField,
} from '@mui/material';
import { useQuery } from 'react-query';
import type { RootState } from 'mainStore';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CustomLoader from 'shared-components/components/CustomLoader';
import StradaSearch from 'admin/buildingSection/budget-calendar/components/StradaSearch';
import './_contactListingDialog.scss';

interface IOption {
  id: number | null;
  full_name: string;
  email: string;
  vendor_company: string | undefined;
  role?: string | null;
}
interface ISchedulingVendor {
  id: number | null;
  job: string;
  name: string;
  label: string;
}

interface IContactListingDialog {
  openContactListingDialog: boolean;
  setOpenContactListingDialog: (option: boolean) => void;
  selectedOptions: IOption[];
  setSelectedOptions: (option: IOption[]) => void;
}
function TickIcon(): JSX.Element {
  return (
    <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.75 8.12891L12.6953 0.183594L13.75 1.23828L4.75 10.2383L0.566406 6.05469L1.62109 5L4.75 8.12891Z' fill='#212121' fillOpacity='0.87' />
    </svg>
  );
}

export default function ContactListingDialog(ContactListingDialogProps: IContactListingDialog): JSX.Element {
  const {
    openContactListingDialog, setOpenContactListingDialog, selectedOptions, setSelectedOptions,
  } = ContactListingDialogProps;
  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );

  const [options, setOptions] = useState<IOption[]>([]);
  const [newContacts, setNewContacts] = useState<IOption[]>([]);
  const [vendor, setVendor] = useState<ISchedulingVendor>({
    id: null,
    job: '',
    name: 'All vendors',
    label: 'All vendors',
  });
  const [vendorList, setVendorList] = useState<ISchedulingVendor[]>([{
    id: null,
    job: '',
    name: 'All vendors',
    label: 'All vendors',
  }]);
  const [search, setSearch] = useState<string>('');
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const { isLoading } = useQuery(
    ['get-vendor-contact-list', vendor],
    async () => axios({
      url: `api/filter/vendor-contact-list/?workspace=${currentWorkspace.id}`,
      method: 'GET',
      params: {
        vendors: vendor.id !== null ? [vendor.id] : '',
      },
    }),
    {
      onSuccess: (res) => {
        if (res.data?.detail !== undefined && res.data.detail.length !== 0) {
          const data: IOption[] = res.data.detail;
          setOptions(data.filter((item) => item.id !== null));
        }
      },
    },
  );
  useQuery(
    'get-vendor-contact',
    async () => axios({
      url: `api/filter/add-vendor-contact/?workspace=${currentWorkspace.id}`,
      method: 'GET',
    }),
    {
      onSuccess: (res) => {
        if (res.data?.detail !== undefined && res.data.detail.length !== 0) {
          const data: ISchedulingVendor[] = res.data.detail;
          setVendorList([...vendorList, ...data]);
        }
      },
    },
  );
  const handleVendorValue = (value: ISchedulingVendor): void => {
    setVendor(value);
    setIsSelectAll(false);
  };

  const handleNewConatct = (contact: IOption): void => {
    if (newContacts.some((item) => item.id === contact.id)) {
      const filteredArray = newContacts.filter((item) => item.id !== contact.id);
      setNewContacts(filteredArray);
    } else {
      const newArray = newContacts.concat(contact);
      setNewContacts(newArray);
    }
  };
  React.useEffect(() => {
    if (newContacts.length === 0) {
      setIsSelectAll(false);
    }
  }, [newContacts]);

  const lowercasedFilter = search.toLowerCase();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const filterData = options.filter((item: IOption) => Object?.keys(item)?.some((key) => (key === 'full_name' || key === 'email' || key === 'role') && item[key]?.toString()?.toLowerCase()?.includes(lowercasedFilter)));

  const handleSelectAll = (): void => {
    if (isSelectAll) {
      setNewContacts([]);
      setIsSelectAll(false);
    } else {
      if (filterData.length === 0) {
        return;
      }
      setIsSelectAll(true);
      setNewContacts(filterData.filter((item) => item.email !== '' && item.email !== null));
    }
  };
  const checkDisabled = (): boolean => {
    if (newContacts.length === 0) {
      return true;
    }
    return false;
  };
  const handleAddContact = (): void => {
    const allSelectedOptions = selectedOptions.concat(newContacts).reduce((acc: IOption[], curr: IOption) => {
      if ((curr.id != null) && !acc.some((obj) => obj.id === curr.id)) {
        acc.push(curr);
      }
      return acc;
    }, []);
    setSelectedOptions(allSelectedOptions);
    setOpenContactListingDialog(false);
  };

  return (
    <form>
      <Dialog
        open={openContactListingDialog}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          elevation: 0,
          style: {
            width: '800px',
          },
        }}
      >
        <DialogTitle>
          <div>
            <p style={{ fontSize: '20px', color: 'rgba(33, 33, 33, 0.87)', fontWeight: '500' }}> Contacts </p>
            <div className='d-flex justify-space-between mt-2 mb-1'>
              <div className={`select-all-btn ${filterData.length === 0 ? 'select-disabled' : ''}`} aria-hidden='true' onClick={(): void => { handleSelectAll(); }}>
                <TickIcon />
                {isSelectAll
                  ? <p> Unselect all</p>
                  : <p>Select all</p>}
              </div>
              <div className='vendor-filter-wrapper'>
                <Autocomplete
                  disableClearable
                  options={vendorList}
                  value={vendor}
                  style={{ width: '165px' }}
                  onChange={(obj: React.SyntheticEvent, value): void => { handleVendorValue(value); }}
                  renderInput={(params): JSX.Element => (
                    <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      placeholder='Search by name'
                      name='vendor'
                      // style={{ height: '25px' }}
                      size='small'
                    />
                  )}
                />
              </div>
              <div className='search-wrapper'>
                <StradaSearch value={search} setSearch={setSearch} placeholder='Search' />
              </div>
            </div>
          </div>
        </DialogTitle>

        <DialogContent style={{ padding: '0px', height: '400px' }}>
          {isLoading ? <div style={{ height: '400px' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div> : (
            <div>
              {filterData.length > 0 && filterData.filter((item) => item.id !== null).map((item) => (
                <div className='d-flex align-items-center py-1 ps-3' style={{ borderTop: '1px solid #E4E4E4' }}>
                  <div className='d-flex' style={{ width: '40%' }}>
                    <Checkbox
                      checked={newContacts.includes(item)}
                      disabled={item.email === '' || item.email === null}
                      onChange={(): void => {
                        handleNewConatct(item);
                      }}
                    />
                    <p
                      className='ms-2'
                      style={{
                        fontSize: '14px', color: 'rgba(33, 33, 33, 0.87)', marginTop: '0.6rem', wordBreak: 'break-all', marginRight: '5px',
                      }}
                    >
                      {item.full_name}
                    </p>
                  </div>
                  <div style={{ width: '30%' }}>
                    <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)' }}>{item.email !== null && item.email !== '' ? item.email : '--'}</p>
                  </div>
                  <div style={{ margin: 'auto' }}>
                    <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)' }}>{item.role !== null && item.role !== '' ? item.role : '--'}</p>
                  </div>
                </div>

              ))}
            </div>
          )}
        </DialogContent>

        <DialogActions style={{ borderTop: '1px solid #E4E4E4' }}>

          <Button
            className='text-transform-none'
            style={{ color: '#00CFA1' }}
            onClick={(): void => { setOpenContactListingDialog(false); }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            onClick={(): void => { handleAddContact(); }}
            variant='contained'
            disabled={checkDisabled()}
            className='ms-2 text-transform-none'
            style={{ color: checkDisabled() ? 'grey' : 'white' }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
