import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CustomLoader from 'shared-components/components/CustomLoader';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  outline: 'none',
  textAlign: 'center',
};

interface Iprops {
  previewURL: string;
  setPreviewURL: (val: string) => void;
}
export default function PreviewModal({ previewURL, setPreviewURL }: Iprops): JSX.Element {
  const [loading, setLoading] = React.useState(true);

  return (
    <div>
      <Modal
        open
        onClose={(): void => { setPreviewURL(''); }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='modal-focus-visible'>
          {loading && <CustomLoader />}
          <img
            onLoad={(): void => { setLoading(false); }}
            src={`${process.env.REACT_APP_ASSESTS_URL}${previewURL}`}
            alt='preview'
            style={{ width: '100%' }}
          />

        </Box>
      </Modal>
    </div>
  );
}
