/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Button, Grid, Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useMutation, useQuery } from 'react-query';
import CustomLoader from 'shared-components/components/CustomLoader';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import './_create-checklist-template.scss';
import Notifications, { notify } from 'react-notify-toast';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from '@mui/styles';
import InputField from 'shared-components/inputs/InputField';
import Header from './Header';
import AllTasks from './AllTasks';
import TaskDetails from './TaskDetails';
import type {
  IState, ITasks, IErrorResponse, IContent,
} from './types';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#eeeeee',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00CFA1',
  },
}))(LinearProgress);

export default function CreateChecklistTemplate(): JSX.Element {
  const { templateId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taskId = queryParams.get('taskId');

  const customTemplateName = localStorage.getItem('template_name');
  const customTemplateDetail = localStorage.getItem('description');

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [checklistTemplateName, setChecklistTemplateName] = useState<string>('');
  const [data, setData] = useState<ITasks[]>();
  const [templateName, setTemplateName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [focusedTask, setFocusedTask] = useState<ITasks>();
  const [focusTaskIndex, setFocusTaskIndex] = useState<number>(0);
  const [addChanges, setAddChanges] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [closeContent, setCloseContent] = useState<boolean>(false);
  const [openDiscardChangesDialog, setOpenDiscardChangesDialog] = useState<boolean>(false);
  const [openRealTimeUpdateDialog, setOpenRealTimeUpdateDialog] = useState<boolean>(false);
  const [openUpdatingNChecklistDialog, setOpenUpdatingNChecklistDialog] = useState<boolean>(false);
  const [checklistCount, setChecklistCount] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState<boolean>(false);
  const [showRegenerate, setShowRegenerate] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [checklistTaskId, setChecklistTaskId] = useState(null);
  const [checklistContentId, setChecklistContentId] = useState(null);
  const [AIMessage, setAIMessage] = useState<string>('');
  const [openAI, setOpenAI] = useState<boolean>(false);
  const [openProgress, setOpenProgress] = useState<boolean>(false);
  const [taskName, setTaskName] = useState<string>('');
  const [taskDetail, setTaskDetail] = useState<string>('');
  const [showTaskRegenerate, setShowTaskRegenerate] = useState<boolean>(false);
  const [taskAIMessage, setTaskAIMessage] = useState<string>('');
  const [openTaskAI, setOpenTaskAI] = useState<boolean>(false);
  const [isChecklistRegenerate, setIsChecklistRegenerate] = useState<boolean>(false);
  // socket
  const [socketConnection, setSocketConnection] = useState<WebSocket | undefined>(undefined);
  const [connectsocket, setConnectSocket] = useState<boolean>(false);
  const [socketOpen, setSocketOpen] = useState<boolean>(false);
  const [isFound, setIsFound] = useState<boolean>(false);
  const [closed, setClosed] = useState(false);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [deletedItemsIDs, setDeletedItemsIDs] = useState<string[]>([]);

  const [socketTaskConnection, setSocketTaskConnection] = useState<WebSocket | undefined>(undefined);
  const [connectTaskSocket, setConnectTaskSocket] = useState<boolean>(false);
  const [disableTaskList, setDisableTaskList] = useState<boolean>(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (taskId !== undefined && taskId !== null && checklistTaskId === null) {
      const operatorSimulationSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}ws/task/${taskId}/`);
      setSocketConnection(operatorSimulationSocket);
      setConnectSocket(true);
      setSocketOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (taskId !== null && customTemplateName !== null && customTemplateDetail !== null) {
      setChecklistTemplateName(customTemplateName);
      setDescription(customTemplateDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTemplateName, customTemplateDetail]);
  useEffect(() => {
    if (customTemplateName) setTemplateName(customTemplateName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTemplateName]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!taskId) {
      setData([{
        unique_position_key: Date.now().toString(),
        name: '',
        is_heading: false,
        assignees: [],
        content: [],
        due_is_after: true,
        due_is_weekday_only: false,
        due_minutes: 0,
        due_months: 0,
        due_days: 0,
        due_hours: 0,
        is_stop: false,
      }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTemplateName]);

  useEffect(() => {
    if (isChecklistRegenerate) { setDisableSaveBtn(true); }
  }, [isChecklistRegenerate]);
  useEffect(() => {
    if (taskId) { setDisableSaveBtn(true); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (disableTaskList) { setDisableSaveBtn(true); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableTaskList]);

  useEffect(() => {
    setIsFound(false);
    if (connectsocket && socketConnection !== undefined) {
      setIsLoading(false);
      socketConnection.onmessage = (taskData): void => {
        const streamedData = JSON.parse(taskData.data);
        if (Object.keys(streamedData?.data?.stream_data).length !== 0) {
          setAddChanges(true);
          setData((prevData) => {
            if (Array.isArray(prevData)) {
              return [...prevData, streamedData?.data?.stream_data];
            }
            setFocusedTask(streamedData?.data?.stream_data);
            return [streamedData?.data?.stream_data];
          });
        }
        if (streamedData?.data?.is_completed === true) {
          setRetryCount(0);
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (streamedData?.data?.message) {
            setAIMessage(streamedData?.data?.message);
            socketConnection.close();
            setSocketOpen(false);
            setDisableSaveBtn(false);
          } else {
            if (data !== undefined) {
              const uniqueKeys = new Set();
              const filteredContent = data.filter((item) => {
                if (uniqueKeys.has(item.unique_position_key)) {
                  return false;
                }
                uniqueKeys.add(item.unique_position_key);
                return true;
              });
              setData([...data, ...filteredContent]);

              // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
              for (const newContentObject of streamedData?.data?.complete_data) {
                const isObjectExists = filteredContent.some((contentObj) => contentObj.unique_position_key === newContentObject.unique_position_key);
                if (!isObjectExists && !deletedItemsIDs.includes(newContentObject.unique_position_key)) {
                  setData([...data, newContentObject]);
                }
              }
            }
            // enqueueSnackbar('Successfully generated Checklist template with AI');
            socketConnection.close();
            setSocketOpen(false);
            setAddChanges(true);
            setDisableSaveBtn(false);
          }
          setShowRegenerate(true);
          setTimeout(() => {
            setShowRegenerate(false);
          }, 30000);
        }
      };
      socketConnection.onerror = (): void => {
      };
      socketConnection.onclose = (): void => {
        if (!closed) {
          if (retryCount < 50) {
            setRetryCount(retryCount + 1);
            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              const operatorSimulationSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}ws/task/${checklistTaskId !== null ? checklistTaskId : taskId}/`);
              setSocketConnection(operatorSimulationSocket);
            }, 1500);
          } else {
            socketConnection.close();
            setSocketOpen(false);
          }
        } else {
          setSocketOpen(false);
          setClosed(false);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectsocket, socketConnection, deletedItemsIDs, closed]);

  const { mutate: onCreateAIChecklistTemplate } = useMutation(async () => axios({
    url: 'api/chat-gpt/generate-checklist/',
    method: 'post',
    data: {
      template_name: templateName ? templateName : customTemplateName,
      description: description ? description : customTemplateDetail,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      task_id: checklistTaskId !== null ? checklistTaskId : taskId,
    },
  }), {
    onSuccess: (res) => {
      if (res.data) {
        setData(undefined);
        setChecklistTaskId(res.data.detail.task_id);
        localStorage.setItem('template_name', templateName);
        localStorage.setItem('description', description);
        navigate({ search: `?taskId=${res.data.detail.task_id}` });
        const operatorSimulationSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}ws/task/${res.data.detail.task_id}/`);
        setSocketConnection(operatorSimulationSocket);
        setConnectSocket(true);
        setSocketOpen(true);
      }
    },
    onError: () => {
      setIsLoading(false);
      enqueueSnackbar('Error in Creating Checklist', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (focusedTask !== undefined && focusedTask.name !== undefined && focusedTask.name !== null) {
      setTaskName(focusedTask.name);
    }
  }, [focusedTask]);
  useEffect(() => {
    setTaskDetail('');
    setShowTaskRegenerate(false);
  }, [focusTaskIndex]);

  const onBackButtonEvent = (): void => {
    if (!isBackButtonClicked) {
      setOpenDiscardChangesDialog(true);
    } else {
      window.history.back();
    }
  };

  useQuery(
    'get/checklist-template',
    async () => axios({
      url: `/api/checklist-template/${templateId}/`,
      method: 'get',
    }),
    {
      select: (res: AxiosResponse<IState>) => res.data,
      onSuccess: (res: IState) => {
        if (taskId === null) {
          setChecklistTemplateName(res.template_name);
          setData(res.tasks);
          setFocusedTask(res.tasks[0]);
          setTemplateName(res.template_name);
          setIsLoading(false);
        }
      },
    },
  );

  const { mutate: getCeleryProgress } = useMutation(async () => axios({
    url: '/api/checklist-celery-progress/',
    method: 'post',
    data: {
      template: templateId,
    },
  }), {
    onSuccess: (res) => {
      setProgressValue(Number(res.data.detail.progress));
      if (res.data.detail.progress !== 100) {
        // getCeleryProgress();
      }
    },
  });
  const { mutate: getTaskProgress } = useMutation(async () => axios({
    url: 'api/chat-gpt/task_progress/',
    method: 'get',
    params: { task_id: taskId },

  }), {
    onSuccess: (res) => {
      // eslint-disable-next-line eqeqeq
      if (res?.data?.detail?.result?.message == 'terminated') {
        setClosed(true);
        setSocketOpen(false);
        setFocusedTask({
          unique_position_key: Date.now().toString(),
          name: '',
          is_heading: false,
          assignees: [],
          content: [],
          due_is_after: true,
          due_is_weekday_only: false,
          due_minutes: 0,
          due_months: 0,
          due_days: 0,
          due_hours: 0,
          is_stop: false,
        });

        setData([{
          unique_position_key: Date.now().toString(),
          name: '',
          is_heading: false,
          assignees: [],
          content: [],
          due_is_after: true,
          due_is_weekday_only: false,
          due_minutes: 0,
          due_months: 0,
          due_days: 0,
          due_hours: 0,
          is_stop: false,
        }]);
        return;
      }
      if (res?.data?.detail?.complete === true) {
        setAddChanges(true);
        setData(res?.data?.detail?.result?.complete_response);
        setFocusedTask(res?.data?.detail?.result?.complete_response[0]);
        setFocusTaskIndex(0);
        socketConnection?.send(JSON.stringify({ stop_task: true }));
        socketConnection?.close();
        setClosed(true);
        setShowRegenerate(true);
        setTimeout(() => {
          setShowRegenerate(false);
        }, 30000);
        setSocketOpen(false);
        setDisableSaveBtn(false);
      }
    },
  });
  useEffect(() => {
    getTaskProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: handleUpdateAllChecklists } = useMutation(async () => axios({
    url: '/api/checklist-template/update_all_checklist/',
    method: 'post',
    data: {
      template: templateId,
    },
  }), {
    onSuccess: () => {
      setOpenRealTimeUpdateDialog(false);
      setOpenUpdatingNChecklistDialog(true);
      getCeleryProgress();
    },
  });

  const { mutate: handleUpdateChecklistTemplate } = useMutation(async () => axios({
    url: `/api/checklist-template/${templateId}/`,
    method: 'patch',
    data: {
      // eslint-disable-next-line no-unneeded-ternary
      template_name: templateName ? templateName : customTemplateName,
      tasks: data,
    },
  }), {
    onSuccess: (res) => {
      if (res.data.detail.checklist_count > 0) {
        setOpenRealTimeUpdateDialog(true);
        setChecklistCount(Number(res.data.detail.checklist_count));
      } else {
        window.history.back();
        setIsBackButtonClicked(true);
        window.removeEventListener('popstate', onBackButtonEvent);
        setIsLoading(false);
      }
    },
    onError: (res: IErrorResponse) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (res.data.response.message !== undefined) {
        setIsLoading(false);
        setTemplateName(checklistTemplateName);
        if (data === undefined) {
          enqueueSnackbar('Create at least one task', {
            variant: 'error',
            autoHideDuration: 1500,
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        } else {
          enqueueSnackbar(res.data.response.message, {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        }
      } else {
        setIsLoading(false);
        setTemplateName(checklistTemplateName);
        enqueueSnackbar('Somethig went wrong', {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });
      }
    },
  });
  useEffect(() => {
    if (connectTaskSocket && socketTaskConnection !== undefined && focusedTask !== undefined && data !== undefined) {
      const task: ITasks = { ...focusedTask };
      socketTaskConnection.onmessage = (taskData): void => {
        const streamedData = JSON.parse(taskData.data);
        if (streamedData?.data?.is_completed === false) {
          setAddChanges(true);
          const contentData: IContent = streamedData.data.stream_data;
          task.content = [...task.content, contentData];
          setFocusedTask({ ...task });
          setData(data.map((dataTask: ITasks) => {
            if (dataTask.unique_position_key === focusedTask.unique_position_key) {
              // eslint-disable-next-line no-param-reassign
              dataTask = { ...task };
            }
            return dataTask;
          }));
        }
        if (streamedData?.data?.is_completed === true) {
          setRetryCount(0);
          if (streamedData?.data?.message) {
            setTaskAIMessage(streamedData?.data?.message);
          } else {
            const uniqueKeys = new Set();
            const filteredContent = task.content.filter((item) => {
              if (uniqueKeys.has(item.unique_position_key)) {
                return false;
              }
              uniqueKeys.add(item.unique_position_key);
              return true;
            });
            setData(data.map((dataTask: ITasks) => {
              if (dataTask.unique_position_key === focusedTask.unique_position_key) {
                // eslint-disable-next-line no-param-reassign
                dataTask.content = [...filteredContent];
              }
              return dataTask;
            }));
            // setData([...filteredContent]);
            // eslint-disable-next-line no-restricted-syntax
            for (const newContentObject of streamedData?.data?.complete_data) {
              const isObjectExists = filteredContent.some((contentObj) => contentObj.unique_position_key === newContentObject.unique_position_key);
              if (!isObjectExists && !deletedItemsIDs.includes(newContentObject.unique_position_key)) {
                setData(data.map((dataTask: ITasks) => {
                  if (dataTask.unique_position_key === focusedTask.unique_position_key) {
                    // eslint-disable-next-line no-param-reassign
                    dataTask.content = [...dataTask.content, ...newContentObject];
                  }
                  return dataTask;
                }));
              }
            }
          }
          setConnectTaskSocket(false);
          socketTaskConnection.close();
          setDisableTaskList(false);
          setShowTaskRegenerate(true);
          setDisableSaveBtn(false);

          setTimeout(() => {
            setShowTaskRegenerate(false);
          }, 30000);
        }
      };
      socketTaskConnection.onclose = (): void => {
        if (!closed) {
          if (retryCount < 50) {
            setRetryCount(retryCount + 1);
            setTimeout(() => {
              const operatorSimulationSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}ws/task/${checklistContentId}/`);
              setSocketTaskConnection(operatorSimulationSocket);
            }, 1500);
          } else {
            socketTaskConnection.close();
            setSocketOpen(false);
          }
        } else {
          setSocketOpen(false);
          setClosed(false);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectTaskSocket, socketTaskConnection, deletedItemsIDs, closed]);

  const { mutate: generateContentWithAI } = useMutation(async () => axios({
    url: 'api/chat-gpt/generate-task/',
    method: 'post',
    data: {
      task_name: taskName,
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      is_heading: !!(focusedTask !== undefined && focusedTask.is_heading),
      description: taskDetail,
    },
  }), {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (res) => {
      if (data !== undefined && focusedTask !== undefined) {
        setData(data.map((task: ITasks) => {
          if (task.unique_position_key === focusedTask.unique_position_key) {
            // eslint-disable-next-line no-param-reassign
            task.content = [];
          }
          return task;
        }));
      }

      setOpenProgress(false);
      setOpenTaskAI(false);
      setChecklistContentId(res.data.detail.task_id);
      const operatorSimulationSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}ws/task/${res.data.detail.task_id}/`);
      setSocketTaskConnection(operatorSimulationSocket);
      setConnectTaskSocket(true);
    },
    onError: () => {
      // setOpenAI(false);
      setOpenProgress(false);
      setOpenTaskAI(false);
      enqueueSnackbar('Error in generating task content', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  function onUpdateChecklistTemplate(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    let error = false;
    if (data !== undefined) {
      data.map((tasks) => {
        tasks.content.map((item) => {
          if (item.files !== undefined && item.type === 'file' && item.files.file_name === '') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            notify.show('File is Required', 'error', 2000);
            error = true;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if ((item.form_type === 'dropdown' || item.form_type === 'radio' || item.form_type === 'multiChoice') && item.options !== undefined && item.options !== null) {
            if (item.options.length === 0) {
              notify.show(`At least one ${item.form_type === 'multiChoice' ? 'multi choice' : item.form_type} input option is Required`, 'error', 30000);
              error = true;
            } else {
              const hasNonEmptyValue = item.options.some((obj) => obj.label.trim() !== '');
              if (!hasNonEmptyValue) {
                notify.show(`At least one value is required in the ${item.form_type === 'multiChoice' ? 'multi choice' : item.form_type} input option`, 'error', 30000);
                error = true;
              }
            }
          }
        });
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!error && !isSubmit) {
      handleUpdateChecklistTemplate();
      setIsLoading(true);
      setIsSubmit(true);
      setTimeout(() => {
        setIsSubmit(false);
      }, 2000);
    }
  }
  const handleSocketClose = (): void => {
    if (socketConnection !== undefined) {
      socketConnection.send(JSON.stringify({ stop_task: true }));
      socketConnection.close();
      setClosed(true);
      setShowRegenerate(true);
      setTimeout(() => {
        setShowRegenerate(false);
      }, 30000);
      setSocketOpen(false);
      setDisableSaveBtn(false);
      if (data === undefined) {
        setFocusedTask({
          unique_position_key: Date.now().toString(),
          name: '',
          is_heading: false,
          assignees: [],
          content: [],
          due_is_after: true,
          due_is_weekday_only: false,
          due_minutes: 0,
          due_months: 0,
          due_days: 0,
          due_hours: 0,
          is_stop: false,
        });
        setData([{
          unique_position_key: Date.now().toString(),
          name: '',
          is_heading: false,
          assignees: [],
          content: [],
          due_is_after: true,
          due_is_weekday_only: false,
          due_minutes: 0,
          due_months: 0,
          due_days: 0,
          due_hours: 0,
          is_stop: false,
        }]);
      }
    }
  };
  const handleTaskSocketClose = (): void => {
    if (socketTaskConnection !== undefined) {
      socketTaskConnection.send(JSON.stringify({ stop_task: true }));
      socketTaskConnection.close();
      setConnectTaskSocket(false);
      setShowTaskRegenerate(true);
      setClosed(true);
      setTimeout(() => {
        setShowTaskRegenerate(false);
      }, 30000);
      setDisableTaskList(false);
      setDisableSaveBtn(false);
    }
  };

  const clearPreviousTaskData = (): void => {
    if (focusedTask !== undefined) {
      setFocusedTask((prev) => {
        if (prev?.assignees) {
          return { ...prev, content: [] };
        }
        return prev;
      });
    }
  };

  return (
    <div className='create-checklist-template'>
      {isLoading ? <div style={{ height: '80vh' }} className='vh-50 d-flex justify-content-center align-items-center'><CustomLoader /></div>
        : (
          <form id='my-form' onSubmit={(event: React.FormEvent<HTMLFormElement>): void => { onUpdateChecklistTemplate(event); }}>
            <Header checklistTemplateName={checklistTemplateName} templateName={templateName} setTemplateName={setTemplateName} addChanges={addChanges} isBackButtonClicked={isBackButtonClicked} setIsBackButtonClicked={setIsBackButtonClicked} onBackButtonEvent={onBackButtonEvent} disableSaveBtn={disableSaveBtn} socketOpen={socketOpen} />
            <Grid container sx={{ height: '100vh' }}>
              <Grid
                container
                item
                className='content-wrap-checklist'
              >
                <Grid item sm={5}>
                  <AllTasks isFound={isFound} setIsFound={setIsFound} socketOpen={socketOpen} handleSocketClose={handleSocketClose} data={data} setData={setData} focusedTask={focusedTask} setFocusedTask={setFocusedTask} setAddChanges={setAddChanges} setOpenTaskAI={setOpenTaskAI} focusTaskIndex={focusTaskIndex} setFocusTaskIndex={setFocusTaskIndex} deletedItemsIDs={deletedItemsIDs} setDeletedItemsIDs={setDeletedItemsIDs} taskId={taskId ?? ''} disableTaskList={disableTaskList} setTaskDetail={setTaskDetail} />
                </Grid>
                {openProgress ? <div style={{ height: '80vh', width: '57%' }} className='vh-50 d-flex justify-content-center align-items-center'><CustomLoader /></div>
                  : focusedTask && <TaskDetails data={data} setData={setData} socketOpen={socketOpen} focusedTask={focusedTask} showContent={showContent} setShowContent={setShowContent} closeContent={closeContent} setCloseContent={setCloseContent} setAddChanges={setAddChanges} setOpenTaskAI={setOpenTaskAI} connectTaskSocket={connectTaskSocket} handleTaskSocketClose={handleTaskSocketClose} setFocusedTask={setFocusedTask} setDeletedItemsIDs={setDeletedItemsIDs} deletedItemsIDs={deletedItemsIDs} setTaskDetail={setTaskDetail} />}
              </Grid>
              {showRegenerate
                && (
                  <div className='bottom-div-container'>
                    <div className='bottom-div'>
                      <p>{AIMessage !== '' ? AIMessage : 'Checklist generated by AI'}</p>
                      <h6 className='regenerate-btn' aria-hidden='true' onClick={(): void => { setIsChecklistRegenerate(true); setOpenAI(true); setShowRegenerate(false); }}>Regenerate</h6>

                    </div>
                  </div>
                )}
              {showTaskRegenerate
                && (
                  <div className='bottom-div-container' style={{ position: 'absolute' }}>
                    <div className='bottom-div'>
                      <p>{taskAIMessage !== '' ? taskAIMessage : 'Tasks generated by AI'}</p>

                      <h6 className='regenerate-btn' aria-hidden='true' onClick={(): void => { clearPreviousTaskData(); generateContentWithAI(); setDisableTaskList(true); setShowTaskRegenerate(false); }}>Regenerate</h6>

                    </div>
                  </div>
                )}
            </Grid>
          </form>
        )}
      <Dialog
        open={openDiscardChangesDialog}
      >
        <DialogContent style={{ width: 400, padding: '24px' }}>
          <div className='dialog-heading'>Discard changes?</div>
          <span
            className='dialog-body'
            style={{
              fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)', fontWeight: '400', marginTop: '15px',
            }}
          >
            All unpublished edits to this will be discarded and can’t be recovered.
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenDiscardChangesDialog(false);
              window.history.pushState(null, '', window.location.href);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={(): void => {
              navigate('/workspace/settings/checklist-templates');
              handleSocketClose();
            }}
            style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }}
            color='primary'
            autoFocus
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRealTimeUpdateDialog}
        keepMounted
      >
        <DialogContent style={{ width: 430, padding: '24px' }}>
          <div className='dialog-heading' style={{ fontWeight: 400, fontFamily: 'Roboto' }}>Confirm real-time run update?</div>
          <span
            className='dialog-body'
            style={{
              fontSize: '12px', color: 'rgba(33, 33, 33, 0.6)', fontWeight: '400', marginTop: '15px',
            }}
          >
            This template has
            {' '}
            {checklistCount}
            {' '}
            checklists runs in progress.
            <br />
            Do you want to update them to reflect the published changes?

          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenRealTimeUpdateDialog(false);
              setIsLoading(false);
              setIsBackButtonClicked(true);
              window.removeEventListener('popstate', onBackButtonEvent);
              window.history.back();
            }}
            color='primary'
          >
            Don’t update
          </Button>
          <Button variant='contained' onClick={(): void => { handleUpdateAllChecklists(); }} style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }} color='primary' autoFocus>
            Update
            {' '}
            {checklistCount}
            {' '}
            checklists
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUpdatingNChecklistDialog}
        keepMounted
      >
        <DialogContent style={{ width: 500, padding: '24px' }}>
          <div className='dialog-heading'>
            Updating
            {' '}
            {checklistCount}
            {' '}
            checklists
          </div>
          <div style={{ margin: '10px 0px' }}><BorderLinearProgress variant='determinate' value={progressValue} /></div>
          <span
            className='dialog-body'
            style={{
              fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)', fontWeight: '400', marginTop: '20px',
            }}
          >
            This update can be done in the background, feel free to navigate away from this page.
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button variant='contained' onClick={(): void => { setOpenUpdatingNChecklistDialog(false); setIsBackButtonClicked(true); window.removeEventListener('popstate', onBackButtonEvent); navigate('/workspace/checklists'); }} style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }} color='primary' autoFocus>
            Return to the checklists
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAI}
        keepMounted
      >
        <DialogContent style={{ width: 500, marginBottom: '20px', padding: '0px' }}>
          <img src={`${process.env.REACT_APP_ASSESTS_URL}AIImage.webp`} alt='' style={{ width: '100%' }} />
          <div style={{ padding: '20px' }}>
            <p className='create-template-title'>{isChecklistRegenerate ? 'Regenerate Checklist' : 'Strada AI Checklist'}</p>
            <div className='create-template-div'>
              <InputField
                type='text'
                label='Name'
                name='name'
                value={templateName ? templateName : customTemplateName ? customTemplateName : ''}
                inputProps={{ maxLength: 150 }}
                disabled
                onChange={(event): void => { setTemplateName(event.target.value); }}
              />
              <p className='bottom-text'>Specific checklist names work best</p>
            </div>
            <div className='create-template-div'>
              <InputField
                type='text'
                name='detail'
                value={description || isEditing ? description : customTemplateDetail ? customTemplateDetail : ''}
                inputProps={{ maxLength: 2000 }}
                placeholder='Additional instructions. e.g., “Give me a maximum of 10 steps, the last step needs to be approved by the property manager”'
                rows={4}
                multiline
                onChange={(event): void => {
                  setDescription(event.target.value);
                  setIsEditing(true);
                }}
              />
              <p className='bottom-text'>This can help fine tune your checklist.</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenAI(false);
              setDisableSaveBtn(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          {description.length > 2000
            ? (
              <Tooltip title={<span>{description.length > 2000 ? 'Template description is too long' : ''}</span>}>
                <Button
                  variant='contained'
                  style={{ textTransform: 'inherit', color: '#8D8D8D', background: '#EBEBE4' }}

                >
                  {isChecklistRegenerate ? 'Regenerate' : 'Generate with AI'}
                </Button>
              </Tooltip>
            )
            : (
              <Button
                variant='contained'
                onClick={(): void => { onCreateAIChecklistTemplate(); setIsLoading(true); setOpenAI(false); localStorage.setItem('template_name', templateName); setFocusedTask(undefined); }}
                style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }}

              >
                {isChecklistRegenerate ? 'Regenerate' : 'Generate with AI'}
              </Button>
            )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openTaskAI}
        keepMounted
      >
        <DialogContent style={{ width: 500, marginBottom: '20px', padding: '0px' }}>
          <img src={`${process.env.REACT_APP_ASSESTS_URL}AIImage.webp`} alt='' style={{ width: '100%' }} />
          <div style={{ padding: '20px' }}>
            <div className='dialog-heading'>Generate task</div>
            <div className='create-template-div'>
              <InputField
                type='text'
                label='Name'
                name='name'
                value={taskName}
                inputProps={{ maxLength: 150 }}
                // control={control}
                disabled
                onChange={(event): void => { setTaskName(event.target.value); }}
              />
              <p className='bottom-text'>Specific task names work best</p>
            </div>
            <div className='create-template-div'>
              <InputField
                type='text'
                name='detail'
                value={taskDetail}
                inputProps={{ maxLength: 2000 }}
                placeholder='Additional instructions. e.g., “Give me a maximum of 10 steps, the last step needs to be approved by the property manager”'
                rows={4}
                multiline
                onChange={(event): void => { setTaskDetail(event.target.value); }}
              />
              <p className='bottom-text'>This can help fine tune your tasks.</p>
            </div>

          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenTaskAI(false);
              setDisableSaveBtn(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={(): void => {
              generateContentWithAI(); setDisableTaskList(true); setShowRegenerate(false); setShowTaskRegenerate(false);
            }}
            style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }}
            color='primary'
            autoFocus
          >
            Generate task
          </Button>
        </DialogActions>
      </Dialog>
      <Notifications />
    </div>
  );
}
