/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputField from 'shared-components/inputs/InputField';
import {
  Button, Tooltip,
} from '@mui/material';
import type { ICreateDialog } from './types';

function CreateDialog(props: ICreateDialog): JSX.Element {
  const {
    openAI,
    disableAIbtn,
    setOpenAI,
    setIsDuplicate,
    setTemplateName,
    setTemplateDetail,
    setDisableAIbtn,
    handleCreate,
  } = props;

  const [tempName, setTempName] = useState<string>('');
  const [tempDesc, setTempDesc] = useState<string>('');

  return (
    <div>
      <Dialog
        open={openAI}
        keepMounted
      >
        <DialogContent style={{ width: 500, marginBottom: '20px', padding: '0px' }}>
          <img src={`${process.env.REACT_APP_ASSESTS_URL}AIImage.png`} alt='' />
          <div style={{ padding: '20px' }}>
            <p className='create-template-title'>Strada AI Checklist</p>
            {/* The onMouseLeave event is utilized to address the scenario where setting state on button click triggers an API call while the state is still empty. */}
            <div className='create-template-div' onMouseLeave={(): void => { setTemplateName(tempName); }}>
              <InputField
                type='text'
                label='Name'
                name='name'
                value={tempName}
                inputProps={{ maxLength: 150 }}
                onChange={(event): void => {
                  setTempName(event.target.value);
                }}
              />
              <p className='bottom-text'>Specific checklist names work best</p>
            </div>
            <div className='create-template-div' onMouseLeave={(): void => { setTemplateDetail(tempDesc); }}>
              <InputField
                type='text'
                name='detail'
                value={tempDesc}
                inputProps={{ maxLength: 2000 }}
                placeholder='Additional instructions. e.g., “Give me a maximum of 10 steps, the last step needs to be approved by the property manager”'
                rows={4}
                multiline
                onChange={(event): void => {
                  setTempDesc(event.target.value);
                }}
              />
              <p className='bottom-text'>This can help fine tune your checklist.</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenAI(false);
              setIsDuplicate(false);
              setTemplateName('');
              setTemplateDetail('');
              setTempName('');
              setTempDesc('');
            }}
            color='primary'
          >
            Cancel
          </Button>
          {tempName === '' || tempName.length > 149 || tempDesc.length > 2000
            ? (
              <Tooltip title={<span>{tempName === '' ? 'Enter Template Name' : tempName.length > 149 ? 'Template Name is too long' : tempDesc.length > 2000 ? 'Template description is too long' : ''}</span>}>
                <Button
                  variant='contained'
                  style={{ textTransform: 'inherit', color: '#8D8D8D', background: '#EBEBE4' }}
                >
                  Generate with AI
                </Button>
              </Tooltip>
            )
            : (
              <Button
                variant='contained'
                onClick={(): void => {
                  setDisableAIbtn(true);
                  handleCreate();
                }}
                onMouseEnter={(): void => {
                  // To ensure safety, the state is set again in case onMouseLeave does not trigger, preventing potential issues where the state remains empty after a button click.
                  setTemplateName(tempName);
                  setTemplateDetail(tempDesc);
                }}
                style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }}
                disabled={disableAIbtn}
              >
                Generate with AI
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateDialog;
