/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Popover } from '@mui/material';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useSnackbar } from 'notistack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StradaSpinner from 'shared-components/components/StradaSpinner';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import {
  useLocation,
} from 'react-router-dom';
import type { IBuildingResponse } from '../../types';

interface IBuildingOption {
  name: string;
  value: number;
}

export default function BuildingsBar(): JSX.Element {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [updating, setUpdating] = React.useState(false);
  const [selectedBuilding, setSelectedBuiding] = React.useState<IBuildingOption | null>(null);

  const singleSideSheetData = useSelector((state: RootState) => state.workspaces.sideSheetData.singleSideSheetData);
  const allBuildings = useSelector((state: RootState) => state.workspaces.workspacesData.allBuildingListing);

  useEffect(() => {
    setAnchorEl(null);
  }, [selectedBuilding]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { data: buildings = [] } = useQuery(
    ['building-in-sidesheet', singleSideSheetData?.workspace],
    async () => axios({
      url: `/api/filter/property/?workspace=${singleSideSheetData?.workspace}`,
      method: 'get',
    }),
    {
      enabled: singleSideSheetData?.workspace !== null,
      select: (res: AxiosResponse<IBuildingResponse>) => res.data.detail.map((building) => ({ name: building.address, value: building.id })),
    },
  );

  // const { data: allBuildings = [] } = useQuery(
  //   ['all-building-in-sidesheet', singleSideSheetData?.workspace],
  //   async () => axios({
  //     url: '/api/filter/property/',
  //     method: 'get',
  //   }),
  //   {
  //     enabled: allBuildingsData.length === 0,
  //     select: (res: AxiosResponse<IBuildingResponse>) => res.data.detail.map((building) => ({ name: building.address, value: building.id })),
  //   },
  // );

  const setBarData = (): void => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (singleSideSheetData !== null && singleSideSheetData.building !== null) {
      const currentBuilding = buildings.filter((building) => singleSideSheetData.building.id === building.value);
      if (currentBuilding.length !== 0) {
        setSelectedBuiding(currentBuilding[0]);
      } else {
        setSelectedBuiding(null);
      }
    } else {
      setSelectedBuiding(null);
    }
  };

  useEffect(() => {
    setBarData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSideSheetData, buildings]);

  const { mutate: updateBuilding } = useMutation(async (buildingId: number) => axios({
    url: `/api/budget-calendar/event/${singleSideSheetData?.id}/`,
    method: 'PATCH',
    data: {
      property: buildingId,
    },
  }), {
    onSuccess: async (): Promise<void> => {
      enqueueSnackbar('Building updated successfully');
      setUpdating(false);
      if (!location.pathname.includes('dashboard')) {
        await queryClient.invalidateQueries('get-single-sidesheet').then();
        await queryClient.invalidateQueries('sidesheet/get-events').then();
      } else {
        await queryClient.invalidateQueries('get-single-sidesheet').then();
        await queryClient.invalidateQueries('others-events').then();
        await queryClient.invalidateQueries('prioritized-events').then();
        await queryClient.invalidateQueries('user-assigned-events').then();
        await queryClient.invalidateQueries('allevents/get-events').then();
        await queryClient.invalidateQueries('user-created-events').then();
      }
    },
    onError: (): void => {
      setUpdating(false);
      setBarData();
      enqueueSnackbar('Updation failed.', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },

  });
  return (
    <div className='assignee-sheet-bar'>
      <h6 className='side-sheet-side-label'> Building </h6>
      <div className='assignee-sheet-popover'>
        <div
          className='popover-btn'
          onClick={handleClick}
          aria-hidden='true'
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 'auto',
          }}
        >
          {updating ? <StradaSpinner open={updating} message='Updating' /> : (
            <>
              <p>
                {' '}
                {selectedBuilding ? selectedBuilding.name : <HorizontalRuleIcon fontSize='small' />}
                {' '}
              </p>
              <ArrowDropDownIcon fontSize='small' htmlColor='' />
            </>
          )}
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className='assignee-popover'>

            {singleSideSheetData?.workspace === null ? allBuildings?.map((building) => (
              <div className={selectedBuilding !== null && selectedBuilding.value === building.value ? 'popover-option active' : 'popover-option'} key={building.name} onClick={(): void => { updateBuilding(building.value); setUpdating(true); handleClose(); setSelectedBuiding(building); }} aria-hidden='true'>
                <h5>
                  {' '}
                  {building.name}
                  {' '}
                </h5>
              </div>
            )) : buildings.map((building) => (
              <div className={selectedBuilding !== null && selectedBuilding.value === building.value ? 'popover-option active' : 'popover-option'} key={building.name} onClick={(): void => { updateBuilding(building.value); setUpdating(true); handleClose(); setSelectedBuiding(building); }} aria-hidden='true'>
                <h5>
                  {' '}
                  {building.name}
                  {' '}
                </h5>
              </div>
            ))}

          </div>
        </Popover>

      </div>
    </div>
  );
}
