import React, { useState } from 'react';
import './_clientNavbar.scss';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

export default function Navbar(): JSX.Element {
  const [mobileNav, setMobileNav] = useState(false);
  window.addEventListener('resize', () => {
    if (window.innerWidth < 481 && !mobileNav) {
      setMobileNav(true);
    } else if (window.innerWidth > 480 && mobileNav) {
      setMobileNav(false);
    }
  });

  return (
    <div>
      {window.innerWidth < 481 || mobileNav ? <MobileNav /> : <DesktopNav />}

    </div>
  );
}
