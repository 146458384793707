import React from 'react';
import Sidebar from '../sidebar';
import LeaseListing from './LeaseListing';

export default function UnpaidCharges(): JSX.Element {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar variant='main' activeLink='lease' />
      <LeaseListing />
    </div>
  );
}
