import {
  BudgetCalendarIcon, CIOIcon, PurhcaseOrderIcon, ChecklistIcon, SideBarHeaderIcon, Lease,
} from './icons';
import type { INavbarItem } from '../types';

export const mainNavbarConfig: INavbarItem[] = [
  {
    id: '01-properties',
    name: 'properties',
    heading: 'Properties',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: SideBarHeaderIcon,
    route: '/workspace/buildings',
    parentName: null,
  },
  {
    id: '01-event-tracker',
    name: 'event-tracker',
    heading: 'Event Tracker',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: BudgetCalendarIcon,
    route: '/building/event-tracker',
    parentName: null,
  },
  {
    id: '02-cois',
    name: 'cois',
    heading: 'COIs',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: CIOIcon,
    route: '/workspace/cois',
    parentName: null,
  },
  {
    id: '03-rfps',
    name: 'rfps',
    heading: 'RFPs',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: CIOIcon,
    route: '/workspace/rfps',
    parentName: null,
  },
  {
    id: '04-checklist',
    name: 'checklists',
    heading: 'Checklists',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: ChecklistIcon,
    route: '/workspace/checklists',
    parentName: null,
  },
  {
    id: '07-purchase-order',
    name: 'purchase-order',
    heading: 'Purchase Order',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: PurhcaseOrderIcon,
    parentName: null,
    route: '/workspace/purchase-orders',
  },

  {
    id: '09-lease',
    name: 'lease',
    heading: 'Lease Abstraction',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: Lease,
    parentName: null,
    route: '/workspace/lease',
  },
];

export const settingNavbarConfig: INavbarItem[] = [
  {
    id: '01-details',
    name: 'details',
    heading: 'Details',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/details',
    parentName: null,
  },
  {
    id: '11-vendors',
    name: 'vendors',
    heading: 'Vendors',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/vendors',
    parentName: null,
  },
  {
    id: '13-members',
    name: 'members',
    heading: 'Members',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/members',
    parentName: null,
  },
  {
    id: '04-templates',
    name: 'templates',
    heading: 'Templates',
    hasChildren: true,
    route: `${window.location.pathname}`,
    parentName: null,
    children: [
      {
        id: '10-checklist-template',
        name: 'checklist-templates',
        heading: 'Checklists',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/checklist-templates',
        parentName: 'templates',
      },
      {
        id: '10-tasks-template',
        name: 'tasks-templates',
        heading: 'Tasks',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/tasks-templates',
        parentName: 'templates',
      },
      {
        id: '10-rfp-template',
        name: 'rfp-templates',
        heading: 'RFP Form',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/rfp-template',
        parentName: 'templates',
      },
      {
        id: '11-lease-template',
        name: 'lease-templates',
        heading: 'Lease Abstraction',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/lease-abstraction',
        parentName: 'templates',
      },
    ],
    hasIcon: false,
    icon: undefined,
  },
  {
    id: '05-vendor-coi',
    name: 'vendor-coi',
    heading: 'Vendor COI',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/vendorCOI',
    parentName: null,
  },
  {
    id: '06-chart-of-accounts',
    name: 'chart-of-accounts',
    heading: 'Chart of Accounts',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/charts-of-accounts',
    parentName: null,
  },

  {
    id: '08-po-approval',
    name: 'po-approval',
    heading: 'PO Approval',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/po-approval',
    parentName: null,
  },

];
