/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState, useMemo, useEffect } from 'react';
import {
  IconButton, Divider, Typography, Avatar,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import PrimayButton from 'shared-components/components/PrimayButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from 'react-redux';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import type { Cell } from 'react-table';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTable from 'shared-components/tables/CustomTable';
import StradaSearch from 'admin/buildingSection/budget-calendar/components/StradaSearch';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import CustomLoader from 'shared-components/components/CustomLoader';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { RootState } from 'mainStore';
import type { IDataObject } from 'formsTypes';
import _ from 'lodash';
import type {
  ILeaseListing, Data,
} from './types';
import './_Lease.scss';

export default function LeaseListing(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<Data[]>([]);
  const [leaseData, setLeaseData] = useState<Data[]>([]);
  const [selectedLease, setSelectedLease] = useState<{ name: string; id: string }>();
  const [getNextData, setgetNextData] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessed, setIsProcessed] = useState<boolean>(false);
  const [filteredDataLoading, setFilteredDataLoading] = useState<boolean>(false);

  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );

  const refetchInterval = (): number | false => {
    if (leaseData.length > 0) {
      const hasUnprocessed = _.some(leaseData, { is_processed: false });
      hasUnprocessed ? setIsProcessed(true) : setIsProcessed(false);
      return hasUnprocessed ? 15000 : false;
    }
    return false;
  };

  const {
    data: allLease = { next: null, previous: null, results: [] },
    isLoading: loadMore, isFetching,
  } = useQuery(
    ['get/lease', currentWorkspace.id, search],
    async () => axios({
      url: `api/lease/?workspace=${currentWorkspace.id}`,
      method: 'get',
      params: {
        search,
        page_size: 25,
      },
    }),
    {
      refetchInterval,
      select: (res: AxiosResponse<ILeaseListing>) => res.data,
      onSuccess: (res) => {
        setCursor(res.next?.split('cursor=')[1]?.split('?')[0].split('page_size')[0]);
        setLeaseData(res.results);
        setIsLoading(false);
        setFilteredData(res.results);
      },
    },
  );

  const { data: loadMoreData } = useQuery(
    ['get/loadmoretasks', cursor],
    async () => axios({
      url: `api/lease/?workspace=${currentWorkspace.id}`,
      method: 'GET',
      params: {
        cursor,
        page_size: 25,
      },
    }),
    {
      enabled: getNextData && cursor !== null,
      select: (res: AxiosResponse<ILeaseListing>) => res.data,

    },
  );
  const GetNextCursorData = (): void => {
    if (allLease.next !== null) {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      loadMoreData && loadMoreData.next !== null ? setCursor(loadMoreData.next.split('cursor=')[1]?.split('?')[0].split('page_size')[0]) : '';
      setgetNextData(true);
      setFilteredDataLoading(true);
    } else {
      setgetNextData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  useEffect(() => {
    if (getNextData && cursor !== null && loadMoreData !== undefined) {
      setLeaseData([...leaseData, ...loadMoreData.results]);
      setFilteredDataLoading(false);
      setFilteredData([...filteredData, ...loadMoreData.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreData]);

  const { mutate: deleteLease, isLoading: deleteLoader } = useMutation(
    async (id: number | string) => axios({
      url: `/api/lease/${id}/`,
      method: 'delete',
    }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get/lease').then();
        setOpen(false);
        let message = '';
        if (selectedLease?.name) {
          message = `Lease abstraction "${selectedLease?.name}" was deleted`;
        } else {
          message = 'Lease abstraction was deleted';
        }
        enqueueSnackbar(message);
      },
    },
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Tenant',
        accessor: 'tenant_name',
        width: '50%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value, row } = cell;
          const { original } = row;
          const newVal: string = value;

          return (
            <div
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>): void => {
                if (e.key === 'Enter' || e.key === ' ') {
                  original.is_processed && original.progress !== -1 && navigate(`/workspace/lease/view/${original.id}`);
                }
              }}
              tabIndex={0}
              role='button'
              onClick={(): void => {
                original.is_processed && original.progress !== -1 && navigate(`/workspace/lease/view/${original.id}`);
              }}
            >
              { !original.is_processed ? (
                <div
                  className='d-flex align-items-center'
                >
                  <CircularProgress size={18} />
                  <p
                    style={{ marginLeft: '.7rem' }}
                    className='is-processed-pdf'
                  >
                    {newVal}
                  </p>
                </div>
              ) : (
                <div className='d-flex  align-items-center'>
                  {original.progress === -1
                    ? (
                      <div className='d-flex  align-items-center'>
                        <Tooltip title={original.message}>
                          <img src={`${process.env.REACT_APP_ASSESTS_URL}cois-error.webp`} alt='error' height={16} />
                        </Tooltip>
                        <p style={{ marginLeft: '.4rem' }}>
                          {newVal}
                        </p>
                      </div>
                    )
                    : (
                      <p style={{ color: '#00CFA1' }}>
                        {newVal}
                      </p>
                    )}
                </div>
              )}
            </div>

          );
        },
      },
      {
        Header: 'Author',
        accessor: 'author',
        width: '28%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          const authorInfo = original.author;
          return (
            <div className={!original.is_processed ? 'lease-avatar-wrapper d-flex align-center is-processed-pdf' : 'lease-avatar-wrapper d-flex align-center'}>
              <Avatar
                src={`${process.env.REACT_APP_IMAGE_URL}${authorInfo.avatar}`}
                className='avatar'
              />
              <p>
                {authorInfo.name}
              </p>
            </div>

          );
        },

      },
      {
        Header: 'Last Updated',
        accessor: 'modified',
        width: '15%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;

          const date = typeof row.values.modified === 'string' ? row.values.modified : '';
          const scheduleDate = new Date(date);
          const originalDateString = scheduleDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
          return (
            <div className={!original.is_processed ? 'is-processed-pdf' : ''}>{originalDateString}</div>
          );
        },
      },
      {
        Header: '',
        accessor: 'account_new',
        width: '7%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          return (
            <div
              style={{
                textAlign: 'right',
                marginRight: '1rem',
              }}
            >
              <PopupState variant='popper' popupId='demo-popup-popper'>
                {(popupState): JSX.Element => (
                  <div>
                    <IconButton {...bindToggle(popupState)} className={!original.is_processed ? 'is-processed-pdf' : ''}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }): JSX.Element => (
                        <ClickAwayListener
                          onClickAway={(): void => {
                            popupState.close();
                          }}
                        >
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper className='checklist-list-popover'>
                              {original.progress !== -1 && (
                                <div
                                  className='chart-btn'
                                  onClick={(): void => {
                                    navigate(`/workspace/lease/view/${original.id}`);
                                    popupState.close();
                                  }}
                                  aria-hidden='true'
                                >
                                  <VisibilityIcon className='edit-delete-icon' />
                                  <span className='edit-delete-text '> View</span>
                                </div>
                              )}

                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  navigate('/workspace/lease/upload/edit', { state: original });
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <EditIcon className='edit-delete-icon' />
                                <span className='edit-delete-text '>
                                  Edit
                                </span>
                              </div>
                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  setOpen(true);
                                  setSelectedLease({ name: original?.tenant_name, id: String(original.id) });
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <DeleteIcon className='edit-delete-icon' />
                                <span className='edit-delete-text '>
                                  Delete
                                </span>
                              </div>

                            </Paper>
                          </Fade>
                        </ClickAwayListener>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </div>
          );
        },
      },
    ],
    [currentWorkspace],
  );

  return (
    <>
      {leaseData?.length === 0 && search === '' && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {
            isFetching || loadMore || isLoading ? <div style={{ height: '90vh', width: '100%' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div>
              : (
                <div className='empty-property-wrapper'>
                  <p>No lease found</p>

                  <div className='create-new-button'>
                    <PrimayButton
                      onClick={(): void => {
                        navigate('/workspace/lease/upload/new');
                      }}
                    >
                      Upload new
                    </PrimayButton>
                  </div>

                </div>
              )
          }
        </>
      )}
      {(leaseData?.length !== 0 || search !== '') && (
        <div className='tasks-wrapper' style={{ marginTop: '78px' }}>
          <StradaLoader open={isLoading || deleteLoader} />
          <div className='header'>
            <Typography className='header-text'>Lease Abstraction</Typography>
            <div className='right-side d-flex align-items-center'>
              <div className='search-wrapper'>
                <StradaSearch value={search} setSearch={setSearch} placeholder='Search' />
              </div>

              <div className='ms-4'>
                <PrimayButton
                  onClick={(): void => {
                    navigate('/workspace/lease/upload/new');
                  }}
                >
                  Upload new
                  {' '}
                </PrimayButton>

              </div>
            </div>
          </div>
          <div className='vendor-table-wrapper blur-table-row'>
            <Divider sx={{ mt: 3, mb: 0.9 }} />
            <div>
              {(filteredData === undefined || filteredData.length === 0) && search !== ''
                ? (
                  <div className='empty-property-wrapper' style={{ marginTop: '30vh' }}>
                    <p>No lease found</p>
                  </div>
                )
                : filteredData === undefined || filteredData.length === 0 || isLoading || loadMore ? <div style={{ height: '70vh' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div>
                  : (
                    <CustomTable
                      {...{
                        columns,
                        data: filteredData !== undefined ? filteredData : [],
                      }}
                    />
                  )}
              <div>
                {!isFetching && filteredDataLoading && <div style={{ height: '20vh' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div>}

                <div className='mytasks-load-more'>
                  {!isProcessed && !isFetching && search === '' && filteredData !== undefined && filteredData.length > 0 && !filteredDataLoading && loadMoreData?.next !== null && allLease.next !== null && <p aria-hidden='true' onClick={(): void => { GetNextCursorData(); }}> Load more </p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog
        open={open}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          elevation: 0,
          style: {
            width: '600px',
            paddingBottom: '.5rem',
            paddingRight: '.7rem',
          },
        }}
      >
        <DialogTitle>
          <div>
            <p style={{ fontSize: '20px', color: 'rgba(33, 33, 33, 0.87)' }}>
              Delete lease abstraction &#34;
              {selectedLease?.name}
              &#34;?
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          This can&apos;t be undone.
        </DialogContent>
        <DialogActions>
          <Button
            className='text-transform-none'
            style={{ color: '#00CFA1', lineHeight: '1.7rem' }}
            onClick={(): void => { setOpen(false); setSelectedLease(undefined); }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            className='ms-2 text-transform-none'
            style={{ color: 'white', padding: '6px 8px' }}
            onClick={(): void => { deleteLease(String(selectedLease?.id)); }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
