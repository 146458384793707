/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-base-to-string */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Tooltip,
  Checkbox,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as yup from 'yup';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import AttachmentIcon from '@mui/icons-material/Attachment';
import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import HookTextField from 'shared-components/hooks/HookTextField';
import HookCheckbox from 'shared-components/hooks/HookCheckbox';
// import PrimayButton from 'shared-components/components/PrimayButton';
import CustomSwitch from 'shared-components/inputs/Switch';
import StradaLoading from 'shared-components/components/StradaLoader';
import type { IFormValues } from 'formsTypes';
import type { IEventScheduleModule } from 'admin/AdminFormTypes';
import { WithContext as ReactTags } from 'react-tag-input';
import SelectInput from 'shared-components/inputs/SelectInput';
import makeStyles from '@mui/styles/makeStyles';
import type { SelectChangeEvent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import type { RootState } from 'mainStore';
import { useSelector } from 'react-redux';
import CustomMultiAutocomplete from 'shared-components/inputs/CustomMultiAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CustomLoader from 'shared-components/components/CustomLoader';
import ordinal from 'ordinal';
import DateRangeDialog from '../EventSchedule/DateRangeDialog';
import type {
  IRFPData,
  ISchedualingData,
  ISelectedAttachment,
  IErrorResponse,
  ISchedualingDataDetail,
  IFollowUps,
} from '../types';
import CustomCalendar from '../EventSchedule/CustomCalendar';

interface IStatusOption {
  name: string;
  value: number;
  color: string;
  background: string;
}
interface IDetail {
  id: number;
  template_name: string;
}

interface IRFPlist {
  detail: IDetail[];
}

interface IOption {
  id: number | null;
  full_name: string;
  email: string;
  vendor_company: string | undefined;
}

function BookIcon(): JSX.Element {
  return (
    <svg
      width='24'
      height='18'
      viewBox='0 0 24 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 14.5156V2.98438C19.9688 2.67187 18.7969 2.51562 17.4844 2.51562C15.4531 2.51562 13.625 3.01563 12 4.01562V15.5C13.625 14.5 15.4531 14 17.4844 14C18.7031 14 19.875 14.1719 21 14.5156ZM17.4844 0.5C19.8594 0.5 21.7031 1 23.0156 2V16.5781C23.0156 16.7031 22.9531 16.8281 22.8281 16.9531C22.7344 17.0469 22.625 17.0938 22.5 17.0938C22.4062 17.0938 22.3281 17.0781 22.2656 17.0469C20.9844 16.3594 19.3906 16.0156 17.4844 16.0156C15.4531 16.0156 13.625 16.5156 12 17.5156C10.6562 16.5156 8.82812 16.0156 6.51562 16.0156C4.82812 16.0156 3.23438 16.375 1.73438 17.0938C1.70312 17.0938 1.65625 17.1094 1.59375 17.1406C1.5625 17.1406 1.53125 17.1406 1.5 17.1406C1.375 17.1406 1.25 17.0938 1.125 17C1.03125 16.9062 0.984375 16.7969 0.984375 16.6719V2C2.32812 1 4.17188 0.5 6.51562 0.5C8.82812 0.5 10.6562 1 12 2C13.3438 1 15.1719 0.5 17.4844 0.5Z'
        fill='#212121'
        fillOpacity='0.6'
      />
    </svg>
  );
}
function AutoIcon(): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0156 9C20.5469 9 21.0312 9.14062 21.4688 9.42188C21.9375 9.67188 22.3125 10.0312 22.5938 10.5C22.875 10.9375 23.0156 11.4375 23.0156 12C23.0156 12.5625 22.875 13.0781 22.5938 13.5469C22.3125 13.9844 21.9375 14.3438 21.4688 14.625C21.0312 14.875 20.5469 15 20.0156 15V18.9844C20.0156 19.5469 19.8125 20.0312 19.4062 20.4375C19.0312 20.8125 18.5625 21 18 21H6C5.4375 21 4.95312 20.8125 4.54688 20.4375C4.17188 20.0312 3.98438 19.5469 3.98438 18.9844V15C3.45312 15 2.95312 14.875 2.48438 14.625C2.04688 14.3438 1.6875 13.9844 1.40625 13.5469C1.125 13.0781 0.984375 12.5625 0.984375 12C0.984375 11.4375 1.125 10.9375 1.40625 10.5C1.6875 10.0312 2.04688 9.67188 2.48438 9.42188C2.95312 9.14062 3.45312 9 3.98438 9V6.98438C3.98438 6.45313 4.17188 6 4.54688 5.625C4.95312 5.21875 5.4375 5.01562 6 5.01562H9C9 4.45312 9.125 3.95312 9.375 3.51562C9.65625 3.04688 10.0156 2.6875 10.4531 2.4375C10.9219 2.15625 11.4375 2.01562 12 2.01562C12.5625 2.01562 13.0625 2.15625 13.5 2.4375C13.9688 2.6875 14.3281 3.04688 14.5781 3.51562C14.8594 3.95312 15 4.45312 15 5.01562H18C18.5625 5.01562 19.0312 5.21875 19.4062 5.625C19.8125 6 20.0156 6.45313 20.0156 6.98438V9ZM7.5 11.4844C7.5 11.9219 7.64062 12.2812 7.92188 12.5625C8.23438 12.8438 8.59375 12.9844 9 12.9844C9.40625 12.9844 9.75 12.8438 10.0312 12.5625C10.3438 12.2812 10.5 11.9219 10.5 11.4844C10.5 11.0781 10.3438 10.7344 10.0312 10.4531C9.75 10.1406 9.40625 9.98438 9 9.98438C8.59375 9.98438 8.23438 10.1406 7.92188 10.4531C7.64062 10.7344 7.5 11.0781 7.5 11.4844ZM15.9844 17.0156V15H8.01562V17.0156H15.9844ZM15 12.9844C15.4062 12.9844 15.75 12.8438 16.0312 12.5625C16.3438 12.2812 16.5 11.9219 16.5 11.4844C16.5 11.0781 16.3438 10.7344 16.0312 10.4531C15.75 10.1406 15.4062 9.98438 15 9.98438C14.5938 9.98438 14.2344 10.1406 13.9219 10.4531C13.6406 10.7344 13.5 11.0781 13.5 11.4844C13.5 11.9219 13.6406 12.2812 13.9219 12.5625C14.2344 12.8438 14.5938 12.9844 15 12.9844Z'
        fill='#212121'
        fillOpacity='0.87'
      />
    </svg>
  );
}

const tagList = [
  {
    name: 'Not Started',
    value: 1,
    color: 'rgba(33, 33, 33, 0.6)',
    background: 'rgba(0, 0, 0, 0.08)',
  },
  {
    name: 'In Process',
    value: 2,
    color: 'rgba(249, 168, 37, 0.87)',
    background: 'rgba(249, 168, 37, 0.08)',
  },
  {
    name: 'Scheduled',
    value: 3,
    color: 'rgba(33, 150, 243, 0.87)',
    background: 'rgba(33, 150, 243, 0.08)',
  },
  {
    name: 'Completed',
    value: 4,
    color: 'rgb(76, 175, 80)',
    background: 'rgba(76, 175, 80, 0.08)',
  },
  {
    name: 'As Needed',
    value: 5,
    color: 'rgb(0, 172, 193)',
    background: 'rgba(0, 172, 193, 0.08)',
  },
  {
    name: 'Contingency',
    value: 6,
    color: 'rgb(216, 27, 96)',
    background: 'rgba(216, 27, 96, 0.08)',
  },
  {
    name: 'Contract',
    value: 7,
    color: 'rgb(94, 53, 177)',
    background: 'rgba(94, 53, 177, 0.08)',
  },
];

const scheduleContentDefaultValues = {
  invitation_title: '',
  track_email: false,
  is_email_scheduled: false,
  is_rfp_form_link: false,
  durationType: '',
  future_days: 1,
  dayRangeType: '',
  startDate: '',
  endDate: null,
  timeDuration: null,
  future: '',
  range: '',
  rfp_form: '',
  forever: '',
  status: 1,
  files: [],
  attachments: [],
  rfps: [],
};

const useStyles = makeStyles(() => ({
  sss: {
    '& .MuiSelect-outlined': {
      border: '1px solid rgb(226, 226, 225)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const rfpFormSchema = yup.object().shape({
  // invitation_title: yup.string().required('Enter Title').nullable(),
});

export default function RFPScheduleContent(): JSX.Element {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const { eventId, eventType } = useParams();
  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );
  const [open, setOpen] = useState(false);
  const [isRfPSave, setIsRfPSave] = useState(false);
  const [schedulingData, setSchedulingData] = useState<ISchedualingDataDetail | null>(null);
  const [vendorBcc, setVendorBcc] = useState<{ id: string; text: string }[]>(
    [],
  );
  const [cc, setcc] = useState<{ id: string; text: string }[]>([]);
  const [ccError, setCCError] = useState<boolean>(false);
  const [showCC, setShowCC] = useState<boolean>(false);
  const [showBCC, setShowBCC] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [triggerTitleError, setTriggerTitleError] = useState<boolean>(false);
  const [bccError, setBCCError] = useState<boolean>(false);
  const [futureDays, setFutureDays] = useState<number | string | null>(1);
  const [rfpSelectionDisabled, setRFPSelectionDisabled] = useState<boolean>(true);
  const [selectedAttachments, setSelectedAttachments] = useState<
  ISelectedAttachment[]
  >([]);
  const [isRFPLoading, setISRFPLoading] = useState(true);
  const [rfpFormData, setRFPFormData] = useState({
    value: '',
    name: '',
  });
  const [rfpNotExist, setRFPNotExist] = useState<boolean>(false);
  const [isAddRFP, setIsAddRFP] = useState<boolean>(false);
  const [editRFPData, setEditRFPData] = useState<IRFPData | null>(null);
  const [selectedTag, setSelectedTag] = React.useState<IStatusOption | null>({
    name: 'Not Started',
    value: 1,
    color: 'rgba(33, 33, 33, 0.6)',
    background: 'rgba(0, 0, 0, 0.08)',
  });
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IOption[] | null>([]);
  const [inputValue, setInputValue] = useState('');
  const [openContactListingDialog, setOpenContactListingDialog] = useState(false);
  const [sendReply, setSendReply] = useState(false);
  const [isAdditionalFollowUp, setIsAdditionalFollowUp] = useState(false);

  const [sendReplyDays, setSendReplyDays] = useState(1);

  const [followUps, setFollowUps] = useState<IFollowUps[]>([]);

  const handleDelete = (i: number): void => {
    setVendorBcc([...vendorBcc.filter((tag, index) => index !== i)]);
    setCCError(false);
  };
  const handleAddition = (vbcc: { id: string; text: string }): void => {
    const validation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (vbcc.text.trim() && validation.test(vbcc.text)) {
      setVendorBcc([...vendorBcc, vbcc]);
      setBCCError(false);
    } else if (vbcc.text.trim() === '') {
      setBCCError(false);
    } else {
      setBCCError(true);
    }
  };
  const handleDeletecc = (i: number): void => {
    setcc([...cc.filter((tag, index) => index !== i)]);
    setCCError(false);
  };
  const handleAdditioncc = (vcc: { id: string; text: string }): void => {
    const validation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (vcc.text.trim() && validation.test(vcc.text)) {
      setcc([...cc, vcc]);
      setCCError(false);
    } else if (vcc.text.trim() === '') {
      setCCError(false);
    } else {
      setCCError(true);
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'future_days') {
      setValue('future_days', value, { shouldDirty: true });
    } else if (name === 'forever') {
      setValue('startDate', moment(new Date()).format('YYYY-MM-DD'));
      setValue(
        'endDate',
        moment(new Date()).add(180, 'days').format('YYYY-MM-DD'),
      );
    }
  };

  const {
    control, formState, handleSubmit, setValue, watch, getValues,
  } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(rfpFormSchema),
    defaultValues: scheduleContentDefaultValues,
  });

  const { errors } = formState;

  //  gettting current event schedule
  const { isLoading } = useQuery(
    'get-event-send-rfp-schedule',
    async () => axios({
      url: `/api/budget-calendar/event-send-rfp/${eventId}/`,
      method: 'GET',
    }),
    {
      onSuccess: (res: AxiosResponse<ISchedualingData>) => {
        setSchedulingData(res.data.detail);
      },
    },
  );

  const { mutate: handlePost, isLoading: updating } = useMutation(
    async (dataToPost: IEventScheduleModule) => axios({
      url: `/api/budget-calendar/event-send-rfp/${eventId}/`,
      method: 'PATCH',
      data: dataToPost,
    }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient
          .invalidateQueries('get-event-send-rfp-schedule')
          .then();
        enqueueSnackbar(`RFP was sent for "${schedulingData?.title}"`);
        navigate(-1);
      },
      onError: (err: IErrorResponse) => {
        enqueueSnackbar(err.data.detail, {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
          ),
        });
      },
    },
  );
  useQuery(
    'get-vendor-contact-list',
    async () => axios({
      url: `api/filter/vendor-contact-list/?workspace=${currentWorkspace.id}`,
      method: 'GET',
    }),
    {
      onSuccess: (res) => {
        if (res.data?.detail !== undefined && res.data.detail.length !== 0) {
          const data: IOption[] = res.data.detail;
          setOptions(data);
        }
      },
    },
  );

  const { data: rfpList, isLoading: getRFP } = useQuery(
    'event-get-rfp',
    async () => axios({
      url: `/api/rfp-template/?workspace=${currentWorkspace.id}`,
    }),
    {
      select: (res: AxiosResponse<IRFPlist>) => res.data.detail.map((rfp) => ({
        name: rfp.template_name,
        value: `${rfp.id}`,
      })),
      onSuccess: () => {
        setISRFPLoading(false);
      },
    },
  );

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (value === 'future') {
      setValue('range', '', { shouldDirty: true });
      setValue('forever', '', { shouldDirty: true });
    } else if (value === 'range') {
      setValue('startDate', null, { shouldDirty: true });
      setValue('endDate', null, { shouldDirty: true });
    }
    setValue('dayRangeType', value);
  };

  const handleRadioForDuration = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setValue('durationType', value);
  };

  useEffect(() => {
    const currentStatus = tagList.filter(
      (tag) => tag.value === watch('status'),
    );
    setSelectedTag(currentStatus[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('status')]);

  const handleFutureDays = (e: ChangeEvent<HTMLInputElement>): void => {
    if (Number(e.target.value)) {
      const clampedValue = Math.max(Number(e.target.value), 1);
      setFutureDays(clampedValue);
      setValue('future_days', clampedValue, { shouldDirty: true });
    }
  };

  const onTimeDurationChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (Number(e.target.value)) {
      const clampedValue = Math.max(Number(e.target.value), 10);
      setValue('timeDuration', clampedValue);
    }
  };
  const setRanges = (_startDate: string, _endDate: string): void => {
    setValue('startDate', _startDate);
    setValue('endDate', _endDate);
  };

  const uploadFile = (files: File[], id: string | undefined): void => {
    const data: ISelectedAttachment[] = [];
    // eslint-disable-next-line array-callback-return
    Array.from(files).map((file, index) => {
      data.push({
        file,
        filename: file.name,
        id: String(Number(id) + Number(index)),
      });
    });
    setSelectedAttachments([...selectedAttachments, ...data]);
    enqueueSnackbar('Files added Successfully.');
  };
  const deleteFile = (id: string | undefined): void => {
    const files = selectedAttachments.filter((file) => file.id !== id);
    setSelectedAttachments(files);
    enqueueSnackbar('File deleted Successfully');
  };
  const handleAttachmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (e.target.files !== null && e.target.files.length !== 0) {
      const filesList: File[] = [];
      let errorCount = 0;
      // eslint-disable-next-line array-callback-return
      Array.from(e.target.files).map((file: File): void => {
        if (file.size <= 25 * 1024 * 1024) {
          filesList.push(file);
        } else if (errorCount === 0) {
          errorCount += 1;
          enqueueSnackbar(`${file.name} size exceeds the limit of 25MB.`, {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        }
      });

      if (filesList.length > 0) {
        const id = Date.now().toString();
        uploadFile(filesList, id);
      }
    }
  };

  useEffect(() => {
    if (schedulingData !== null && !isRfPSave) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setValue('invitation_title', schedulingData.invitation_title, {
        shouldDirty: true,
      });
      setValue('subject', schedulingData.subject, { shouldDirty: true });
      setValue('description', schedulingData.description, {
        shouldDirty: true,
      });
      setValue('dayRangeType', schedulingData.dayRangeType, {
        shouldDirty: true,
      });
      setValue('durationType', schedulingData.durationType, {
        shouldDirty: true,
      });
      setValue('endDate', schedulingData.endDate, { shouldDirty: true });
      setValue('startDate', schedulingData.startDate, { shouldDirty: true });
      setValue('timeDuration', schedulingData.timeDuration, {
        shouldDirty: true,
      });
      setValue('status', schedulingData.status, { shouldDirty: true });
      setValue('is_email_scheduled', schedulingData.is_email_scheduled, {
        shouldDirty: true,
      });
      setValue('future_days', schedulingData.future_days, {
        shouldDirty: true,
      });
      setValue('track_email', schedulingData.track_email, {
        shouldDirty: true,
      });
      setValue('is_rfp_form_link', schedulingData.is_rfp_form_link, {
        shouldDirty: true,
      });
      setFutureDays(schedulingData.future_days);
      if (schedulingData.rfp_form === null) {
        setRFPFormData({
          value: '',
          name: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, setValue, schedulingData]);

  useEffect(() => {
    if (rfpFormData.name === '') {
      if (
        rfpList !== undefined
        && rfpList.length > 0
        && schedulingData !== null
        && schedulingData.rfp_form !== null
        && schedulingData.rfp_form !== ''
      ) {
        const newOptions = rfpList.filter(
          (option) => option.name === schedulingData.rfp_form,
        );
        if (newOptions.length > 0) {
          setRFPFormData(newOptions[0]);
        } else {
          setRFPFormData({
            value: schedulingData.rfp_form,
            name: schedulingData.rfp_form,
          });
          setRFPNotExist(true);
        }
      } else {
        setRFPFormData({ value: '', name: '' });
      }
    }
  }, [rfpFormData.name, rfpList, schedulingData]);

  const handleRFPChange = (e: SelectChangeEvent): void => {
    if (rfpList !== undefined && rfpList.length > 0) {
      const newOptions = rfpList.filter(
        (option) => option.value === e.target.value,
      );
      if (newOptions.length > 0) {
        setRFPFormData(newOptions[0]);
      } else {
        setRFPFormData({ value: '', name: '' });
      }
      setRFPSelectionDisabled(false);
    }
  };

  useEffect(() => {}, [schedulingData]);
  useEffect(() => {
    if (watch('is_email_scheduled') && watch('invitation_title') === '') {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('is_email_scheduled'), watch('invitation_title')]);
  useEffect(() => {
    if (watch('invitation_title') !== '') {
      setTriggerTitleError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('invitation_title')]);

  const onSubmit = (formData: IEventScheduleModule): void => {
    if (schedulingData !== null) {
      schedulingData.future_days = Number(futureDays);
      if (!titleError) {
        if (
          watch('is_email_scheduled')
          && watch('dayRangeType') === 'range'
          && watch('startDate') === null
          && watch('endDate') === null
        ) {
          enqueueSnackbar('Enter Date Range', {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        } else if (
          watch('is_email_scheduled')
          && watch('dayRangeType') === 'future'
          && watch('future_days') === ''
        ) {
          enqueueSnackbar('Enter Future Days', {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        } else {
          handlePost({
            ...formData,
            future_days: Number(futureDays),
            rfp_form: rfpFormData.name,
            rfp_form_id:
              rfpFormData.value !== '' || rfpFormData.value !== null
                ? rfpFormData.value
                : null,
          });
        }
      } else {
        setTriggerTitleError(true);
      }
    }
  };

  useEffect(() => {
    document.getElementById('budget-calendar-start')?.scrollIntoView();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    followUps.length === 0 ? setIsAdditionalFollowUp(false) : '';
  }, [followUps]);

  const clearFieldsData = (): void => {
    setValue('description', '', { shouldDirty: true });
    setValue('subject', '', { shouldDirty: true });
    setSelectedOptions([]);
    setSelectedAttachments([]);
    setVendorBcc([]);
    setcc([]);
    setSendReply(false);
    setSendReplyDays(1);
  };

  const { mutate: addRFPMessage, isLoading: rfpMsgLoader } = useMutation(
    async (dataToPost: IRFPData) => axios({
      url: '/api/budget-calendar/event-rfp-email/',
      method: 'POST',
      data: dataToPost,
    }),
    {
      onSuccess: async () => {
        clearFieldsData();
        setEditRFPData(null);
        setIsAddRFP(false);
        setIsRfPSave(true);
        setFollowUps([]);
        setIsAdditionalFollowUp(false);
        await queryClient
          .invalidateQueries('get-event-send-rfp-schedule')
          .then();
      },
      onError: (err: IErrorResponse) => {
        if (err.data.message !== undefined && err.data.message !== '') {
          enqueueSnackbar(err.data.message, {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        } else {
          enqueueSnackbar('Error in Saving RFP', {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        }
      },
    },
  );

  const { mutate: updateRFPMessage, isLoading: rfpUpdateMsgLoader } = useMutation(
    async ({ id, data }: { id: number | undefined; data: IRFPData }) => axios({
      url: `/api/budget-calendar/event-rfp-email/${id}/`,
      method: 'PATCH',
      data,
    }),
    {
      onSuccess: async (): Promise<void> => {
        setEditRFPData(null);
        setIsAddRFP(false);
        clearFieldsData();
        setIsAdditionalFollowUp(false);
        setFollowUps([]);
        await queryClient
          .invalidateQueries('get-event-send-rfp-schedule')
          .then();
      },
      onError: (err: IErrorResponse) => {
        if (err.data.message !== undefined && err.data.message !== '') {
          enqueueSnackbar(err.data.message, {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        } else {
          enqueueSnackbar('Error in Saving RFP', {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        }
      },
    },
  );

  const { mutate: deleteRFPMessage } = useMutation(
    async (id: number | undefined) => axios({
      url: `/api/budget-calendar/event-rfp-email/${id}/`,
      method: 'DELETE',
    }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient
          .invalidateQueries('get-event-send-rfp-schedule')
          .then();
      },
      onError: () => {
        enqueueSnackbar('Can not delete message');
      },
    },
  );

  const handleRFPEdit = (index: number): void => {
    if (schedulingData) {
      const data = schedulingData.rfps[index];
      setEditRFPData(schedulingData.rfps[index]);
      setSendReply(schedulingData.rfps[index].send_auto_reply);
      setSendReplyDays(schedulingData.rfps[index].reply_in_days);
      setSelectedOptions(schedulingData.rfps[index].event_rfp_vendor_contacts);
      setSelectedAttachments(schedulingData.rfps[index].attachments);
      setValue('description', data.description, { shouldDirty: true });
      setValue('subject', data.subject, { shouldDirty: true });
      const bcc = data.vendor_bcc.map((val: string) => ({
        text: val,
        id: val,
      }));
      const ccc = data.vendor_cc.map((cc_val: string) => ({
        text: cc_val,
        id: cc_val,
      }));
      setVendorBcc(bcc);
      setcc(ccc);
      setFollowUps(schedulingData.rfps[index].follow_ups);
      setIsAdditionalFollowUp(
        schedulingData.rfps[index].is_additional_followup,
      );
    }
  };

  const handleRFPDelete = (index: number): void => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (schedulingData && schedulingData.rfps[index].id !== undefined) {
      deleteRFPMessage(schedulingData.rfps[index].id);
    }
  };

  const renderDisplayRFP = (rfp: IRFPData, index: number): JSX.Element => (
    <Grid item className='message-card'>
      <div className='d-flex justify-space-between'>
        <div className='message-email'>
          To:
          {' '}
          {rfp.event_rfp_vendor_contacts !== null
            && rfp.event_rfp_vendor_contacts.length > 0
            && rfp.event_rfp_vendor_contacts.map((item) => `${item.full_name}, `)}
        </div>
        {!isAddRFP && (
          <div className='message-options'>
            <EditIcon
              className='cursor-pointer'
              onClick={(): void => {
                handleRFPEdit(index);
              }}
            />
            <span style={{ marginLeft: '5px' }}>
              <DeleteIcon
                className='cursor-pointer'
                onClick={(): void => {
                  handleRFPDelete(index);
                }}
              />
            </span>
          </div>
        )}
      </div>
      <div className='message-email'>
        {' '}
        CC:
        {rfp.vendor_cc.join(', ')}
      </div>
      <div className='message-email'>
        {' '}
        Bcc:
        {rfp.vendor_bcc.join(', ')}
      </div>
      <div className='message-subject mb-2 mt-1'>{rfp.subject}</div>
      {rfp.send_auto_reply && (
        <div className='d-flex align-items-center my-2'>
          <AutoIcon />
          <span
            style={{
              color: '#3E3E3E',
              fontSize: '16px',
              fontFamily: 'Roboto-Medium',
              marginLeft: '10px',
            }}
          >
            {`Send automatic reply in ${rfp.reply_in_days} ${
              rfp.reply_in_days > 1 ? 'days' : 'day'
            } if vendor does not reply`}
          </span>
        </div>
      )}
      {/* additional followup */}
      {rfp.is_additional_followup && rfp.follow_ups.length > 0 && (
        <section className='mt-1 mb-4'>
          <p className='add-additional-followups'>Add additional follow ups:</p>
          {rfp.follow_ups.map((item, idx) => (
            <p className='add-additional-followups'>
              {`If vendor from ${ordinal(
                idx + 1,
              )} follow up did'nt reply, send ${ordinal(
                idx + 2,
              )} follow up after`}
              &nbsp;
              {item.reply_in_days}
              &nbsp;
              {item.reply_in_days > 1 ? 'days.' : 'day.'}
            </p>
          ))}
        </section>
      )}
      <div className='message-description'>{rfp.description}</div>
      <div
        className='message-attachment-list'
        style={{ marginTop: '5px', flexWrap: 'wrap' }}
      >
        {rfp.attachments?.map((file) => (
          <Tooltip title={file.filename}>
            <div className='message-attachment' style={{ marginTop: '10px' }}>
              <span style={{ paddingRight: '6px', display: 'flex' }}>
                <InsertDriveFileIcon fontSize='small' />
              </span>
              {file.filename !== '' && file.filename.length > 10
                ? `${file.filename.substring(0, 10)}...`
                : file.filename}
              <span style={{ display: 'flex' }}>
                <ArrowDropDownIcon fontSize='small' />
              </span>
            </div>
          </Tooltip>
        ))}
      </div>
    </Grid>
  );

  const onAddRFPClick = (): void => {
    if (schedulingData && schedulingData.rfps.length > 0) {
      const index = schedulingData.rfps.length - 1;
      const data = schedulingData.rfps[index];
      setSendReply(schedulingData.rfps[index].send_auto_reply);
      setSendReplyDays(schedulingData.rfps[index].reply_in_days);
      setSelectedAttachments(schedulingData.rfps[index].attachments);
      setValue('description', data.description, { shouldDirty: true });
      setValue('subject', data.subject, { shouldDirty: true });
      const bcc = data.vendor_bcc.map((val: string) => ({
        text: val,
        id: val,
      }));
      const ccc = data.vendor_cc.map((cc_val: string) => ({
        text: cc_val,
        id: cc_val,
      }));
      setVendorBcc(bcc);
      setcc(ccc);
      setFollowUps(
        schedulingData.rfps[index].follow_ups.map((item) => ({ reply_in_days: item.reply_in_days })),
      );
      setIsAdditionalFollowUp(
        schedulingData.rfps[index].is_additional_followup,
      );
    }
    setIsAddRFP(true);
  };

  const onCancelRFPClick = (): void => {
    if (isAddRFP && editRFPData !== null) {
      deleteRFPMessage(editRFPData.id);
    }
    setEditRFPData(null);
    setSelectedOptions([]);
    setSelectedAttachments([]);
    clearFieldsData();
    setIsAddRFP(false);
  };

  async function file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(
          reader.result !== null && typeof reader.result === 'string'
            ? reader.result.toString()
            : '',
        );
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }

  const onSaveRFPClick = async (): Promise<void> => {
    const attachmentsData: string[] = await Promise.all(
      selectedAttachments.map(async (file): Promise<string> => {
        if (typeof file.file !== 'string') {
          return file2Base64(file.file).then((res): string => res);
        }
        return file.file;
      }),
    );
    const newData: ISelectedAttachment[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let count = 0; count < selectedAttachments.length; count++) {
      const obj: ISelectedAttachment = { file: '', filename: '', id: '' };
      // eslint-disable-next-line prefer-destructuring
      obj.file = attachmentsData[count];
      obj.filename = selectedAttachments[count].filename;
      obj.id = selectedAttachments[count].id.length > 10
        ? ''
        : selectedAttachments[count].id;
      newData.push(obj);
    }
    const data: IRFPData = {
      description: getValues('description'),
      event: Number(eventId),
      attachments: newData,
      subject: getValues('subject'),
      event_rfp_vendor_contacts: selectedOptions,
      vendor_cc: cc.map((singleCC) => singleCC.text),
      vendor_bcc: vendorBcc.map((singleCC) => singleCC.text),
      send_auto_reply: sendReply,
      reply_in_days: sendReplyDays,
      is_additional_followup: isAdditionalFollowUp,
      follow_ups: followUps,
    };
    if (isAddRFP) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !rfpMsgLoader ? addRFPMessage(data) : '';
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !rfpUpdateMsgLoader
        ? updateRFPMessage({ id: editRFPData?.id, data })
        : '';
    }
  };

  const getDisabledCheck = (): boolean => {
    watch('subject');
    watch('description');
    if (
      selectedOptions?.length === 0
      || getValues('subject') === ''
      || getValues('subject') === null
      || getValues('description') === undefined
      || getValues('description') === ''
      || getValues('description') === null
    ) {
      return true;
    }
    return false;
  };
  const getRFPDisabledCheck = (): boolean => {
    if (
      (watch('is_rfp_form_link')
        && (schedulingData?.rfps.length === 0 || rfpFormData.value === ''))
      || editRFPData !== null
      || (!watch('is_rfp_form_link') && !watch('is_email_scheduled'))
    ) {
      return true;
    }
    return false;
  };

  const handleAddFollowUp = (): void => {
    setFollowUps([
      ...followUps,
      {
        reply_in_days: 1,
      },
    ]);
  };

  const removeFollowUp = (indexToRemove: number): void => {
    const updatedArray = [
      ...followUps.slice(0, indexToRemove),
      ...followUps.slice(indexToRemove + 1),
    ];
    setFollowUps(updatedArray);
  };
  const updateFollowUpDays = (val: number, indexToUpdate: number): void => {
    setFollowUps(
      followUps.map((item, idx) => {
        if (idx === indexToUpdate) {
          // eslint-disable-next-line no-param-reassign
          item.reply_in_days = val === 0 ? 1 : val;
        }
        return item;
      }),
    );
  };
  const renderAddEditForm = (): JSX.Element => (
    <Grid container className='mt-4'>
      <Grid
        container
        columnSpacing={2}
        className='d-flex mb-2 justify content-between'
      >
        <Grid item sm={6}>
          <div className='d-flex'>
            {editRFPData === null ? 'Add ' : 'Edit '}
            message
            <label
              htmlFor='schedule-attachment-btn'
              className='attachment-btn ms-3'
            >
              <AttachmentIcon color='primary' />
              <h6> Attach File </h6>
            </label>
            <input
              id='schedule-attachment-btn'
              type='file'
              multiple
              onChange={(e): void => {
                handleAttachmentChange(e);
              }}
              style={{ display: 'none' }}
            />
          </div>
        </Grid>
        <Grid item sm={6} className='text-end'>
          <Button
            variant='text'
            className='text-transform-none'
            onClick={(): void => {
              onCancelRFPClick();
              setFollowUps([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant='text'
            disabled={getDisabledCheck()}
            className='ms-2 text-transform-none'
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onClick={(): void => { onSaveRFPClick(); }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid
          item
          md={12}
          style={{ position: 'relative' }}
          className='fieldset-border'
        >
          <div
            style={{
              position: 'absolute',
              zIndex: '1',
              cursor: 'pointer',
              right: '10px',
              top: '15px',
            }}
            className='cursor-pointer'
            aria-hidden='true'
            onClick={(e): void => {
              e.stopPropagation();
              setOpenContactListingDialog(true);
            }}
          >
            <BookIcon />
          </div>
          <CustomMultiAutocomplete
            selectedOptions={selectedOptions === null ? [] : selectedOptions}
            setSelectedOptions={setSelectedOptions}
            inputValue={inputValue}
            setInputValue={setInputValue}
            options={options}
            openContactListingDialog={openContactListingDialog}
            setOpenContactListingDialog={setOpenContactListingDialog}
          />
          <label className='error-message'>
            {' '}
            {errors.event_task_vendor_contacts
              ? 'Select Vendor Contacts'
              : ''}
            {' '}
          </label>
        </Grid>
        {showCC && (
          <Grid item md={12}>
            <div className='d-flex align-items-center'>
              <div className='bcc-wrapper w-100'>
                <label> CC </label>
                <ReactTags
                  tags={cc}
                  handleDelete={handleDeletecc}
                  handleAddition={(tagone): void => {
                    handleAdditioncc(tagone);
                  }}
                  placeholder=' '
                  autofocus={false}
                  handleInputBlur={(tagVal): void => {
                    handleAdditioncc({ id: tagVal, text: tagVal });
                  }}
                />
              </div>
              <div
                style={{ margin: '0 5px 0 10px', cursor: 'pointer' }}
                aria-hidden='true'
                onClick={(): void => {
                  setShowCC(false);
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <label className='error-message'>
              {' '}
              {ccError ? 'Enter a valid email' : ''}
              {' '}
            </label>
          </Grid>
        )}
        {showBCC && (
          <Grid item md={12}>
            <div className='d-flex align-items-center'>
              <div className='bcc-wrapper w-100'>
                <label> BCC </label>
                <ReactTags
                  tags={vendorBcc}
                  handleDelete={handleDelete}
                  handleAddition={(tagone): void => {
                    handleAddition(tagone);
                  }}
                  placeholder=''
                  autofocus={false}
                  handleInputBlur={(tagVal): void => {
                    handleAddition({ id: tagVal, text: tagVal });
                  }}
                />
              </div>
              <div
                style={{ margin: '0 5px 0 10px', cursor: 'pointer' }}
                aria-hidden='true'
                onClick={(): void => {
                  setShowBCC(false);
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <label className='error-message'>
              {' '}
              {bccError ? 'Enter a valid email' : ''}
              {' '}
            </label>
          </Grid>
        )}
        <div
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '16px',
            marginTop: '0.6rem',
          }}
        >
          <Grid item md={12}>
            <HookTextField
              name='subject'
              label='Subject'
              control={control}
              errors={errors}
              maxLength={150}
            />
          </Grid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              right: '0',
              top: '15px',
            }}
          >
            {!showCC && (
              <p
                style={{ marginRight: '15px', cursor: 'pointer' }}
                aria-hidden='true'
                onClick={(): void => {
                  setShowCC(true);
                }}
              >
                CC
              </p>
            )}
            {!showBCC && (
              <p
                style={{ marginRight: '15px', cursor: 'pointer' }}
                aria-hidden='true'
                onClick={(): void => {
                  setShowBCC(true);
                }}
              >
                BCC
              </p>
            )}
          </div>
        </div>
        <div className='d-flex mb-2 ms-1' style={{ width: '100%' }}>
          <Checkbox
            checked={sendReply}
            onChange={(): void => {
              setSendReply(!sendReply);
            }}
          />
          <p
            className='ms-2'
            style={{
              color: '#3E3E3E',
              fontSize: '16px',
              fontFamily: 'Roboto',
              marginTop: '0.55rem',
            }}
          >
            Send automatic reply with Strada AI
          </p>
        </div>
        {sendReply && (
          <>
            <br />
            <div className='d-flex align-items-center mb-3 ms-1'>
              <span
                style={{
                  color: '#3E3E3E',
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                  marginLeft: '10px',
                }}
              >
                Send automatic reply in
              </span>
              <span
                style={{
                  maxWidth: '70px',
                  margin: '0px 12px',
                  border: '1px solid #ddd',
                  padding: '4px 4px 4px 8px',
                  borderRadius: '4px',
                }}
              >
                <InputBase
                  className='future-input-field'
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  onChange={(e): void => { Number(e.target.value) < 31 && setSendReplyDays(Number(e.target.value) === 0 ? 1 : Number(e.target.value)); }}
                  name='automatic_reply_days'
                  type='number'
                  inputProps={{ min: 1 }}
                  value={sendReplyDays}
                />
              </span>
              <span
                style={{
                  color: '#3E3E3E',
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                  paddingLeft: '0',
                }}
              >
                {sendReplyDays > 1
                  ? 'days if vendor does not reply'
                  : 'day if vendor does not reply'}
              </span>
            </div>
            {/* additional follow ups */}
            <>
              <div
                className='d-flex mb-2 ms-1'
                style={{ width: '100%', paddingLeft: '2.4rem' }}
              >
                <Checkbox
                  checked={isAdditionalFollowUp}
                  onChange={(): void => {
                    setIsAdditionalFollowUp(!isAdditionalFollowUp);
                    handleAddFollowUp();
                  }}
                />
                <p
                  className='ms-2'
                  style={{
                    color: '#3E3E3E',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    marginTop: '0.55rem',
                  }}
                >
                  Add additional follow ups
                  {' '}
                </p>
              </div>

              {isAdditionalFollowUp
                && followUps.map((item, idx) => (
                  <>
                    <div
                      className='d-flex align-items-center mb-2 ms-1 '
                      style={{ width: '100%', paddingLeft: '2.4rem' }}
                    >
                      <span
                        style={{
                          color: '#3E3E3E',
                          fontSize: '16px',
                          fontFamily: 'Roboto',
                          marginLeft: '10px',
                        }}
                      >
                        If no reply, send
                        {' '}
                        <span
                          style={{ display: 'inline-block', minWidth: '27px' }}
                        >
                          {ordinal(idx + 2)}
                        </span>
                        {' '}
                        follow up after
                        {' '}
                      </span>
                      <span
                        style={{
                          maxWidth: '70px',
                          margin: '0px 12px',
                          border: '1px solid #ddd',
                          padding: '4px 4px 4px 8px',
                          borderRadius: '4px',
                        }}
                      >
                        <InputBase
                          className='future-input-field'
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          onChange={(e): void => { Number(e.target.value) < 31 && updateFollowUpDays(Number(e.target.value), idx); }}
                          name='automatic_reply_days'
                          type='number'
                          inputProps={{ min: 1 }}
                          value={item.reply_in_days}
                        />
                      </span>
                      <span
                        style={{
                          color: '#3E3E3E',
                          fontSize: '16px',
                          fontFamily: 'Roboto',
                          paddingLeft: '0',
                        }}
                      >
                        {item.reply_in_days > 1 ? 'days' : 'day'}
                      </span>
                      <CloseIcon
                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        onClick={(): void => {
                          removeFollowUp(idx);
                        }}
                      />
                    </div>
                    {followUps.length === idx + 1
                      ? ''
                      : (
                        <div className='follow-ups-divider' />
                      )}
                  </>
                ))}
              {followUps.length < 29 && isAdditionalFollowUp && (
                <p className='add-follow-up' onClick={handleAddFollowUp}>
                  Add
                  {' '}
                  {ordinal(followUps.length + 2)}
                  {' '}
                  follow up
                </p>
              )}
            </>
          </>
        )}
        <Grid className='email-description-wrap' item sm={12}>
          <div
            className={
              schedulingData !== null
              && schedulingData.signature !== null
              && schedulingData.signature !== ''
                ? 'email-description-div'
                : ''
            }
          >
            <HookTextField
              name='description'
              label='Description'
              control={control}
              errors={errors}
              multiline
            />
            {schedulingData !== null
              && schedulingData.signature !== null
              && schedulingData.signature !== '' && (
              <div
                className='email-signature-wrap'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                      schedulingData.signature !== undefined
                        ? schedulingData.signature
                        : '',
                }}
              />
            )}
          </div>
        </Grid>
        <div
          className='message-attachment-list'
          style={{ marginTop: '5px', flexWrap: 'wrap' }}
        >
          {selectedAttachments.length > 0
            && selectedAttachments.map((file) => (
              <PopupState variant='popover' popupId='demo-popup-popover'>
                {(popupState): JSX.Element => (
                  <div style={{ marginTop: '10px' }}>
                    <Tooltip title={file.filename}>
                      <div
                        className='message-attachment'
                        style={{ marginTop: '0px' }}
                        {...bindTrigger(popupState)}
                      >
                        {' '}
                        <span style={{ paddingRight: '6px', display: 'flex' }}>
                          <InsertDriveFileIcon fontSize='small' />
                        </span>
                        {' '}
                        {file.filename && file.filename.length > 10
                          ? `${file.filename.substring(0, 10)}...`
                          : file.filename}
                        {' '}
                        <span style={{ display: 'flex' }}>
                          {' '}
                          <ArrowDropDownIcon fontSize='small' />
                        </span>
                      </div>
                    </Tooltip>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div
                        className='event-schedule-att-popover'
                        style={{ height: 'fit-content' }}
                      >
                        {file.file_url !== undefined && (
                          <div
                            aria-hidden='true'
                            onClick={(): void => {
                              window.open(String(file.file_url), '_blank');
                              popupState.close();
                            }}
                          >
                            {' '}
                            Download
                            {' '}
                          </div>
                        )}
                        <div
                          onClick={(): void => {
                            deleteFile(file.id);
                            popupState.close();
                          }}
                          aria-hidden='true'
                        >
                          {' '}
                          Delete
                          {' '}
                        </div>
                      </div>
                    </Popover>
                  </div>
                )}
              </PopupState>
            ))}
        </div>
      </Grid>
    </Grid>
  );

  const renderRFPContent = (): JSX.Element => (
    <Grid container columnSpacing={2} className='d-flex justify content-between'>
      <Grid item sm={6} className='message'>Messages</Grid>
      { !isAddRFP && editRFPData === null && schedulingData && schedulingData.rfps.length === 0
        ? (
          <Grid item sm={6} className='text-right'>
            <Button variant='text' onClick={(): void => { onAddRFPClick(); }}>
              <AddIcon fontSize='small' />
              Add
            </Button>
          </Grid>
        ) : (
          <Grid item sm={6} />
        )}

      {!isAddRFP && schedulingData && schedulingData.rfps.length === 0 ? (
        <Grid item sm={12} className='message-empty mt-4'>
          Click on the button &#34;Add&#34; to write the messages to vendors.
        </Grid>
      ) : null }
      {schedulingData && schedulingData.rfps.length > 0 && schedulingData.rfps.map((rfp, index) => {
        if (editRFPData?.id === rfp.id) {
          return renderAddEditForm();
        }
        return renderDisplayRFP(rfp, index);
      })}

      {isAddRFP && renderAddEditForm()}

      {!isAddRFP
        && editRFPData === null
        && schedulingData
        && schedulingData.rfps.length > 0 && ( // count === 0 &&
        <Button
          variant='text'
          onClick={(): void => {
            onAddRFPClick();
          }}
        >
          <AddIcon fontSize='small' />
          Add
        </Button>
      )}
    </Grid>
  );

  return (
    <div
      style={{ width: '100%', paddingTop: '62px' }}
      id='budget-calendar-start'
      className='budget-calendar-start'
    >
      {/* <StradaLoading message={deleting ? 'Deleting...' : 'loading...'} open={uploading || deleting || updating} /> */}
      <StradaLoading message='loading...' open={updating} />
      {isRFPLoading || isLoading || getRFP ? (
        <div
          style={{ height: '85vh' }}
          className='vh-50 d-flex justify-content-center align-items-center'
        >
          <CustomLoader />
        </div>
      ) : (
        <>
          <DateRangeDialog
            open={open}
            handleClose={(): void => {
              setOpen(false);
            }}
            setRanges={setRanges}
            startDate={watch('startDate')}
            endDate={watch('endDate')}
          />
          <Grid container>
            <Grid item md={2} />
            <Grid item md={8}>
              <div className='event-schule-main-wrapper'>
                <div className='header'>
                  <IconButton
                    onClick={(): void => {
                      window.history.back();
                    }}
                    className='back-btn'
                  >
                    <ArrowBackIcon htmlColor='' />
                  </IconButton>
                  <h2>
                    {' '}
                    {schedulingData?.title}
                    {' '}
                  </h2>

                  {selectedTag !== null ? (
                    <div
                      style={{
                        background: selectedTag?.background,
                        color: selectedTag?.color,
                      }}
                      className='single-tag'
                    >
                      {' '}
                      {selectedTag?.name}
                    </div>
                  )
                    : '-'}
                </div>

                {/* Form Area */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  {watch('is_email_scheduled') && (
                    <div className={triggerTitleError ? 'title-error-div' : ''}>
                      <HookTextField
                        name='invitation_title'
                        required={watch('is_email_scheduled')}
                        label='Title for Calendar Invite'
                        control={control}
                        errors={errors}
                        maxLength={150}
                      />
                      {triggerTitleError && (
                        <p className='error-message'>This FIeld is Required</p>
                      )}
                    </div>
                  )}

                  <div className='custom-switch'>
                    <CustomSwitch
                      checked={watch('is_email_scheduled')}
                      onChange={(e, val): void => {
                        setValue('is_email_scheduled', val, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <p> Add scheduling link to email </p>
                  </div>

                  {watch('is_email_scheduled') && (
                    <div>
                      <div className='date-range-section'>
                        <h6> Date Range </h6>
                        <p>
                          {' '}
                          Set a range of dates when you can accept meetings.
                          {' '}
                        </p>

                        <div className='input-section'>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby='demo-radio-buttons-group-label'
                              defaultValue=''
                              name='dayRangeType'
                              value={watch('dayRangeType')}
                              onChange={handleRadioChange}
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 24,
                                },
                              }}
                            >
                              <FormControlLabel
                                value='future'
                                className='form-control-label-schedule'
                                control={<Radio color='primary' />}
                                label={(
                                  <div className='date-range-wrapper'>
                                    <InputBase
                                      className='future-input-field'
                                      disabled={
                                        watch('dayRangeType') !== 'future'
                                      }
                                      onChange={handleFutureDays}
                                      name='future_days'
                                      type='number'
                                      inputProps={{ min: 1 }}
                                      value={
                                        watch('dayRangeType') === 'future'
                                          ? Number(futureDays) > 1
                                            ? futureDays
                                            : 1
                                          : ''
                                      }
                                    />
                                    <span>days into the future</span>
                                  </div>
                                )}
                              />
                              <FormControlLabel
                                value='range'
                                className='form-control-label-schedule'
                                control={<Radio />}
                                label={(
                                  <div className='date-range-wrapper'>
                                    <span>Within a date range</span>
                                    <InputBase
                                      className='range-input-field'
                                      name='range'
                                      onChange={handleChange}
                                      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                                      value={
                                        watch('dayRangeType') === 'range'
                                        && watch('startDate')
                                        && watch('endDate')
                                          ? `${moment(
                                            watch('startDate'),
                                          ).format('MMM DD')}  -  ${moment(
                                            watch('endDate'),
                                          ).format('MMM DD')}`
                                          : ''
                                      }
                                      onClick={(): void => {
                                        if (watch('dayRangeType') === 'range') {
                                          setOpen(true);
                                        }
                                      }}
                                      disabled={
                                        watch('dayRangeType') !== 'range'
                                      }
                                    />
                                  </div>
                                )}
                              />
                              <FormControlLabel
                                value='forever'
                                className='form-control-label-schedule'
                                control={<Radio />}
                                label={(
                                  <div className='date-range-wrapper'>
                                    <span>Indefinitely into the future</span>
                                  </div>
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <h6> Event Duration </h6>
                        <p> Choose a duration </p>

                        <div className='event-duration-section'>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby='demo-radio-buttons-group-label'
                              defaultValue=''
                              name='durationType'
                              value={watch('durationType')}
                              onChange={handleRadioForDuration}
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 24,
                                },
                              }}
                            >
                              <div className='fixed-durations'>
                                <FormControlLabel
                                  value='15'
                                  control={<Radio color='primary' />}
                                  label='15 min'
                                  classes={{
                                    label: 'duration-radio-label',
                                  }}
                                />
                                <FormControlLabel
                                  value='30'
                                  control={<Radio />}
                                  label='30 min'
                                  classes={{
                                    label: 'duration-radio-label',
                                  }}
                                />
                                <FormControlLabel
                                  value='45'
                                  control={<Radio />}
                                  label='45 min'
                                  classes={{
                                    label: 'duration-radio-label',
                                  }}
                                />
                                <FormControlLabel
                                  value='60'
                                  control={<Radio />}
                                  label='60 min'
                                  classes={{
                                    label: 'duration-radio-label',
                                  }}
                                />
                              </div>
                              <p> ..or choose a custom duration </p>
                              <FormControlLabel
                                value='10'
                                control={<Radio />}
                                label={(
                                  <div className='custom-duration-input'>
                                    <div className='input-wrapper'>
                                      <InputBase
                                        name='custom-duration'
                                        type='number'
                                        inputProps={{ min: 15 }}
                                        value={
                                          Number(watch('timeDuration')) > 10
                                            ? watch('timeDuration')
                                            : 15
                                        }
                                        disabled={
                                          watch('durationType') !== '10'
                                        }
                                        onChange={onTimeDurationChange}
                                      />
                                      <span>min</span>
                                    </div>
                                  </div>
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>

                      {/* Table Section  */}

                      <Grid item md={12}>
                        <CustomCalendar schedulingData={schedulingData} />
                      </Grid>
                    </div>
                  )}

                  {eventType !== 'task' && (
                    <Grid container>
                      <Grid item sm={6}>
                        <div className='custom-switch'>
                          <CustomSwitch
                            checked={!!watch('is_rfp_form_link')}
                            onChange={(e, val): void => {
                              setValue('is_rfp_form_link', val);
                              if (!val && schedulingData !== null) {
                                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                                // setRFPFormData({ name: schedulingData !== null && schedulingData.rfp_form ? schedulingData.rfp_form : '', value: schedulingData !== null && schedulingData.rfp_form_id ? schedulingData.rfp_form_id : '' });
                              }
                            }}
                          />
                          <p>Add RFP form link to email</p>
                        </div>
                      </Grid>
                      <Grid item sm={6}>
                        {rfpNotExist ? (
                          <div className='no-rfp'>
                            {rfpFormData.name}
                            <ArrowDropDownIcon />
                          </div>
                        ) : (
                          <SelectInput
                            name='country'
                            label={
                              rfpFormData.value === '' ? 'Select RFP form' : ''
                            }
                            value={rfpFormData.value}
                            showPleaseSelect={false}
                            className={classes.sss}
                            showPlaceholder
                            onChange={(e): void => {
                              handleRFPChange(e);
                            }}
                            options={rfpList ?? []}
                            disabled={
                              !!(
                                (rfpFormData.name !== ''
                                  && rfpSelectionDisabled)
                                || !watch('is_rfp_form_link')
                              )
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {renderRFPContent()}

                  <Grid item md={12}>
                    <div className='event-schedule-footer'>
                      <HookCheckbox
                        name='track_email'
                        label='Track Emails'
                        control={control}
                      />
                      <div className='btn-wrapper'>
                        <Button
                          type='submit'
                          className={
                            getRFPDisabledCheck() || !watch('is_rfp_form_link')
                              ? 'primary-btn send-rfp-btn'
                              : 'primary-btn'
                          }
                          disabled={
                            getRFPDisabledCheck() || !watch('is_rfp_form_link')
                          }
                        >
                          Send RFP
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </form>
              </div>
            </Grid>
            <Grid item md={2} />
          </Grid>
        </>
      )}
    </div>
  );
}
