import React from 'react';
import TaskScheduleContent from './TaskScheduleContent';

export default function TaskSchedule(): JSX.Element {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <TaskScheduleContent />
    </div>
  );
}
