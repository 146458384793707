/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import AddContactDialog from './MultiAutocompleteDialogs/AddContactDialog';
import ContactListingDialog from './MultiAutocompleteDialogs/ContactListingDialog';

interface IOption {
  id: number | null;
  full_name: string;
  email: string;
  vendor_company: string | undefined;
  role?: string | null;
}

export interface ICustomMultiAutocomplete {
  options: IOption[];
  selectedOptions: IOption[];
  setSelectedOptions: (option: IOption[]) => void;
  inputValue: string;
  setInputValue: (email: string) => void;
  openContactListingDialog: boolean;
  setOpenContactListingDialog: (option: boolean) => void;
}

export default function CustomMultiAutocomplete(CustomMultiAutocompleteProps: ICustomMultiAutocomplete): JSX.Element {
  const {
    selectedOptions, setSelectedOptions, inputValue, setInputValue, options, openContactListingDialog, setOpenContactListingDialog,
  } = CustomMultiAutocompleteProps;

  const [emailError, setEmailError] = useState(false);
  const [finalInputValue, setFinalInputValue] = useState(inputValue);
  const [cleanInputValue, setCleanInputValue] = useState(inputValue);
  const [openAddVendorForm, setOpenAddVendorForm] = useState(false);

  useEffect(() => {
    if (finalInputValue !== '') {
      setInputValue(finalInputValue);
    }
  });

  useEffect(() => {
    if (inputValue !== '' && inputValue.includes(',')) {
      const data = inputValue.split(',');
      setCleanInputValue(data[data.length - 1]);
    } else {
      setCleanInputValue(inputValue);
    }
  }, [inputValue]);

  // eslint-disable-next-line no-useless-escape
  const emailValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (event: any, newInputValue: string): void => {
    if (newInputValue !== '') {
      setFinalInputValue('');
    }
    setInputValue(newInputValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectedOptionsChange = (event: any, option: IOption[]): void => {
    setSelectedOptions(option);
  };
  const handleCustomOption = (): void => {
    if (cleanInputValue.trim() !== '' && emailValidation.test(cleanInputValue.trim())) {
      setEmailError(false);
      setSelectedOptions([...selectedOptions, {
        id: null, full_name: cleanInputValue.trim(), email: cleanInputValue.trim(), vendor_company: '',
      }]);
      const lastCommaIndex = inputValue.lastIndexOf(',');
      if (lastCommaIndex === -1) {
        setFinalInputValue('');
      } else {
        setFinalInputValue(inputValue.substring(0, lastCommaIndex));
      }
    } else {
      setEmailError(true);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: any) => {
    if ((event.keyCode === 9 || event.keyCode === 13) && cleanInputValue.trim() !== '' && emailValidation.test(cleanInputValue.trim())) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      event.preventDefault();
      setEmailError(false);
      setSelectedOptions([...selectedOptions, {
        id: null, full_name: cleanInputValue.trim(), email: cleanInputValue.trim(), vendor_company: '',
      }]);
      const lastCommaIndex = inputValue.lastIndexOf(',');
      if (lastCommaIndex === -1) {
        setFinalInputValue('');
      } else {
        setFinalInputValue(inputValue.substring(0, lastCommaIndex));
      }
    } else if (event.keyCode === 9 || event.keyCode === 13) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      event.preventDefault();
      setEmailError(true);
    }
  };

  useEffect(() => {
    options.forEach((item) => {
      if (item.id === null) {
        // eslint-disable-next-line no-param-reassign
        item.id = Number(Math.floor(Math.random() * 1000000));
      }
    });
  }, [options]);
  useEffect(() => {
    const findIdx = selectedOptions.findIndex((item) => item.vendor_company === 'Strada Contact');
    if (findIdx !== -1) {
      selectedOptions.forEach((item) => {
        if (item.vendor_company === 'Strada Contact') {
          // eslint-disable-next-line no-param-reassign
          item.id = null;
        }
      });
      setSelectedOptions(selectedOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const disbledOption = (email: string): boolean => {
    if (email === '' || email === null) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Autocomplete
        multiple
        forcePopupIcon={false}
        disableClearable
        filterSelectedOptions
        options={options}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        filterOptions={(item, { inputValue }) => item.filter(
          (option): boolean => {
            const inputLowerCase = inputValue.toLowerCase();
            const { full_name: fullName, email } = option;
            const fullNameLowerCase = fullName !== null ? fullName.toLowerCase() : '';
            const emailLowerCase = email !== null ? email.toLowerCase() : '';
            return (
              fullNameLowerCase.includes(inputLowerCase)
              || emailLowerCase.includes(inputLowerCase)
            );
          },
        )}
        getOptionLabel={(option): string => {
          if (typeof option === 'string') {
            return option;
          }
          return option.full_name;
        }}
        renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id} style={{ pointerEvents: disbledOption(option.email) ? 'none' : 'auto', opacity: disbledOption(option.email) ? '.7' : '1' }}>
            <div>
              <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.87)' }}>
                {option.full_name}
                <span style={{
                  fontSize: '12px', color: 'rgba(33, 33, 33, 0.38)', textAlign: 'right', width: '100%', marginLeft: '5px',
                }}
                >
                  (
                  {option.vendor_company}
                  )

                </span>
              </p>
              <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)' }} className='mt-1'>
                {option.email}
              </p>
            </div>
          </li>
        )}
        value={selectedOptions}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionsChange}
        renderInput={(params): JSX.Element => (
          <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant='outlined'
            label='To'
            value={inputValue}
            onKeyDown={handleKeyDown}
            //   placeholder='TO'
            error={emailError}
          />
        )}
        noOptionsText={inputValue.length > 0
          ? (
            <div
              aria-hidden='true'
              onClick={(): void => { handleCustomOption(); }}
            >
              <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.87)' }}>
                {cleanInputValue}
              </p>
              <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)' }} className='mt-1'>
                {cleanInputValue}
              </p>
              <div
                style={{
                  color: '#00CFA1', width: '100%', borderTop: '1px solid #E4E4E4', padding: '12px', marginTop: '8px', cursor: 'pointer',
                }}
                aria-hidden='true'
                onClick={(e): void => { e.stopPropagation(); setOpenAddVendorForm(true); }}
              >
                +   Add vendor contact
              </div>
            </div>
          ) : 'No options'}
      />
      {openAddVendorForm
      && <AddContactDialog openAddVendorForm={openAddVendorForm} setOpenAddVendorForm={setOpenAddVendorForm} inputValue={cleanInputValue} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} /> }
      {openContactListingDialog
      && <ContactListingDialog openContactListingDialog={openContactListingDialog} setOpenContactListingDialog={setOpenContactListingDialog} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} /> }
    </>
  );
}
