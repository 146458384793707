/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import '../privacyPolicy/_privacyPolicy.scss';
import {
  Link, Element,
} from 'react-scroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Content(): JSX.Element {
  const [collapse, setCollapse] = useState({
    terms: true,
    choice: true,
    rules: true,
    limitation: true,
    general: true,
  });
  const [collapseItem, setCollapseItem] = useState({
    terms: true,
    choice: true,
    rules: true,
    limitation: true,
    general: true,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='privacy-content'>
      <div className='container d-flex'>
        <div className='left-wrapper'>
          <div className='heading-table'>
            <div className='heading-inner-table-box'>

              <Link
                activeClass='active'
                to='User Terms'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <div className='d-flex align-items-center collapse-icon'>
                  <div aria-hidden='true' onClick={(): void => { setCollapse({ ...collapse, terms: !collapse.terms }); }}>
                    <p> User Terms</p>
                  </div>

                </div>
                {!collapse.terms
                && (
                  <Link
                    activeClass='active-2'
                    to='These User Terms are Legally Binding'
                    smooth
                    duration={80}
                    offset={-100}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <h6> These User Terms are Legally Binding</h6>
                  </Link>
                )}
              </Link>
              <Link
                activeClass='active'
                to='Customer’s Choices and Instructions'
                smooth
                duration={80}
                offset={-70}
                isDynamic
                delay={0}
                spy
              >
                <div className='d-flex align-items-center collapse-icon'>
                  <div aria-hidden='true' onClick={(): void => { setCollapse({ ...collapse, choice: !collapse.choice }); }}>
                    <p>Customer’s Choices and Instructions</p>
                  </div>

                </div>
                {!collapse.choice
                && (
                  <>
                    <Link
                      activeClass='active-2'
                      to='You are an Authorized User on a Workspace Controlled by a “Customer”'
                      smooth
                      duration={80}
                      offset={-70}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>You are an Authorized User on a Workspace Controlled by a “Customer”</h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='What This Means for You — and for Us'
                      smooth
                      duration={80}
                      offset={-70}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>What This Means for You — and for Us</h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='The Relationship Between You, Customer and Us'
                      smooth
                      duration={80}
                      offset={-70}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>The Relationship Between You, Customer and Us</h6>
                    </Link>
                  </>
                ) }
              </Link>
              <Link
                activeClass='active'
                to='Rules'
                smooth
                duration={80}
                offset={-90}
                isDynamic
                delay={0}
                spy
              >
                <div className='d-flex align-items-center collapse-icon'>
                  <div aria-hidden='true' onClick={(): void => { setCollapse({ ...collapse, rules: !collapse.rules }); }}>
                    <p>Rules</p>
                  </div>
                </div>
                {!collapse.rules
                && (
                  <>
                    <Link
                      activeClass='active-2'
                      to='You Must be Over the Legal Age'
                      smooth
                      duration={80}
                      offset={-110}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>You Must be Over the Legal Age</h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='You Are Here At the Pleasure of Customer (and Us)'
                      smooth
                      duration={80}
                      offset={-110}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>You Are Here At the Pleasure of Customer (and Us)</h6>
                    </Link>
                  </>
                )}
              </Link>
              <Link
                activeClass='active'
                to='Limitation of Liability'
                smooth
                duration={80}
                offset={-110}
                isDynamic
                delay={0}
                spy
              >
                <div className='d-flex align-items-center collapse-icon'>
                  <div aria-hidden='true' onClick={(): void => { setCollapse({ ...collapse, limitation: !collapse.limitation }); }}>
                    <p>Limitation of Liability</p>
                  </div>

                </div>
                {!collapse.limitation
                && (
                  <Link
                    activeClass='active-2'
                    to='Application of Consumer Law'
                    smooth
                    duration={80}
                    offset={-100}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <h6>Application of Consumer Law</h6>
                  </Link>
                ) }
              </Link>
              <Link
                activeClass='active'
                to='General Provisions'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <div className='d-flex align-items-center collapse-icon'>
                  <div aria-hidden='true' onClick={(): void => { setCollapse({ ...collapse, general: !collapse.general }); }}>
                    <p>General Provisions</p>
                  </div>

                </div>
                {!collapse.general
                && (
                  <>
                    <Link
                      activeClass='active-2'
                      to='Email and Strada Messages'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Email and Strada Messages </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Privacy Policy'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Privacy Policy </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Modifications'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Modifications </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Waiver'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Waiver </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Severability'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Severability </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Assignment'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Assignment </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Governing Law; Venue; Fees'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Governing Law; Venue; Fees </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Entire Agreement'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Entire Agreement </h6>
                    </Link>
                    <Link
                      activeClass='active-2'
                      to='Contacting Strada'
                      smooth
                      duration={80}
                      offset={-100}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <h6>Contacting Strada </h6>
                    </Link>
                  </>
                )}
              </Link>

            </div>

          </div>
        </div>
        <div className='right-wrapper'>

          {/*  section-1 */}
          <p className='privacy-policy-heading'>Terms Of Use</p>
          <p className='privacy-policy-paragraph'>These User Terms of Service (the “User Terms”) govern your access and use of our online workplace productivity tools and platform (the “Services”). Please read them carefully. Even though you are signing onto an existing workspace, these User Terms apply to you as a user of the Services. We are grateful you’re here.</p>

          {/*   section-2 */}
          <Element name='User Terms'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>User Terms</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, terms: !collapseItem.terms }); }}>
                {collapseItem.terms ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.terms || window.innerWidth > 768)
            && (
              <Element name='These User Terms are Legally Binding'>
                <p className='privacy-policy-inner-heading'>These User Terms are Legally Binding</p>
                <p className='privacy-policy-paragraph'>These User Terms are a legally binding contract between you and us. If you access or use the Services, or continue accessing or using the Services after being notified of a change to the User Terms, you confirm that you have read, understand and agree to be bound by the User Terms. “We”, “our” and “us” currently refers to the applicable Strada entity in the Contract (defined below).</p>
              </Element>
            ) }
          </Element>
          {' '}
          {/*   section-3 */}
          <Element name='Customer’s Choices and Instructions'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Customer’s Choices and Instructions</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, choice: !collapseItem.choice }); }}>
                {collapseItem.choice ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.choice || window.innerWidth > 768)
            && (
              <>
                <Element name='You are an Authorized User on a Workspace Controlled by a “Customer”'>
                  <p className='privacy-policy-inner-heading'>You are an Authorized User on a Workspace Controlled by a “Customer”</p>
                  <p className='privacy-policy-paragraph'>
                    An organization or other third party that we refer to in these User Terms as “Customer” has invited you to a workspace (i.e., a unique domain where a group of users may access the Services). If you are joining one of your employer’s workspaces, for example, Customer is your employer.
                  </p>
                </Element>
                <Element name='What This Means for You — and for Us'>
                  <p className='privacy-policy-inner-heading'>What This Means for You — and for Us</p>
                  <p className='privacy-policy-paragraph'>
                    When an Authorized User (including, you) submits content or information to the Services, such as messages or files (“Customer Data”), you acknowledge and agree that the Customer Data is owned by Customer and the Contract provides Customer with many choices and control over that Customer Data. For example, Customer may provision or deprovision access to the Services, enable or disable third party integrations, manage permissions, retention and export settings, transfer or assign workspaces, share channels, or consolidate your workspace or channels with other workspaces or channels, and these choices and instructions may result in the access, use, disclosure, modification or deletion of certain or all Customer Data.
                  </p>
                </Element>
                <Element name='The Relationship Between You, Customer and Us'>
                  <p className='privacy-policy-inner-heading'>The Relationship Between You, Customer and Us</p>
                  <p className='privacy-policy-paragraph'>
                    AS BETWEEN US AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER’S RESPONSIBILITY TO (A) INFORM YOU AND ANY AUTHORIZED USERS OF ANY RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY SETTINGS THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS, PERMISSIONS OR CONSENTS FROM YOU AND ANY AUTHORIZED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AND THE OPERATION OF THE SERVICES; (C) ENSURE THAT THE TRANSFER AND PROCESSING OF CUSTOMER DATA UNDER THE CONTRACT IS LAWFUL; AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU AND ANY AUTHORIZED USER RELATING TO OR BASED ON CUSTOMER DATA, THE SERVICES OR CUSTOMER’S FAILURE TO FULFILL THESE OBLIGATIONS. STRADA MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE SERVICES, WHICH ARE PROVIDED TO YOU ON AN “AS IS” AND “ AS AVAILABLE” BASIS.
                  </p>
                </Element>
              </>
            )}
          </Element>
          {' '}
          {/*   section-4 */}
          <Element name='Rules'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Rules</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, rules: !collapseItem.rules }); }}>
                {collapseItem.rules ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.rules || window.innerWidth > 768)
            && (
              <>
                <Element name='You Must be Over the Legal Age'>
                  <p className='privacy-policy-inner-heading'>You Must be Over the Legal Age</p>
                  <p className='privacy-policy-paragraph'>
                    To the extent prohibited by applicable law, the Services are not intended for and should not be used by (a) anyone under the age of sixteen or (b) anyone under the applicable age of majority according to the data protection laws and regulations in your jurisdiction. You represent that you are over the legal age and are the intended recipient of Customer’s invitation to the Services. You may not access or use the Services for any purpose if either of the representations in the preceding sentence is not true. Without limiting the foregoing, you must be of legal working age.
                  </p>
                </Element>
                <Element name='You Are Here At the Pleasure of Customer (and Us)'>
                  <p className='privacy-policy-inner-heading'>You Are Here At the Pleasure of Customer (and Us)</p>
                  <p className='privacy-policy-paragraph'>
                    These User Terms remain effective until Customer’s subscription for you expires or terminates, or your access to the Services has been terminated by Customer or us. Please contact Customer if you at any time or for any reason wish to terminate your account.
                  </p>
                </Element>
              </>
            )}
          </Element>
          {/*   section-5 */}
          <Element name='Limitation of Liability'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Limitation of Liability</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, limitation: !collapseItem.limitation }); }}>
                {collapseItem.limitation ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.limitation || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph'>If we believe that there is a violation of the Contract, User Terms or any of our other policies that can simply be remedied by Customer’s removal of certain Customer Data or taking other action, we will, in most cases, ask Customer to take action rather than intervene. We may directly step in and take what we determine to be appropriate action (including disabling your account) if Customer does not take appropriate action or we believe there is a credible risk of harm to us, the Services, Authorized Users, or any third parties. IN NO EVENT WILL YOU OR WE HAVE ANY LIABILITY TO THE OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNLESS YOU ARE ALSO A CUSTOMER (AND WITHOUT LIMITATION TO OUR RIGHTS AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY TO US FOR A BREACH OF THESE USER TERMS. OUR MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY BREACH OF THE USER TERMS IS ONE HUNDRED DOLLARS ($100) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY’S RIGHT TO SEEK AND OBTAIN EQUITABLE RELIEF.</p>
                <Element name='Application of Consumer Law'>
                  <p className='privacy-policy-inner-heading'>Application of Consumer Law</p>
                  <p className='privacy-policy-paragraph'>
                    Strada is a workplace tool intended for use by businesses and organizations and not for consumer purposes. To the maximum extent permitted by law, you hereby acknowledge and agree that consumer laws do not apply. If however any consumer laws (e.g., in Australia, the Competition and Consumer Act 2010 (Cth)) do apply and cannot otherwise be lawfully excluded, nothing in these User Terms will restrict, exclude or modify any statutory warranties, guarantees, rights or remedies you have, and our liability is limited (at our option) to the replacement, repair or resupply of the Services or the pro-rata refund to Customer of pre-paid fees for your subscription covering the remainder of the term.
                  </p>
                </Element>
              </>
            ) }
          </Element>
          {/*   section-6 */}
          <Element name='General Provisions'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>General Provisions</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, general: !collapseItem.general }); }}>
                {collapseItem.general ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.general || window.innerWidth > 768)
            && (
              <>
                <Element name='Email and Strada Messages'>
                  <p className='privacy-policy-inner-heading'>Email and Strada Messages</p>
                  <p className='privacy-policy-paragraph'>
                    Except as otherwise set forth herein, all notices under the User Terms will be by email, although we may instead choose to provide notice to Authorized Users through the Services (e.g., a Stradabot notification). Notices to Strada should be sent to
                    {' '}
                    <a
                      href='mailto:feedback@strada.ai'
                      className='privacy-policy-email-link'
                    >
                      feedback@strada.ai
                    </a>
                    {' '}
                    . A notice will be deemed to have been duly given (a) the day after it is sent, in the case of a notice sent through email; and (b) the same day, in the case of a notice sent through the Services. Notices under the Contract will be delivered solely to Customer in accordance with the terms of that agreement.
                  </p>
                </Element>
                <Element name='Privacy Policy'>
                  <p className='privacy-policy-inner-heading'>Privacy Policy</p>
                  <p className='privacy-policy-paragraph'>
                    Please review our Privacy Policy for more information on how we collect and use data relating to the use and performance of our products.
                  </p>
                </Element>
                <Element name='Modifications'>
                  <p className='privacy-policy-inner-heading'>Modifications</p>
                  <p className='privacy-policy-paragraph'>
                    As our business evolves, we may change these User Terms. If we make a material change to the User Terms, we will provide you with reasonable notice prior to the change taking effect either by emailing the email address associated with your account or by messaging you through the Services. You can review the most current version of the User Terms at any time by visiting this page. Any material revisions to these User Terms will become effective on the date set forth in our notice, and all other changes will become effective on the date we publish the change. If you use the Services after the effective date of any changes, that use will constitute your acceptance of the revised terms and conditions.
                  </p>
                </Element>
                <Element name='Waiver'>
                  <p className='privacy-policy-inner-heading'>Waiver</p>
                  <p className='privacy-policy-paragraph'>
                    No failure or delay by either party in exercising any right under the User Terms, will constitute a waiver of that right. No waiver under the User Terms will be effective unless made in writing and signed by an authorized representative of the party being deemed to have granted the waiver.
                  </p>
                </Element>
                <Element name='Severability'>
                  <p className='privacy-policy-inner-heading'>Severability</p>
                  <p className='privacy-policy-paragraph'>
                    The User Terms will be enforced to the fullest extent permitted under applicable law. If any provision of the User Terms is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the User Terms will remain in effect.
                  </p>
                </Element>
                <Element name='Assignment'>
                  <p className='privacy-policy-inner-heading'>Assignment</p>
                  <p className='privacy-policy-paragraph'>
                    You may not assign any of your rights or delegate your obligations under these User Terms, whether by operation of law or otherwise, without the prior written consent of us (not to be unreasonably withheld). We may assign these User Terms in their entirety (including all terms and conditions incorporated herein by reference), without your consent, to a corporate affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of our assets.
                  </p>
                </Element>
                <Element name='Governing Law; Venue; Fees'>
                  <p className='privacy-policy-inner-heading'>Governing Law; Venue; Fees</p>
                  <p className='privacy-policy-paragraph'>
                    The User Terms and any disputes arising out of or related hereto, will be governed exclusively by the same applicable governing law of the Contract, without regard to conflicts of laws rules or the United Nations Convention on the International Sale of Goods. The courts located in the applicable venue of the Contract will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to the User Terms, or its formation, interpretation or enforcement.
                  </p>
                  <p className='privacy-policy-paragraph'>
                    Each party hereby consents and submits to the exclusive jurisdiction of such courts. In any action or proceeding to enforce rights under the User Terms, the prevailing party will be entitled to recover its reasonable costs and attorney’s fees.
                  </p>
                </Element>
                <Element name='Entire Agreement'>
                  <p className='privacy-policy-inner-heading'>Entire Agreement</p>
                  <p className='privacy-policy-paragraph'>
                    The User Terms, including any terms incorporated by reference into the User Terms, constitute the entire agreement between you and us and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. To the extent of any conflict or inconsistency between the provisions in these User Terms and any pages referenced in these User Terms, the terms of these User Terms will first prevail; provided, however, that if there is a conflict or inconsistency between the Contract and the User Terms, the terms of the Contract will first prevail, followed by the provisions in these User Terms, and then followed by the pages referenced in these User Terms (e.g., the Privacy Policy). Customer will be responsible for notifying Authorized Users of those conflicts or inconsistencies and until such time the terms set forth herein will be binding.
                    {' '}

                  </p>
                </Element>
                <Element name='Contacting Strada'>
                  <p className='privacy-policy-inner-heading'>Contacting Strada</p>
                  <p className='privacy-policy-paragraph'>
                    Please also feel free to contact us if you have any questions about Strada’s User Terms of Service. You may contact us at
                    {' '}
                    <a
                      href='mailto:feedback@strada.ai'
                      className='privacy-policy-email-link'
                    >
                      feedback@strada.ai
                    </a>
                    {' '}

                  </p>
                </Element>
              </>
            )}
          </Element>

        </div>

      </div>
    </div>
  );
}

export default Content;
