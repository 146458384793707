/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { Grid, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputField from 'shared-components/inputs/InputField';
import ContactIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import type { IContactDetails, IContactNumbers } from '../types';

interface IProps {
  contactList: IContactDetails[];
  setContactList: (value: IContactDetails[]) => void;
  setAddContact: (value: boolean) => void;
  addContact: boolean;
}

export default function AddContact(props: IProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const {
    setContactList, setAddContact, contactList, addContact,
  } = props;
  const [contact, setContact] = useState<IContactDetails>({
    full_name: '', email: '', phone: '', additional_contact_numbers: [], role: '', note: '', unique_position_key: '',
  });
  const [editContact, setEditContact] = useState(false);
  const [editContactIndex, setEditContactIndex] = useState(NaN);
  const [error, setError] = useState(false);

  const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  function formatPhoneNumber(phoneNumber: string | null): string | null {
    if (phoneNumber === '' || phoneNumber === null) {
      return '';
    }
    // Remove all non-digit characters except the leading '+'
    let digits = phoneNumber.replace(/[^\d+]/g, '');

    if ((digits.startsWith('+') && digits.length < 11) || digits.length < 10) {
      return '';
    }

    let countryCode = '';
    if (digits.startsWith('+')) {
      countryCode = digits.slice(0, 2);
      digits = digits.slice(2);
    } else if (digits.startsWith('1') && digits.length > 10) {
      countryCode = digits.slice(0, 1);
      digits = digits.slice(1);
    }

    if (digits.length > 10) {
      digits = digits.slice(-10);
    }
    const formattedNumber = `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;

    if (countryCode) {
      return `${countryCode}-${formattedNumber}`;
    }
    return formattedNumber;
  }

  const onContactSubmit = (): void => {
    if (contact.email === '' || (contact.email !== null && contact.email !== '' && emailRegex.test(contact.email))) {
      const formattedPhone = formatPhoneNumber(contact.phone);
      const formattedAdditional: IContactNumbers[] = [];
      if (contact.additional_contact_numbers !== null && contact.additional_contact_numbers?.length > 0) {
        contact.additional_contact_numbers.forEach((item) => {
          const formattedAdd = formatPhoneNumber(item.phone);
          formattedAdditional.push({ ...item, phone: formattedAdd });
        });
      }

      if (editContact) {
        contactList[editContactIndex] = { ...contact, phone: formattedPhone, additional_contact_numbers: formattedAdditional };
        setContactList(contactList);
        setEditContact(false);
      } else {
        setContactList([...contactList, {
          ...contact, phone: formattedPhone, additional_contact_numbers: formattedAdditional, unique_position_key: Date.now().toString(),
        }]);
        setAddContact(false);
      }
      setContact({
        full_name: '', email: '', phone: '', additional_contact_numbers: [], role: '', note: '', unique_position_key: '',
      });
    } else {
      enqueueSnackbar('Enter valid email.');
    }
  };

  const getContactDisabledCheck = (): boolean => {
    // if (contact.full_name !== '' && contact.email !== '') {
    if (contact.full_name !== '') {
      if (contact.additional_contact_numbers !== null && contact.additional_contact_numbers?.length > 0) {
        const uniqueNumbers = Array.from(new Set(contact.additional_contact_numbers.map((item) => item.phone)));
        if (uniqueNumbers.length === contact.additional_contact_numbers.length) {
          let check = false;
          // eslint-disable-next-line array-callback-return
          contact.additional_contact_numbers.map((item) => {
            if (formatPhoneNumber(item.phone) === formatPhoneNumber(contact.phone) || item.phone === '' || item.phone === null || !phoneRegex.test(item.phone)) {
              check = true;
              return true;
            }
          });
          if (!check) {
            return false;
          }
        }
        return true;
      }
      return false;
    }
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>, item: IContactNumbers): void => {
    const { value } = e.target;
    // if (phoneRegex.test(value) || value === '') {
    const contactNumbers = contact.additional_contact_numbers?.map((signleNumber) => {
      if (signleNumber.unique_position_key === item.unique_position_key) {
        signleNumber.phone = value;
      }
      return signleNumber;
    });
    if (contactNumbers !== undefined) {
      setContact({ ...contact, additional_contact_numbers: contactNumbers });
    }
    // }
  };

  const AddMobileNumber = (): void => {
    if (contact?.additional_contact_numbers !== null) {
      const obj = {
        phone: '',
        unique_position_key: Date.now().toString(),
      };
      const data: IContactNumbers[] = contact.additional_contact_numbers;
      data.push(obj);
      setContact({ ...contact, additional_contact_numbers: data });
    }
  };

  const RemoveMobileNumber = (index: number): void => {
    if (contact?.additional_contact_numbers !== null) {
      const data: IContactNumbers[] = contact.additional_contact_numbers;
      data.splice(index, 1);
      setContact({ ...contact, additional_contact_numbers: data });
    }
  };

  const handleContactCancel = (): void => {
    setAddContact(false);
    setEditContact(false);

    const cleanContactList = contactList.map((item) => {
      if (item.additional_contact_numbers && item.additional_contact_numbers.length > 0) {
        item.additional_contact_numbers = item.additional_contact_numbers?.filter((number) => number.phone !== '' && phoneRegex.test(number.phone ?? ''));
      }
      return item;
    });

    setContactList(cleanContactList);

    setContact({
      full_name: '', email: '', phone: '', additional_contact_numbers: [], role: '', note: '', unique_position_key: '',
    });
  };

  useEffect(() => {
    const isValidPhone = (phone: string | null): boolean => phone !== null && phoneRegex.test(phone) && phone.replace(/[^\d+]/g, '').length <= 20 && phone.replace(/[^\d+]/g, '').length >= 10;

    const mainPhoneValid = contact.phone !== '' && contact.phone !== null ? isValidPhone(contact.phone) : true;
    const additionalPhonesValid = contact.additional_contact_numbers?.every((item) => item.phone === '' || item.phone === null || isValidPhone(item.phone)) ?? true;

    setError(!mainPhoneValid || !additionalPhonesValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const renderAddContact = (): JSX.Element => (
    <>
      <Grid container columnSpacing={3} className='mt-3'>
        <Grid item sm={12} md={12}>

          <InputField
            name='full_name'
            label='Full Name*'
            maxLength={150}
            value={contact.full_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleChange(e); }}
          />
        </Grid>
        <Grid item sm={12} md={12}>

          <InputField
            name='email'
            label='Email'
            maxLength={150}
            value={contact.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleChange(e); }}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <InputField
            name='phone'
            label='Phone'
            maxLength={16}
            minLength={10}
            value={contact.phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleChange(e); }}
          />

        </Grid>
        {contact?.additional_contact_numbers?.map((item, index) => (
          <Grid item sm={12} md={12}>
            <div className='d-flex'>
              <InputField
                name='phone'
                label='Phone'
                maxLength={16}
                minLength={10}
                value={item.phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleNumberChange(e, item); }}
              />
              <div className='ms-4 mt-2' aria-hidden='true' onClick={(): void => { RemoveMobileNumber(index); }}><DeleteIcon /></div>
            </div>
          </Grid>
        ))}
        <Grid item sm={12} md={12}>
          {error ? <p className='phone-vali-error'>Enter a valid phone number</p> : ''}
        </Grid>
        <Grid item sm={12} md={12}>
          <Button
            variant='outlined'
            className='text-transform-none text-dark'
            disabled={contact.phone === '' || error}
            style={{
              border: '1px solid #e4e4e4', padding: '5px 10px', marginBottom: '20px', cursor: contact.phone === 'no-drop' ? '' : 'pointer',
            }}
            onClick={(): void => { AddMobileNumber(); }}
          >
            <span className='me-2'><ContactIcon /></span>
            Add mobile number
          </Button>
        </Grid>
        <Grid item sm={12} md={12}>

          <InputField
            name='role'
            label='Role'
            value={contact.role}
            maxLength={150}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleChange(e); }}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <InputField
            name='note'
            label='Type your notes (optional)'
            type='text'
            maxLength={150}
            value={contact.note}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleChange(e); }}
          />
        </Grid>
        <Grid item sm={6} className='text-start mt-3'>
          <Button
            variant='outlined'
            className='text-transform-none text-dark'
            style={{ border: '1px solid #e4e4e4' }}
            onClick={(): void => { handleContactCancel(); }}
          >
            Cancel
          </Button>
          <Button
            onClick={(): void => { onContactSubmit(); }}
            variant='contained'
            disabled={getContactDisabledCheck() || error}
            className='ms-2 text-transform-none text-white'
          >
            Save Contact
          </Button>
        </Grid>
      </Grid>

      {editContact && <div className='mt-3 mb-2' style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />}

    </>
  );

  const handleEditContact = (index: number): void => {
    setContact(contactList[index]);
    setEditContactIndex(index);
    setEditContact(true);
  };

  const handleDeleteContact = (index: number): void => {
    const data = [...contactList];
    data.splice(index, 1);
    setContactList(data);
  };

  function makeLinksClickable(text: string): string {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, (url) => `<a href="${url}" style="color: #00CFA1" target="_blank" rel="noopener noreferrer">${url}</a>`);
  }

  const createMarkup = (text: string): { __html: string } => ({ __html: makeLinksClickable(text) });

  return (
    <div className='mt-2'>
      {contactList.length > 0 && contactList.map((vc, index) => {
        if (editContact && contact.unique_position_key === vc.unique_position_key) {
          return renderAddContact();
        }
        return (
          <Grid item className='message-card'>
            <div
              className='message-email'
              style={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
              }}
            >
              {vc.full_name}
              <div className='message-options'>
                <EditIcon className='cursor-pointer' onClick={(): void => { handleEditContact(index); }} />
                <span style={{ marginLeft: '5px' }}>
                  <DeleteIcon className='cursor-pointer' onClick={(): void => { handleDeleteContact(index); }} />
                </span>
              </div>
            </div>
            {vc.email !== '' && vc.email !== null && (
              <div className='message-subject d-flex mt-2 mb-2'>
                <img src={`${process.env.REACT_APP_ASSESTS_URL}mail_icon.webp`} alt='' className='me-3' style={{ height: '100%' }} />
                <p style={{ color: '#00CFA1' }}>{vc.email}</p>
              </div>
            )}
            {vc.phone !== '' && vc.phone !== null && (
              <div className='message-subject d-flex mt-2 mb-2'>
                <img src={`${process.env.REACT_APP_ASSESTS_URL}phone_icon.webp`} alt='' className='me-3' style={{ height: '100%' }} />
                <div className='d-flex'>
                  <p>{formatPhoneNumber(vc.phone)}</p>
                  {vc.additional_contact_numbers !== null && vc.additional_contact_numbers !== undefined && vc.additional_contact_numbers.length > 0
                    && vc.additional_contact_numbers.map((singleNumber, indx) => (
                      <>
                        {vc.additional_contact_numbers?.length !== indx && <pre>, </pre>}
                        <p>{formatPhoneNumber(singleNumber.phone)}</p>
                      </>
                    ))}

                </div>

              </div>
            )}
            {vc.role !== '' && vc.role !== null && (
              <div className='message-subject d-flex mt-2 mb-2'>
                <img src={`${process.env.REACT_APP_ASSESTS_URL}role_icon.webp`} alt='' className='me-3' style={{ height: '100%' }} />
                <p>{vc.role}</p>
              </div>
            )}
            {vc.note !== undefined && vc.note !== '' && vc.note !== null && (
              <div className='message-subject d-flex mt-2 mb-2'>
                <img src={`${process.env.REACT_APP_ASSESTS_URL}notes_icon.webp`} alt='' className='me-3' style={{ height: '100%' }} />
                {/* <p>{vc.note}</p> */}
                <p dangerouslySetInnerHTML={createMarkup(vc.note)} />
              </div>
            )}
          </Grid>
        );
      })}
      {addContact && renderAddContact()}

    </div>
  );
}
