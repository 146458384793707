/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-void */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Popover,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  OutlinedInput,
  DialogTitle,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InfoIcon from '@mui/icons-material/Info';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import Papa from 'papaparse';
import LinearProgress from '@mui/material/LinearProgress';
import { useSnackbar } from 'notistack';
import CustomLoader from 'shared-components/components/CustomLoader';
import PrimayButton from 'shared-components/components/PrimayButton';
import { Box } from '@mui/system';
import * as XLSX from 'xlsx';
import { toInteger } from 'lodash';
import StradaLoader from 'shared-components/components/StradaLoader';
import PreviewModal from './PreviewModal';

interface AllCSVs {
  file: File | FormData | null;
  user_columns: ColumnMappings;
  year: string;
  ignore_columns: IColOptions[];
  properties: number;
  errorIndex: number;
  skip_rows: number;
  isValidBuilding: boolean;
  isPropertyUpdated: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ColumnMappings = Record<string, string>;

interface IColOptions {
  columnName: string;
  isChecked: boolean;
}
interface IMessage {
  message: string;
}
interface IDetail {
  message: string;
  result: IMessage;
  task_id: string;
}
interface IData {
  detail: IDetail;
}
interface IResponse {
  data: IData;
}
interface IEventsData {
  building: number | string;
  year: number | string;
}
interface IBuildingOptions {
  value: number;
  label: string;
  address: string;
}
interface IBuildingSelectOptions {
  key: number;
  value: string;
}
interface IRefactoredData {
  name: string;
  link: string;
}
interface IRefactoredErrorData {
  filename: string;
  message: string;
}
// eslint-disable-next-line @typescript-eslint/no-type-alias
type IColumnMappings = Record<string, string>;
interface SplitResult {
  name: string | null;
  address: string | null;
}

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#eeeeee',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00CFA1',
  },
}))(LinearProgress);
function CollapseIcon(): JSX.Element {
  return (
    <svg
      width='12'
      height='8'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 0.015625L12 6.01562L10.5938 7.42188L6 2.82812L1.40625 7.42188L0 6.01562L6 0.015625Z'
        fill='#212121'
        fillOpacity='0.6'
      />
    </svg>
  );
}

function HammerIcon(): JSX.Element {
  return (

    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 2.5C11.3807 2.5 12.5 3.61929 12.5 5V15C12.5 16.3807 11.3807 17.5 10 17.5S7.5 16.3807 7.5 15V5C7.5 3.61929 8.61929 2.5 10 2.5Z
        M5 10C5 11.3807 5.61929 12.5 6.5 12.5H13.5C14.3807 12.5 15 11.3807 15 10S14.3807 7.5 13.5 7.5H6.5C5.61929 7.5 5 8.61929 5 10Z'
        fill='crimson'
      />
    </svg>
  );
}

function TickIcon(): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.98438 15.0156L16.9844 6.01562L15.5781 4.5625L7.98438 12.1562L4.42188 8.59375L3.01562 10L7.98438 15.0156ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z'
        fill='#00CFA1'
      />
    </svg>
  );
}

function CrossIcon(): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.0156 13.6094L11.4062 10L15.0156 6.39062L13.6094 4.98438L10 8.59375L6.39062 4.98438L4.98438 6.39062L8.59375 10L4.98438 13.6094L6.39062 15.0156L10 11.4062L13.6094 15.0156L15.0156 13.6094ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z'
        fill='#C62828'
      />
    </svg>
  );
}

export default function ImportCSV(): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );
  const [allCSVs, setAllCSVs] = useState<AllCSVs[]>([]);
  const [allRefactorCSVs, setAllRefactorCSVs] = useState<File[]>([]);
  const [refactoredLinkData, setRefactoredLinkData] = useState<IRefactoredData[]>([]);
  const [refactoredErrorData, setRefactoredErrorData] = useState<IRefactoredErrorData[]>([]);
  const [currentCSV, setCurrentCSV] = useState<AllCSVs | null>(null);
  const [currentCSVIndex, setCurrentCSVIndex] = useState<number>(-1);
  const [csvData, setCSVData] = useState<string[][] | null>(null);
  const [columnMappings, setColumnMappings] = useState<IColumnMappings>({});
  const [dafaultHeadings, setDafaultHeadings] = useState<string[]>([]);
  const [options, setOptions] = useState<string[][]>([]);
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [building, setBuilding] = useState<number>(-1);
  const [errorIndex, setErrorIndex] = useState<number>(-1);
  const [isEventName, setIsEventName] = useState('');

  const [buildingOptions, setBuildingOptions] = useState<IBuildingOptions[]>(
    [],
  );
  const [columnOptions, setColumnOptions] = useState<IColOptions[]>([]);
  const [selectedColumnOptions, setSelectedColumnOptions] = useState<
  IColOptions[]
  >([]);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [openExistingEvents, setOpenExistingEvents] = useState<boolean>(false);
  const [openRemoveEvents, setOpenRemoveEvents] = useState<boolean>(false);
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  const [skipRows, setskipRows] = useState<number>(-1);
  const [previewURL, setPreviewURL] = useState('');
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [showBuidings, setShowBuidings] = useState<boolean>(true);
  const [csvLength, setCsvLength] = useState<number>(0);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const [isPropertyUpdated, setIsPropertyUpdated] = useState<boolean>(false);
  const [mappedColData, setMappedColData] = useState<{ option: string; value: string; index: number }>({ option: '', value: '', index: -1 });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentColIndex, setCurrentColIndex] = useState(-1);
  const [existingProperties, setExistingProperties] = useState<number[]>([]);
  const [checkPropertyOnRender, setCheckPropertyOnRender] = useState<boolean>(true);

  const headerRef = React.useRef<HTMLTableElement>(null);

  const defaultOption = [
        'property',
        'event_name',
        'acct',
        'vendor',
        'project_tag',
        'exact_due_date',
        'notes',
        'assignee',
        'notify_assignee',
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
        'total',
    ];

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  async function parseCsvFile(file: File): Promise<Papa.ParseResult<unknown>> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (result) => {
          resolve(result);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  const updateCsvFile = (updatedData: string[][]): void => {
    if (csvData === null || currentCSV === null) return;
    setCSVData(updatedData);
    const currentFileName = (currentCSV.file as File).name || 'updatedFile.csv';
    const csvBlob = new Blob([Papa.unparse(updatedData)], { type: 'text/csv' });
    const csvFile = new File([csvBlob], currentFileName, { type: 'text/csv' });
    setAllCSVs(allCSVs.map((item, index): AllCSVs => {
      if (index === currentCSVIndex) {
        item.file = csvFile;
        setCurrentCSV(item);
        return item;
      }
      return item;
    }));

    const tmpOptions = updatedData[0].map((op, indx): string[] => {
      if (/^\d+__/.test(op)) {
        op === op.split('__')[1];
      }
        if (indx !== 0 && (op === '' || op.includes('Unnamed:') || !defaultOption.includes(op.trim().toLowerCase()))) {
        const header = `Unnamed ${indx}`;
        return [header, ...dafaultHeadings];
      }
      return [op, ...dafaultHeadings];
    });

    setOptions(tmpOptions);
  };

const handleSkipedRows = (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  if (currentCSV == null || csvData == null) return;
  setColumnMappings({});
  setExistingProperties([]);
  setskipRows(Number(event.target.value) >= csvLength - 1 ? csvLength - 2 : Number(event.target.value));
// to be
  const updateFirstColumn = (data: string[][], newValue: string): string[][] => data.map((row) => {
      const updatedRow = [...row];
      updatedRow[0] = newValue;
      return updatedRow;
    });
  const updatedCSVData = updateFirstColumn(csvData, 'mapped_properties');
  updateCsvFile(updatedCSVData);
};
  const handleSpecialCases = (parts: string[]): SplitResult => {
    let name: string | null = null;
    let address: string | null = null;

    if (parts.length > 0) {
      name = parts[0].trim();
    }

    if (parts.length > 1) {
      address = parts.slice(1).join(' ').trim();
    }
    if (name && /[#!]/.test(name)) {
      const nameParts = name.split(/[#!]/);
      name = nameParts[0].trim();
      const additionalAddress = nameParts.slice(1).join(' ').trim();
      address = address ? `${address} ${additionalAddress}` : additionalAddress;
    }
    return { name, address };
  };
  const splitNameAndAddress = (s: string): SplitResult => {
    let name: string | null = null;
    let address: string | null = null;
    if (!s) {
      return { name, address };
    }

    if (s.includes(';')) {
      const parts = s.split(';');
      name = parts[0].trim();
      address = parts[1].trim();
    } else if (s.includes('-')) {
      const parts = s.split(/-(?![^(]*\))/);
      if (parts.length === 2) {
        name = parts[0].trim();
        address = parts[1].trim();
      } else {
        const specialResult = handleSpecialCases(parts);
        name = specialResult.name;
        address = specialResult.address;
      }
    } else if (s.includes('#') || s.includes(':')) {
      const delimiter = s.includes('#') ? '#' : ':';
      const [namePart, addressPart] = s.split(delimiter, 2);
      name = namePart.trim();
      address = addressPart.trim();
    } else if (s.includes('(') && s.includes(')')) {
      const match = /(.*?)\((.*?)\)/.exec(s);
      if (match) {
        name = match[1].trim();
        address = match[2].trim();
      }
    } else {
      const parts = s.split(/\(([^)]*)\)/);
      const specialResult = handleSpecialCases(parts);
      name = specialResult.name;
      address = specialResult.address;
    }

    if (address !== null || name !== null) {
      const match = /(.*?)(\d{4,}.*)/.exec(s);
      if (match) {
        name = match[1].trim();
        address = match[2].trim();
      }
    }

    name = name ? name.replace(/\s+/g, ' ') : null;
    address = address ? address.replace(/\s+/g, ' ') : null;

    const addressParts = address ? address.split(/[;,-]/) : null;
    address = addressParts
      ? addressParts.map((part) => part.trim()).join(' ')
      : null;

    if (address) {
      // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
      address = address
        .split(' ')
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
        .join(' ');
    }

    return { name, address };
  };
  const getPropertyOptions = (
    originalCol = '',
  ): IBuildingSelectOptions[] => {
    const rowProperties = [...buildingOptions];
    if (originalCol === '') {
      originalCol = 'Select Property';
    }
    rowProperties.unshift({
      value: -1,
      address: originalCol,
      label: '',
    });
    const optionsData = Array.from(
      new Set(
        rowProperties.map((option) => ({
          key: option.value,
          value: option.address.trim().toLowerCase(),
        })),
      ),
    );
    const uniqueOptionsData = optionsData.filter((item, index, self) => index === self.findIndex((t) => t.value === item.value));
    return uniqueOptionsData;
  };
  const isPropertyMatched = (property: string): boolean => {
    if (property === 'mapped_properties') {
      return false;
    }
    if (/^\d+__/.test(property)) {
      return true;
    }

    const splitResult = splitNameAndAddress(property);

    return (
      getPropertyOptions().some(
        (option) => option.value === splitResult.name,
      )
      || getPropertyOptions().some(
        (option) => option.value === splitResult.address,
      )
      || getPropertyOptions().some((option) => option.value === property)
    );
  };
  const processCSVData = (): void => {
    if (csvData === null || buildingOptions.length === 0) return;
    const rowProperties = [...buildingOptions];
    const valuesList = Array.from(
      new Set(
        rowProperties.map((option) => ({
          key: option.value,
          value: option.address.trim().toLowerCase(),
        })),
      ),
    );
    let propertyColumnKey = '';
    for (const key in columnMappings) {
      if (columnMappings[key] === 'property') {
        propertyColumnKey = key;
        break;
      }
    }

      if (propertyColumnKey) {
      const propertyColumnIndex = Number(propertyColumnKey.split(' ')[1]);
      const propertyValuesToMatch = valuesList.map((item) => item.value.trim().toLowerCase());
      const matchingRowIndices: number[] = [];
      const updatedCSV: string[][] = JSON.parse(JSON.stringify(csvData));
      const normalizeString = (str: string): string => str.toLowerCase().replace(/[^a-z0-9]+/g, '');
      setCheckPropertyOnRender(false);
      if (propertyColumnIndex) {
        csvData.slice(skipRows).forEach((row, rowIndex) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const rowValue = row[propertyColumnIndex] !== undefined ? row[propertyColumnIndex].trim().toLowerCase() : '';
          const existsInPropertyValues = propertyValuesToMatch.some((propertyValue) => normalizeString(propertyValue) === normalizeString(rowValue));
          if (existsInPropertyValues) {
            matchingRowIndices.push(rowIndex);
            const indexOfId = propertyValuesToMatch.findIndex((propertyValue) => normalizeString(propertyValue) === normalizeString(rowValue));
            updatedCSV[rowIndex + skipRows][0] = `${valuesList[indexOfId].key}__${row[propertyColumnIndex].trim().toLowerCase()}---${row[propertyColumnIndex].trim().toLowerCase()}`;
          }
        });
      }
      // else {
      //   /// in case where property already exist in header of csv {property:property} and propertyColumnIndex is not available
      //     // eslint-disable-next-line prefer-destructuring
      //     const propertyIndex = csvData.slice(skipRows)[0].findIndex((item) => (item ? item.trim().toLowerCase() === 'property' : -1));
      //       if (propertyIndex !== -1) {
      //         csvData.slice(skipRows).forEach((row, rowIndex) => {
      //            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      //         const rowValue = row[propertyColumnIndex] !== undefined ? row[propertyColumnIndex].trim().toLowerCase() : '';
      //         const existsInPropertyValues = propertyValuesToMatch.some((propertyValue) => normalizeString(propertyValue) === normalizeString(rowValue));
      //         if (existsInPropertyValues) {
      //          matchingRowIndices.push(rowIndex);
      //          const indexOfId = propertyValuesToMatch.findIndex((propertyValue) => normalizeString(propertyValue) === normalizeString(rowValue));
      //          updatedCSV[rowIndex][0] = `${valuesList[indexOfId].key}__${row[propertyColumnIndex].trim().toLowerCase()}---${row[propertyColumnIndex].trim().toLowerCase()}`;
      //         } else {
      //        ///   to be
      //           updatedCSV[rowIndex][0] = 'mapped_properties';
      //         }
      //         });
      //     }
      // }
      updateCsvFile(updatedCSV);
      setExistingProperties(matchingRowIndices);
    }
  };

  const handleCSVFile = (singleFile: AllCSVs, index: number): void => {
    setIsPropertyUpdated(false);
    setCurrentCSV(singleFile);
    setCurrentCSVIndex(index);
    setTableLoader(true);
    setExistingProperties([]);
    setCheckPropertyOnRender(true);
    if (singleFile.file !== null && !(singleFile.file instanceof FormData)) {
      Papa.parse(singleFile.file, {
        complete: (result) => {
          const parsedData = result.data as string[][];
          setCsvLength(parsedData.length);
          setColumnMappings(singleFile.user_columns);
          setSelectedColumnOptions(singleFile.ignore_columns);
          setYear(singleFile.year);
          setBuilding(singleFile.properties);
          setErrorIndex(singleFile.errorIndex);
          setskipRows(singleFile.skip_rows);
          const headers: string[] = [];
          const tmpOptions = parsedData
            .slice(singleFile.skip_rows)[0]
            .map((op, indx): string[] => {
              if (indx !== 0 && (op === '' || op.includes('Unnamed:') || !defaultOption.includes(op.trim().toLowerCase()))) {
                const header = `Unnamed ${indx}`;
                headers.push(header);
                return [header, ...dafaultHeadings];
              }
              headers.push(op);
              return [op, ...dafaultHeadings];
            });
          setCSVData([headers, ...parsedData.slice(1)]);
          setOptions(tmpOptions);
        },

       });
    }

    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getFirstColumn = (option: string): string => {
    if (/^\d+__/.test(option)) {
      return option.split('---')[0];
    }
    return `${option.split('---')[1]}`;
  };

  const handlePropertyChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    rowIndex: number,
    columnIndex: number,
  ): void => {
    let selectedProperty = event.target.value;
    rowIndex = rowIndex + skipRows + 1;
    setIsPropertyUpdated(true);
    setCurrentColIndex(columnIndex);
    csvData !== null
      && setCSVData((prevCsvData) => {
        const newCsvData = prevCsvData === null ? csvData : prevCsvData;
        const newRow = [...newCsvData[rowIndex]];
        const actualValue = newRow[0];
        const actualProperty = actualValue.split('---')[1];

        if (selectedProperty.startsWith('-1')) {
          selectedProperty = 'mapped_properties';
        }
        newRow[0] = `${selectedProperty}---${actualProperty}`;
        newCsvData[rowIndex] = newRow;
        updateCsvFile(newCsvData);
        return newCsvData;
      });
  };

  const handleColumnMapping = (
    column: string,
    selectedTemplateColumn: string,
    index: number,
  ): void => {
        setExistingProperties([]);
        if (csvData == null || currentCSV == null) return;
        index && currentColIndex === index ? setIsPropertyUpdated(false) : '';
    const keyExist = csvData
      .slice(skipRows)[0]
      .slice(1)
      .map((string) => string.trim().toLowerCase())
      .indexOf(selectedTemplateColumn);
    let isExitInMapping = false;
    // eslint-disable-next-line guard-for-in
    for (const key in columnMappings) {
      if (key.trim().toLowerCase() === selectedTemplateColumn) {
        isExitInMapping = true;
      }
      if (columnMappings[key] === selectedTemplateColumn) {
        if (columnMappings[key] === 'property') {
          let keyExist = -1;
          let columnIndex = -1;
          if (key.includes('Unnamed')) {
            keyExist = toInteger(key.split(' ')[1]) - 1;
          } else {
            keyExist = csvData
              .slice(skipRows)[0]
              .slice(1)
              .map((string) => string)
              .indexOf(key);
          }
          if (column.includes('Unnamed')) {
            columnIndex = toInteger(column.split(' ')[1]) - 1;
          } else {
            columnIndex = csvData
              .slice(skipRows)[0]
              .slice(1)
              .map((string) => string)
              .indexOf(column);
          }
          if (keyExist !== -1) {
            const updatedData = csvData.slice(1).map((row) => {
              row[0] = `mapped_properties---${row[columnIndex + 1]}`;
              return row;
            });
            updateCsvFile([csvData[0], ...updatedData]);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete columnMappings[key];
      }
    }

    if (
      keyExist !== -1
      && !isExitInMapping
      && selectedTemplateColumn === 'property'
    ) {
      const newHeader = `Unnamed ${keyExist + 1}`;
      csvData[0][keyExist + 1] = newHeader;
      let columnIndex = -1;
      if (column.includes('Unnamed')) {
        columnIndex = toInteger(column.split(' ')[1]) - 1;
      } else {
        columnIndex = csvData
          .slice(skipRows)[0]
          .slice(1)
          .map((string) => string)
          .indexOf(column);
      }
      const updatedData = csvData.slice(1).map((row) => {
        row[0] = `mapped_properties---${row[columnIndex + 1]}`;
        return row;
      });
      const newDataWithHeader = [csvData[0], ...updatedData];
      updateCsvFile(newDataWithHeader);
    } else if (
      keyExist === -1
      && !isExitInMapping
      && selectedTemplateColumn === 'property'
    ) {
      let columnIndex = -1;
      if (column.includes('Unnamed')) {
        columnIndex = toInteger(column.split(' ')[1]) - 1;
      } else {
        columnIndex = csvData
          .slice(skipRows)[0]
          .slice(1)
          .map((string) => string)
          .indexOf(column);
      }
      const updatedData = csvData.slice(1).map((row) => {
        row[0] = `mapped_properties---${row[columnIndex + 1]}`;
        return row;
      });
      const newDataWithHeader = [csvData[0], ...updatedData];
      updateCsvFile(newDataWithHeader);
    } else if (keyExist !== -1 && selectedTemplateColumn === 'property') {
      let columnIndex = -1;
      if (column.includes('Unnamed')) {
        columnIndex = toInteger(column.split(' ')[1]) - 1;
      } else {
        columnIndex = csvData[0]
          .slice(1)
          .map((string) => string)
          .indexOf(column);
      }
      const updatedData = csvData.slice(1).map((row) => {
        row[0] = `mapped_properties---${row[columnIndex + 1]}`;
        return row;
      });
      const newDataWithHeader = [csvData[0], ...updatedData];
      updateCsvFile(newDataWithHeader);
    }

    setColumnMappings(columnMappings);

    if (selectedTemplateColumn.toLowerCase().trim() === 'property') {
      setShowBuidings(false);
      setAllCSVs(
        allCSVs.map((item, idx): AllCSVs => {
          if (idx === currentCSVIndex) {
            item.isValidBuilding = true;
            setCurrentCSV(item);
          }
          return item;
        }),
      );
    }

    const regex = /event|\W|\s/i;
    setIsEventName(selectedTemplateColumn);
    if (regex.test(selectedTemplateColumn)) {
      setErrorIndex(index);
    }

    setColumnMappings((prevMappings) => ({
      ...prevMappings,
      [column]: selectedTemplateColumn,
    }));
  };

  const deleteCSVFile = (index: number): void => {
    const updatedArray = allCSVs.filter((_, i) => i !== index);
    setAllCSVs(updatedArray);
  };

  const deleteRefactorCSVFile = (index: number): void => {
    const updatedArray = allRefactorCSVs.filter((_, i) => i !== index);
    setAllRefactorCSVs(updatedArray);
  };

  const handlePropertyCheck = (columnName: string, columnIndex: number): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (columnName === undefined) return false;
    if (columnName === '') {
      columnName = `Unnamed ${columnIndex + 1}`;
    }
    if (
      (columnName.trim().toLowerCase() === 'property'
        && !columnMappings[columnName])
      || columnMappings[columnName] === 'property'
    ) {
      return true;
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleCellChange = async (rowIndex: number, columnIndex: number, newValue: string): Promise<void> => {
    if (csvData === null || currentCSV === null) return;

    // update csv td
    rowIndex = rowIndex + skipRows + 1;
    const updatedData = [...csvData];
    updatedData[rowIndex][columnIndex + 1] = newValue;
    setCSVData(updatedData);

    const currentFileName = (currentCSV.file as File).name || 'updatedFile.csv';
    const csvBlob = new Blob([Papa.unparse(updatedData)], { type: 'text/csv' });
    const csvFile = new File([csvBlob], currentFileName, { type: 'text/csv' });

    setAllCSVs(
      allCSVs.map((item, index): AllCSVs => {
        if (index === currentCSVIndex) {
          setCurrentCSV({ ...currentCSV, file: csvFile });
          return { ...currentCSV, file: csvFile };
        }
        return item;
      }),
    );
    processCSVData();
  };

  // USE EFFECTS
  const scrollIntoView = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

useEffect(() => {
  processCSVData();
}, [columnMappings, skipRows]);

useEffect(() => {
  const handleUpdateColumnMappings = (propertyIndex: number): void => {
    const key = `Unnamed ${propertyIndex}`;
    const newColumnMappings = { ...columnMappings, [key]: 'property' };
    setColumnMappings(newColumnMappings);
    setCheckPropertyOnRender(false);
  };
     let propertyIndex = -1;
    if (propertyIndex === -1 && csvData !== null && checkPropertyOnRender && skipRows < csvData.length) {
      // eslint-disable-next-line prefer-destructuring
      propertyIndex = csvData.slice(skipRows)[0].findIndex((item) => typeof item === 'string' && item.trim().toLowerCase() === 'property');
        if (propertyIndex !== -1) {
          handleUpdateColumnMappings(propertyIndex);
        }
      }
}, [skipRows, csvData]);

  useEffect(() => {
    scrollIntoView();
  }, [allCSVs, allRefactorCSVs]);

  useEffect(() => {
    if (currentCSV !== null) {
      setAllCSVs(
        allCSVs.map((item, index): AllCSVs => {
          if (index === currentCSVIndex) {
            item.user_columns = columnMappings;
            setCurrentCSV(item);
          }
          return item;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnMappings]);
  useEffect(() => {
    skipRows !== -1
      ? handleCSVFile(allCSVs[currentCSVIndex], currentCSVIndex)
      : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipRows]);
  useEffect(() => {
    let keyExists = false;
    let propertyIndex = -1;
    if (csvData != null) {
      propertyIndex = csvData
        .slice(skipRows)[0]
        ?.slice(1)
        ?.map((string) => string.trim().toLowerCase())
        .indexOf('property') ?? -1;
    }
    if (propertyIndex !== -1) {
      keyExists = true;
      for (const key in columnMappings) {
        if (
          key.trim().toLowerCase() === 'property'
          && columnMappings[key] !== 'property'
        ) {
          keyExists = false;
          break;
        }
      }
    } else {
      for (const key in columnMappings) {
        if (columnMappings[key] === 'property') {
          keyExists = true;
          break;
        }
      }
    }
    setAllCSVs(
      allCSVs.map((item, index): AllCSVs => {
        if (index === currentCSVIndex) {
          if (keyExists) {
            item.isValidBuilding = true;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (building !== -1 && !keyExists) {
            item.isValidBuilding = true;
          } else {
            item.isValidBuilding = false;
          }
          setCurrentCSV(item);
        }
        return item;
      }),
    );
    setShowBuidings(!keyExists);
  }, [columnMappings, csvData, skipRows]);

  useEffect(() => {
    if (currentCSV !== null) {
      setAllCSVs(
        allCSVs.map((item, index): AllCSVs => {
          if (index === currentCSVIndex) {
            item.year = year;
            item.properties = building;
            item.ignore_columns = selectedColumnOptions;
            item.errorIndex = errorIndex;
            setCurrentCSV(item);
          }
          return item;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, building, errorIndex]);
  useEffect(() => {
    if (currentCSV !== null) {
      setAllCSVs(
        allCSVs.map((item, index): AllCSVs => {
          if (index === currentCSVIndex) {
            item.properties = building;
            item.isValidBuilding = building !== -1;
            setCurrentCSV(item);
          }
          return item;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);
  useEffect(() => {
    if (currentCSV !== null) {
      setAllCSVs(
        allCSVs.map((item, index): AllCSVs => {
          if (index === currentCSVIndex) {
            item.skip_rows = skipRows;
            setCurrentCSV(item);
          }
          return item;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipRows]);
  // END EFFECTS

  const { isLoading } = useQuery(
    'get-default-headings',
    async () => axios({
      url: `/api/budget-calendar/import/?workspace=${currentWorkspace.id}`,
      method: 'get',
    }),
    {
      onSuccess: (res) => {
        setDafaultHeadings(res.data.columns);
        setBuildingOptions(res.data.properties);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const objectArray: IColOptions[] = res.data.columns.map(
          (str: string): IColOptions => {
            const columnName = str
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (char) => char.toUpperCase());
            return { columnName, isChecked: true };
          },
        );
        const filteredArray: IColOptions[] = objectArray.filter(
          (obj: IColOptions) => obj.columnName !== 'Event Name'
            && obj.columnName !== 'Building Name',
        );
        setColumnOptions(filteredArray);
      },
    },
  );
  const { mutate: getExistingEvents } = useMutation(
    async (data: IEventsData) => axios({
      url: '/api/budget-calendar/import/get-year-status',
      method: 'get',
      params: {
        building_id: data.building,
        year: data.year,
      },
    }),
    {
      onSuccess: (res) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (res.data.detail.status) {
          setOpenExistingEvents(true);
        }
      },
      onError: (err: IResponse) => {
        enqueueSnackbar(err.data.detail.result.message, {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
          ),
        });
      },
    },
  );
  const { mutate: removeExistingEvents } = useMutation(
    async (data: IEventsData) => axios({
      url: '/api/budget-calendar/import/delete-events',
      method: 'delete',
      params: {
        building_id: data.building,
        year: data.year,
      },
    }),
    {
      onSuccess: () => {
        setOpenRemoveEvents(false);
      },
      onError: (err: IResponse) => {
        enqueueSnackbar(err.data.detail.result.message, {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
          ),
        });
      },
    },
  );

  const { mutate: refactorCSVFiles } = useMutation(
    async (data: FormData) => axios({
      url: '/api/budget-calendar/import/get_cleaned/',
      method: 'post',
      data,
    }),
    {
      onSuccess: (res) => {
        setAllRefactorCSVs([]);
        const links = res.data.detail.downloadable_links;
        const errors = res.data.detail.error_messages;

        const data: IRefactoredData[] = [];
        links.forEach((filePath: string) => {
          const pathParts = filePath.split('/');
          const fileName = pathParts[pathParts.length - 1];
          const obj = {
            name: fileName,
            link: `${process.env.REACT_APP_BASE_URL}/${filePath}`,
          };
          data.push(obj);
        });
        setRefactoredErrorData(errors);
        setRefactoredLinkData(data);
      },
      onError: (err: { data: { detail: { message: string } } }) => {
        setAllRefactorCSVs([]);
        enqueueSnackbar(err.data.detail.message || 'Error refactoring the data. Please check your file and try again.', {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });
      },
    },
  );

  const handleRefactorCSV = (): void => {
    const data = new FormData();
      allRefactorCSVs.forEach((file) => {
        data.append('file', file);
      });
      refactorCSVFiles(data);
  };

  const readExcelFile = async (file: File): Promise<XLSX.WorkBook> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event): void => {
      const data = event.target?.result;
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (data) {
        const workbook = XLSX.read(data, { type: 'binary' });
        resolve(workbook);
      } else {
        reject(new Error('Failed to read file.'));
      }
    };
    reader.onerror = (error): void => {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });

  const { getRootProps: getRootPropsRefactor, getInputProps: getInputPropsRefactor } = useDropzone({
    accept: {
      'text/text': ['.csv', '.xlsx', '.xls'],
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop: async (accepted: File[]) => {
      setRefactoredLinkData([]);
      setRefactoredErrorData([]);
      const allData: File[] = [];
      let missingFiles = 0;

      const parseCSVFile = async (file: File): Promise<{ parsedFile: File | null }> => new Promise((resolve) => {
        Papa.parse(file, {
          skipEmptyLines: 'greedy',
          complete: (result) => {
            const parsedData = result.data as string[][];
            if (parsedData.length >= 2) {
              resolve({ parsedFile: file });
            } else {
              resolve({ parsedFile: null });
            }
          },
        });
    });

      for (const file of accepted) {
        if (file.name.endsWith('.csv')) {
            // eslint-disable-next-line no-inner-declarations
          const processData = async (file: File): Promise<void> => {
            const { parsedFile } = await parseCSVFile(file);
            if (parsedFile) {
              allData.push(parsedFile);
            } else {
              missingFiles += 1;
            }
          };
           await processData(file);
        } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          const workbook = await readExcelFile(file);
          const sheetNames = workbook.SheetNames;
          for (const sheetName of sheetNames) {
            const sheet = workbook.Sheets[sheetName];
            const sheetToCsv = XLSX.utils.sheet_to_csv(sheet);
            const blob = new Blob([sheetToCsv], { type: 'text/csv' });
            const csvFile = new File([blob], `${sheetName}.csv`, {
              type: 'text/csv',
              lastModified: Date.now(),
            });
            // eslint-disable-next-line no-inner-declarations
            const processData = async (file: File): Promise<void> => {
              const { parsedFile } = await parseCSVFile(file);
              if (parsedFile) {
                allData.push(parsedFile);
              } else {
                missingFiles += 1;
              }
            };
            // eslint-disable-next-line no-await-in-loop
            await processData(csvFile);
          }
        }
        if (missingFiles !== 0) {
          enqueueSnackbar(
               missingFiles === 1 ? '1 file has 0 or fewer rows' : `${missingFiles} files have 0 or fewer rows`,
           {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
            ),
          },
        );
      }
      setAllRefactorCSVs([...allRefactorCSVs, ...allData]);
      }
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/text': ['.csv', '.xlsx', '.xls'],
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop: async (accepted: File[]) => {
      const allData: AllCSVs[] = [];

      const parseCSVFile = async (
        file: File,
      ): Promise<{ propertyCheck: boolean; parsedFile: File | null }> => new Promise((resolve) => {
        Papa.parse(file, {
          skipEmptyLines: 'greedy',
          complete: (result) => {
            const parsedData = result.data as string[][];
            if (parsedData.length === 0) { resolve({ propertyCheck: false, parsedFile: null }); }
             if (parsedData.length >= 2) {
              const mappedPropertiesCheck = parsedData[0]
                ?.map((string) => string.trim().toLowerCase())
                .includes('mapped_properties');
              const propertyIndex = parsedData[0]
                ?.map((string) => string.trim().toLowerCase())
                .indexOf('property');
              if (!mappedPropertiesCheck) {
                const rows = parsedData.slice(1).map((row) => {
                  let mappedInitVal = 'mapped_properties';
                  if (propertyIndex !== -1) {
                    mappedInitVal = `mapped_properties---${row[propertyIndex]}`;
                  }
                  return [mappedInitVal, ...row];
                });
                const updatedData = [
                  ['mapped_properties', ...parsedData[0]],
                  ...rows,
                ];
                const updatedCsv = Papa.unparse(updatedData);
                const updatedBlob = new Blob([updatedCsv], {
                  type: 'text/csv',
                });
                const updatedFile = new File([updatedBlob], file.name, {
                  type: 'text/csv',
                  lastModified: Date.now(),
                });
                resolve({
                  propertyCheck: propertyIndex !== -1,
                  parsedFile: updatedFile,
                });
              } else {
                resolve({
                  propertyCheck: propertyIndex !== -1,
                  parsedFile: file,
                });
              }
            } else {
              resolve({ propertyCheck: false, parsedFile: null });
            }
          },
        });
      });

      let missingFiles = 0;
      for (const file of accepted) {
        if (file.name.endsWith('.csv')) {
          // eslint-disable-next-line no-inner-declarations
          const processData = async (file: File): Promise<void> => {
            const { propertyCheck, parsedFile } = await parseCSVFile(file);
            if (parsedFile) {
              const obj = {
                file: parsedFile,
                user_columns: {},
                year: new Date().getFullYear().toString(),
                properties: -1,
                errorIndex: -1,
                ignore_columns: columnOptions.map((option) => ({ ...option })),
                skip_rows: 0,
                isValidBuilding: propertyCheck,
                isPropertyUpdated: false,
              };
              allData.push(obj);
              // setOrignalCSVs(allData);
            } else {
              missingFiles += 1;
            }
          };
          await processData(file);
        } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          const workbook = await readExcelFile(file);
          const sheetNames = workbook.SheetNames;
          for (const sheetName of sheetNames) {
            const sheet = workbook.Sheets[sheetName];
            const sheetToCsv = XLSX.utils.sheet_to_csv(sheet);
            const blob = new Blob([sheetToCsv], { type: 'text/csv' });
            const csvFile = new File([blob], `${sheetName}.csv`, {
              type: 'text/csv',
              lastModified: Date.now(),
            });
            // eslint-disable-next-line no-inner-declarations
            const processData = async (file: File): Promise<void> => {
              const { propertyCheck, parsedFile } = await parseCSVFile(file);
              if (parsedFile !== null) {
                const obj = {
                  file: parsedFile,
                  user_columns: {},
                  year: new Date().getFullYear().toString(),
                  properties: -1,
                  errorIndex: -1,
                  ignore_columns: columnOptions.map((option) => ({
                    ...option,
                  })),
                  skip_rows: 0,
                  isValidBuilding: propertyCheck,
                  isPropertyUpdated: false,
                };
                allData.push(obj);
              } else {
                missingFiles += 1;
              }
            };
            // eslint-disable-next-line no-await-in-loop
            await processData(csvFile);
          }
        }
      }
      if (missingFiles !== 0) {
          enqueueSnackbar(
               missingFiles === 1 ? '1 file has 0 or fewer rows' : `${missingFiles} files have 0 or fewer rows`,
           {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
            ),
          },
        );
      }
      setAllCSVs([...allCSVs, ...allData]);
    },
  });

  const { mutate: getCeleryProgress } = useMutation(
    async (taskID: string) => axios({
      url: `/api/budget-calendar/import/get_task_progress/?task_id=${taskID}`,
      method: 'get',
    }),
    {
      onSuccess: (res, taskID) => {
        setProgressValue(Number(res.data.detail.progress.percent));
        if (
          res.data.detail.progress.percent !== 100
          || res.data.detail.result === undefined
        ) {
          setTimeout((): void => {
            getCeleryProgress(taskID);
          }, 1000);
        } else if (res.data.detail.result.message !== undefined) {
          enqueueSnackbar(res.data.detail.result.message);
          navigate('/workspace/event-tracker');
        } else {
          enqueueSnackbar('CSV files are Imported');
          navigate('/workspace/event-tracker');
        }
      },
      onError: (err: IResponse) => {
        enqueueSnackbar(err.data.detail.result.message, {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
          ),
        });
        setOpenImportDialog(false);
      },
    },
  );

  const { mutate: importAllCSVs } = useMutation(
    async (data: FormData) => axios({
      url: `/api/budget-calendar/import/?workspace=${currentWorkspace.id}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }),
    {
      onSuccess: (res: IResponse) => {
        setOpenImportDialog(true);
        getCeleryProgress(res.data.detail.task_id);
      },
      onError: (err: IResponse) => {
        setOpenImportDialog(false);
        enqueueSnackbar(err.data.detail.message, {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
          ),
        });
      },
    },
  );

  const handlePayload = async (): Promise<void> => {
    const formData = new FormData();
    async function processAllCSVs(allCSVs: AllCSVs[]): Promise<void> {
      for (const item of allCSVs) {
        if (item.file !== null && !(item.file instanceof FormData)) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const result = await parseCsvFile(item.file);
            const updatedData: string[][] = result.data as string[][];
            const numbersWithUnnamed: number[] = Object.keys(item.user_columns).filter((val) => !defaultOption.includes(val ? val.trim().toLowerCase() : '')).map((key) => Number(key.split(' ')[1]));
            numbersWithUnnamed.forEach((columnIndex) => {
              updatedData[item.skip_rows][columnIndex] = `Unnamed ${columnIndex}`;
            });
            const currentFileName = item.file.name || 'updatedFile.csv';
           const csvBlob = new Blob([Papa.unparse(updatedData)], { type: 'text/csv' });
           const csvFile = new File([csvBlob], currentFileName, { type: 'text/csv' });
            formData.append('files', csvFile);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error parsing CSV file:', error);
          }
        }
        formData.append('user_columns', JSON.stringify(item.user_columns));
        formData.append('year', item.year);
        formData.append('properties', JSON.stringify(item.properties));
        formData.append('ignore_columns', JSON.stringify(item.ignore_columns));
        formData.append('skip_rows', JSON.stringify(item.skip_rows));
      }
    }

    await processAllCSVs(allCSVs).then(() => {
      importAllCSVs(formData);
    });
  };

  const getTemplate = (): void => {
    if (dafaultHeadings.length > 0) {
      const dataArray = [dafaultHeadings];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const csvContent = `data:text/csv;charset=utf-8,${dataArray
        .map((row): string => row.join(','))
        .join('\n')}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'template.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const currentYear = new Date().getFullYear();
  const yearsOptions = [
    currentYear - 2,
    currentYear - 1,
    currentYear,
    currentYear + 1,
    currentYear + 2,
  ].map((optionYear) => optionYear.toString());

const getCurrentOption = (option: string): string => {
  if (!currentCSV) {
    return option;
  }
  const foundKey = Object.keys(currentCSV.user_columns).find(
    (key) => key.toLowerCase() === option.toLowerCase(),
  );

  return foundKey ? currentCSV.user_columns[foundKey] : option;
};

  const handleCheckboxChange = (index: number): void => {
    setSelectedColumnOptions((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].isChecked = !newArray[index].isChecked;
      return newArray;
    });
  };

  const renderSkipperRows = (): JSX.Element => {
    let rowIndexArray: number[] = [];

    if (csvData !== null) {
      // eslint-disable-next-line @typescript-eslint/init-declarations
      let columnIndex: number;
      if (errorIndex === -1) {
        columnIndex = 0;
      } else {
        columnIndex = errorIndex;
      }

      if (columnIndex !== -1) {
        rowIndexArray = csvData
          .slice(skipRows + 1)
          .reduce((indices: number[], row: string[], index: number) => {
            if (!row[columnIndex + 1] || row[columnIndex + 1].trim() === '') {
              // If the cell is empty, add the row number (considering header row)
              indices.push(index + 1);
            }
            return indices;
          }, []);
      }
    }
    let keyExists = false;
    for (const key in columnMappings) {
        if (columnMappings[key] === 'event_name') {
          keyExists = true;
      }
    }

    return (
      <div>
        {isEventName === 'event_name' && keyExists && rowIndexArray.length > 0 && (
          <div className='error-rows-wrap'>
            <img
              src={`${process.env.REACT_APP_ASSESTS_URL}cois-error.webp`}
              alt='error'
            />
            <p>
              {`${rowIndexArray.join(',')} ${rowIndexArray.length > 1 ? 'rows' : 'row'
              }  don’t have enough data to populate. They will be skipped.`}
            </p>
          </div>
        )}
      </div>
    );
  };

  const shouldDisableButton = (): boolean => {
    const isDisabled = allCSVs.every((obj: AllCSVs) => obj.isValidBuilding);
    if (!isDisabled) {
      return true;
    }
    return false;
  };

  const showLimitError = (): void => {
    enqueueSnackbar('Cannot upload more than 10 files.', {
      variant: 'error',
      content: (key, message) => (
        <div className='text-white bg-danger ps-4 pe-5 py-3'>{message}</div>
      ),
    });
  };

  return (
    <div className='import-csv-wrapper'>
      <StradaLoader open={tableLoader} message='Loading file(s)' />
      <div className='import-csv-header'>
        <p>Import your events from CSV</p>
        <div
          aria-hidden='true'
          onClick={(): void => {
            navigate('/workspace/event-tracker');
          }}
          className='cursor-pointer'
        >
          <CloseIcon />
        </div>
      </div>
      {isLoading ? (
        <div
          className='d-flex justify-center align-items-center w-100'
          style={{ height: '90vh' }}
        >
          <CustomLoader />
        </div>
      ) : (
        <div>
          {currentCSV === null ? (
            <div className='import-csv-body'>
              <div className='csv-recommended'>
                <p className='recommended-desc'>
                  Strada makes it easy to manage your annual events and automate
                  the process. Automaticaly add email reminders, assign tasks
                  and more from your uploads without having to manually edit
                  each item.
                </p>
                <div className='collapse-btn d-flex  align-items-center pt-4'>
                  <span
                    className={`${!isCollapse ? 'icon-btn-inverted' : 'icon-btn'
                    }`}
                    onClick={(): void => {
                      setIsCollapse(!isCollapse);
                    }}
                  >
                    <CollapseIcon />
                    {' '}
                  </span>
                  {' '}
                  <p className='recommended-heading'>
                    Uploading from your budget
                  </p>
                </div>
                {isCollapse ? (
                  <>
                    <div className='csv-content'>
                      <div className='icon-wrapper  d-flex  align-items-center pt-4 pb-2'>
                        <TickIcon />
                        {' '}
                        <p className='recommended-heading'>
                          {' '}
                          When recommended
                          {' '}
                        </p>
                      </div>
                      <p className='recommended-desc'>
                        We recommending uploading from your budget when budgets
                        have been broken out to individual items.
                      </p>
                      <div className='img-wrapper mb-6 d-flex justify-content-center text-center'>
                        <img
                          src={`${process.env.REACT_APP_ASSESTS_URL}recommended.svg`}
                          alt='recommended'
                          onClick={(): void => {
                            setPreviewURL('recommended.svg');
                          }}
                        />
                      </div>
                    </div>

                    <div className='csv-content'>
                      <div className='icon-wrapper  d-flex  align-items-center  pb-2 pt-4 '>
                        <HammerIcon />
                        <p className='recommended-heading'>
                          Refactor your budget
                        </p>
                      </div>
                      <p className='recommended-desc'>
                        If your budget has ledger accounts that have not been broken down to individual events by month, we recommend to use the following tool to help you with your upload
                      </p>
                      <br />
                      <div className='fw-bold'>Step 1:</div>
                      <p className='recommended-desc'>
                        In your current budget file, add a column labeled &#34;Include&#34;. Add a Y to indicate the information of that row to be uploaded to Strada. Any row data without a Y next to it will not be uploaded.
                      </p>
                      <div className='img-wrapper d-flex justify-content-center  text-center mb-1'>
                        <img
                          src={`${process.env.REACT_APP_ASSESTS_URL}refactor.png`}
                          alt='refactor'
                          onClick={(): void => {
                            setPreviewURL('refactor.png');
                          }}
                        />
                      </div>
                      <div className='fw-bold'>Step 2:</div>
                      <p className='recommended-desc'>
                        Upload your budget, with the added &#34;Include&#34; column, here:
                      </p>
                      <div {...getRootPropsRefactor({ className: 'my-4 csv-drop-zone' })}>
                        <input {...getInputPropsRefactor()} />
                        <UploadIcon />
                        <p className='heading'>Select files to refactor </p>
                        <p className='sub-heading'>or drag and drop these here </p>
                      </div>

                      {allRefactorCSVs.length > 0 ? allRefactorCSVs.map(
                        (file, index): JSX.Element => (
                          <div className='single-csv-wrap' key={`${file.name}-${Math.random()}`}>
                            <div className='single-csv-div'>
                              <p>
                                {!(file instanceof FormData) ? file.name : ''}
                              </p>
                            </div>
                            <div
                              className='remove-csv'
                              aria-hidden='true'
                              onClick={(): void => {
                                deleteRefactorCSVFile(index);
                              }}
                            >
                              Remove
                            </div>
                          </div>
                        ),
                      ) : ''}
                      <div ref={scrollRef}>
                        {allRefactorCSVs.length > 0 && (
                        <PrimayButton
                          style={{ width: '130px', margin: '15px 0px' }}
                          onClick={(): void => {
                      // allRefactorCSVs.length > 10 ? showLimitError() : handleRefactorCSV();
                      handleRefactorCSV();
                    }}
                        >
                          Refactor
                        </PrimayButton>
                )}
                      </div>

                      <div className='fw-bold'>Step 3:</div>
                      <p className='recommended-desc'>
                        Download and open your cleaned spreadsheet. This spreadsheet will only include the items you wanted, broken down individually. Change the event names to specific items of your choice and add any additional information that you&#39;d like.
                      </p>
                      <br />
                      {refactoredLinkData.length > 0 || refactoredErrorData.length > 0 ? (
                        <>
                          {refactoredLinkData.length > 0 ? refactoredLinkData.map(
                        (data, index): JSX.Element => (
                          <div className='single-csv-wrap' key={`${data.link}-${Math.random()}`}>
                            <div className='single-csv-div'>
                              <p>
                                {data.name}
                              </p>
                            </div>
                            <a
                              className='download-csv'
                              href={data.link}
                            >
                              Download
                            </a>
                          </div>
                        ),
                      ) : ''}
                          {refactoredErrorData.length > 0 ? refactoredErrorData.map(
                            (data, index): JSX.Element => (
                              <div className='single-csv-wrap' key={`${data.message}-${Math.random()}`}>
                                <div className='single-csv-div'>
                                  <p>
                                    {data.filename}
                                  </p>
                                </div>
                                <Tooltip title={(
                                  <>
                                    {data.message}
                                    <br />
                                  </>
                                )}
                                >
                                  <InfoIcon className='back-icon ' />
                                </Tooltip>
                              </div>
                            ),
                      ) : ''}
                        </>
                      ) : (
                        <p>
                          No Refactored data yet
                        </p>
                       )}

                      <br />
                    </div>
                  </>
                ) : (
                  <br />
                )}
              </div>
              {previewURL && (
                <PreviewModal
                  previewURL={previewURL}
                  setPreviewURL={setPreviewURL}
                />
              )}
              <Box sx={{ width: '614px' }}>
                <p className='intro-text'>
                  Download
                  {' '}
                  <span
                    aria-hidden='true'
                    onClick={(): void => {
                      getTemplate();
                    }}
                  >
                    template
                  </span>
                  {' '}
                  to import events to the event tracker
                </p>
                <div {...getRootProps({ className: 'my-4 csv-drop-zone' })}>
                  <input {...getInputProps()} />
                  <UploadIcon />
                  <p className='heading'>Select CSV files to import </p>
                  <p className='sub-heading'>or drag and drop these here </p>
                </div>
              </Box>
              {allCSVs.map(
                (singleFile, index): JSX.Element => (
                  <div className='single-csv-wrap' key={`single-${Math.random()}`}>
                    <div className='single-csv-div'>
                      <p>
                        {singleFile.file !== null
                          && !(singleFile.file instanceof FormData)
                          ? singleFile.file.name
                          : ''}
                      </p>
                    </div>
                    <div
                      className='remove-csv'
                      aria-hidden='true'
                      onClick={(): void => {
                        deleteCSVFile(index);
                      }}
                    >
                      Remove
                    </div>
                  </div>
                ),
              )}
              <div ref={scrollRef}>
                {allCSVs.length > 0 && (
                  <PrimayButton
                    style={{ width: '130px', margin: '15px 0px' }}
                    onClick={(): void => {
                      allCSVs.length > 10 ? showLimitError() : handleCSVFile(allCSVs[0], 0);
                    }}
                  >
                    {' '}
                    Upload
                    {' '}
                  </PrimayButton>
                )}
              </div>
            </div>
          ) : (
            <div>
              {csvData !== null && csvData.length > 0 && (
                <div>
                  <div className='files-heading-wrap'>
                    <div className='file-name-div'>
                      <p>
                        {currentCSV.file !== null
                          && !(currentCSV.file instanceof FormData)
                          ? currentCSV.file.name
                          : ''}
                      </p>
                    </div>
                    <div className='file-count-div'>
                      <p>
                        {`${currentCSVIndex + 1} of ${allCSVs.length
                        } files`}

                      </p>
                      <div
                        className='me-3'
                        style={{
                          cursor: currentCSVIndex !== 0 ? 'pointer' : 'auto',
                          color: currentCSVIndex !== 0 ? 'black' : '#B1B1B1',
                        }}
                        aria-hidden='true'
                        onClick={(): void => {
                          currentCSVIndex !== 0
                            ? handleCSVFile(
                              allCSVs[currentCSVIndex - 1],
                              currentCSVIndex - 1,
                            )
                            : null;
                        }}
                      >
                        <ArrowBackIcon />
                      </div>
                      <div
                        style={{
                          cursor:
                            currentCSVIndex !== allCSVs.length - 1
                              ? 'pointer'
                              : 'auto',
                          color:
                            currentCSVIndex !== allCSVs.length - 1
                              ? 'black'
                              : '#B1B1B1',
                        }}
                        aria-hidden='true'
                        onClick={(): void => {
                          currentCSVIndex !== allCSVs.length - 1
                            ? handleCSVFile(
                              allCSVs[currentCSVIndex + 1],
                              currentCSVIndex + 1,
                            )
                            : null;
                        }}
                      >
                        <ArrowForwardIcon />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='single-csv-heading text-start'>
                      Here&apos;s your data that will be imported. We&apos;ll
                      create an event for each of the row showing below.
                    </p>
                    <div className='d-flex'>
                      <div className='property-select'>
                        <OutlinedInput
                          type='number'
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            handleSkipedRows(event);
                          }}
                          value={skipRows}
                          inputProps={{ min: 0 }}
                          style={{ height: '40px', width: '80px' }}
                        />
                      </div>
                      <div
                        className='property-select'
                        style={{
                          opacity: showBuidings ? 1 : 0.7,
                          pointerEvents: showBuidings ? 'auto' : 'none',
                        }}
                      >
                        <Select
                          onChange={(event): void => {
                            setBuilding(Number(event.target.value));
                            getExistingEvents({
                              building: event.target.value,
                              year,
                            });
                          }}
                          value={building}
                          style={{ height: '40px' }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                width: '300px',
                              },
                            },
                          }}
                        >
                          {building === -1 && (
                            <MenuItem value={-1}>Select a Building</MenuItem>
                          )}
                          {buildingOptions.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className='date-select'>
                        <Select
                          onChange={(event): void => {
                            setYear(event.target.value);
                            showBuidings && building !== -1
                              ? getExistingEvents({
                                building,
                                year: event.target.value,
                              })
                              : '';
                          }}
                          value={year}
                          style={{ height: '40px', width: '120px' }}
                        >
                          {yearsOptions.map((option) => (
                            <MenuItem value={option} key={option}>{option}</MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className='adjust-import'>
                        <div
                          className='d-flex align-items-center cursor-pointer'
                          aria-hidden='true'
                          onClick={(e): void => {
                            handleClick(e);
                          }}
                        >
                          <SettingsIcon />
                          <div className='import-text'>Adjust your import</div>
                        </div>
                        <Popover
                          open={anchorEl !== null}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <div className='import-popover'>
                            {selectedColumnOptions.length > 0
                              && selectedColumnOptions.map((option, index) => option.columnName !== 'Property' && (
                                <div
                                  className='single-option'
                                  key={`${option.columnName}`}
                                >
                                  <Checkbox
                                    checked={option.isChecked}
                                    onChange={(): void => {
                                      handleCheckboxChange(index);
                                    }}
                                  />
                                  <div className='name-side'>
                                    <p>
                                      {' '}
                                      {option.columnName}
                                      {' '}
                                    </p>
                                  </div>
                                </div>
))}
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <div>{renderSkipperRows()}</div>
                  <div className='table-container'>
                    {
                      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                      tableLoader ? setTableLoader(false) : ''
                    }
                    <table className='csv-table' ref={headerRef}>
                      <thead>
                        <tr>
                          {options.slice(1).map((selectOptions, index) => (
                            <th key={`select-${Math.random()}`}>
                              <select
                                onChange={(event): void => {
                                  isPropertyUpdated && event.target.value.trim().toLowerCase() === 'property'
                                    ? ((): void => { setShowModal(true); setMappedColData({ option: selectOptions[0], value: event.target.value, index }); })()
                                    : handleColumnMapping(selectOptions[0], event.target.value, index);
                                }}
                                value={getCurrentOption(selectOptions[0])}
                              >
                                {Array.from(
                                  new Set(
                                    selectOptions.map((option) => option.trim().toLowerCase()),
                                  ),
                                ).map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </select>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {csvData.slice(1 + skipRows).map((row, rowIndex) => (
                          <tr key={rowIndex} className='table-row'>
                            {row.slice(1).map((value, columnIndex) => (
                              <td key={columnIndex}>
                                {handlePropertyCheck(
                                  //  csvData.slice(skipRows)[0].slice(1)[
                                  csvData.slice(0)[0].slice(1)[
                                    columnIndex
                                  ],
                                  columnIndex,
                                ) ? (

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {isPropertyMatched(row[0])
                                      || isPropertyMatched(row[columnIndex]) || existingProperties.includes(rowIndex + 1) ? (
                                        <div
                                          className='tooltip-icon'
                                          style={{ color: 'green' }}
                                          title='Property matched'
                                        >
                                          ✔️
                                        </div>
                                      ) : (
                                        <div
                                          className='tooltip-icon'
                                          style={{ color: 'orange' }}
                                          title='This property is not matched'
                                        >
                                          ⚠️
                                        </div>
                                      )}
                                    <select
                                      onChange={(event): void => {
                                          handlePropertyChange(
                                            event,
                                            rowIndex,
                                            columnIndex,
                                          );
                                        }}
                                      value={`${getFirstColumn(
                                          csvData.slice(skipRows)[
                                            rowIndex + 1
                                          ][0],
                                        )}`}
                                      style={{ marginLeft: '5px' }}
                                    >
                                      {getPropertyOptions(
                                          csvData.slice(skipRows)[rowIndex + 1][
                                            columnIndex + 1
                                          ],
                                        ).map((option) => (
                                          <option
                                            key={`${option.key}__${option.value}`}
                                            value={`${option.key}__${option.value}`}
                                          >
                                            {option.value}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  ) : (
                                    <td
                                      key={columnIndex}
                                      style={{ border: 'none' }}
                                    >
                                      <input
                                        className='csv-input'
                                        type='text'
                                        value={value}
                                        onChange={(e): void => {
                                          void handleCellChange(
                                            rowIndex,
                                            columnIndex,
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    </td>
                                  )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='csv-buttons-wrapper'>
                    <Tooltip
                      title={
                        shouldDisableButton()
                          ? 'Please choose either a Building or Property option from the corresponding column in each CSV file to enable Import, or move the row with the Property to the Header.'
                          : ''
                      }
                    >
                      <span>
                        <Button
                          style={{
                            color: shouldDisableButton() ? '212121' : 'white',
                            background: shouldDisableButton()
                              ? '#E4E4E4'
                              : '#00CFA1',
                            width: '130px',
                            margin: '0 50px',
                            textTransform: 'inherit',
                          }}
                          disabled={shouldDisableButton()}
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onClick={async (): Promise<void> => {
                           await handlePayload();
                          }}
                        >
                          {' '}
                          Import
                        </Button>
                      </span>
                    </Tooltip>
                    <p
                      className='back-btn'
                      aria-hidden='true'
                      onClick={(): void => {
                        setCurrentCSV(null);
                      }}
                    >
                      Back
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <Dialog open={openImportDialog} keepMounted>
        <DialogContent style={{ width: 500, padding: '24px' }}>
          <div className='dialog-heading'>
            Importing
            {' '}
            {allCSVs.length}
            {' '}
            CSV files
          </div>
          <div style={{ margin: '10px 0px' }}>
            <BorderLinearProgress variant='determinate' value={progressValue} />
          </div>
          <span
            className='dialog-body'
            style={{
              fontSize: '14px',
              color: 'rgba(33, 33, 33, 0.6)',
              fontWeight: '400',
              marginTop: '20px',
            }}
          >
            This import will continue in the background, by returning to Budget
            Calendar.
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            variant='contained'
            onClick={(): void => {
              navigate('/workspace/event-tracker');
            }}
            style={{
              textTransform: 'inherit',
              color: 'white',
              background: '#00CFA1',
            }}
            color='primary'
            autoFocus
          >
            Return to the Event Tracker
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openExistingEvents} keepMounted>
        <DialogContent style={{ width: 500, padding: '24px' }}>
          <div
            className='dialog-heading'
            style={{
              fontSize: '20px',
            }}
          >
            Keep the existing events in
            {' '}
            {year}
            {' '}
            ?
          </div>
          <span
            className='dialog-body'
            style={{
              fontSize: '16px',
              color: 'rgba(33, 33, 33, 0.6)',
              fontWeight: '400',
              marginTop: '25px',
            }}
          >
            {`${buildingOptions.find((obj) => obj.value === building)?.label
            } already has events in ${year} . Would you like to keep these?`}
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit', width: '210px' }}
            onClick={(): void => {
              setOpenRemoveEvents(true);
              setOpenExistingEvents(false);
            }}
            color='primary'
          >
            No, delete existing events and replace with new ones
          </Button>
          <Button
            variant='contained'
            onClick={(): void => {
              setOpenExistingEvents(false);
            }}
            style={{
              textTransform: 'inherit',
              color: 'white',
              background: '#00CFA1',
            }}
            color='primary'
            autoFocus
          >
            Yes, keep events and add new ones
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRemoveEvents} keepMounted>
        <DialogContent style={{ width: 500, padding: '24px' }}>
          <div
            className='dialog-heading'
            style={{
              fontSize: '20px',
            }}
          >
            Remove previous events for in
            {' '}
            {allCSVs.length}
            {' '}
            ?
          </div>
          <span
            className='dialog-body'
            style={{
              fontSize: '16px',
              color: 'rgba(33, 33, 33, 0.6)',
              fontWeight: '400',
              marginTop: '25px',
            }}
          >
            All previous events for in will be removed. This data will be
            unrecoverable.
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenRemoveEvents(false);
            }}
            color='primary'
          >
            No, cancel and go back
          </Button>
          <Button
            variant='contained'
            onClick={(): void => {
              removeExistingEvents({ building, year });
            }}
            style={{
              textTransform: 'inherit',
              color: 'white',
              background: '#00CFA1',
            }}
            color='primary'
            autoFocus
          >
            Yes, delete these old events
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={showModal}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          elevation: 0,
          style: {
            width: '600px',
            paddingBottom: '.5rem',
            paddingRight: '.7rem',
          },
        }}
      >
        <DialogTitle>
          <div>
            <p style={{ fontSize: '20px', color: 'rgba(33, 33, 33, 0.87)' }}>Are you sure you want to change this column to Property?</p>
            <p style={{ fontSize: '16px', color: 'rgba(33, 33, 33, 0.87)' }}>Any changes you made to the data in the current Property column will be reverted back to its original. </p>
          </div>
        </DialogTitle>
        <DialogActions>
          <Button
            className='text-transform-none'
            style={{ color: '#00CFA1', lineHeight: '1.7rem' }}
            onClick={(): void => { setShowModal(false); }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            className='ms-2 text-transform-none bg-danger'
            style={{ color: 'white', padding: '6px 8px' }}
            onClick={(): void => { setShowModal(false); mappedColData.index !== -1 ? handleColumnMapping(mappedColData.option, mappedColData.value, mappedColData.index) : ''; setMappedColData({ option: '', value: '', index: -1 }); setIsPropertyUpdated(false); }}
          >
            Continue
            {' '}
            {' '}

          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
