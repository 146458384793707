import React, {
  useRef, useEffect,
} from 'react';
import {
  Link, useNavigate, NavLink, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import SecondaryButton2 from 'shared-components/components/SecondaryButton2';

export default function NewDesktopNav(): JSX.Element {
  const navigate = useNavigate();
  const navbarToogle = useSelector((state: RootState) => state.workspaces.sideSheetData.navbarToogle);
  let lastScrollTop = 0;
  const componentRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const scrollHandle = (): void => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollTop < 10) {
      if (componentRef.current !== null) {
        componentRef.current.style.top = '0';
      }
    } else if (scrollTop > lastScrollTop) {
      if (componentRef.current !== null) {
        componentRef.current.style.top = '-72px';
      }
    } else if (componentRef.current !== null) {
      componentRef.current.style.top = '0';
      if (navbarToogle) {
        componentRef.current.style.background = 'white';
      } else {
        componentRef.current.style.background = '#D9F6F5';
      }
    }

    lastScrollTop = scrollTop;
  };

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/contact-us') {
      window.addEventListener('scroll', scrollHandle);
    }
    return () => {
      if (componentRef.current !== null) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        componentRef.current.style.top = '0';
        window.removeEventListener('scroll', scrollHandle);
      }
    };
  });

  return (

    <nav className='client-navbar-container' ref={componentRef} style={{ background: location.pathname === '/contact-us' || location.pathname === '/' ? '#D9F6F5' : 'white', borderBottom: location.pathname === '/contact-us' || location.pathname === '/' ? 'none' : '1px solid rgba(33, 33, 33, 0.12)' }}>
      <div className='container'>
        <div className='client-main-navbar'>

          {/* Left side ==> */}
          <div className='left-side-nav'>
            <Link to='/' className='logo-wrapper'>
              <img src={`${process.env.REACT_APP_ASSESTS_URL}Logo.webp`} alt='strada-logo' />
            </Link>
          </div>

          <div className='right-side-nav'>
            <div className='client-nav-links'>
              <NavLink to='/signin' className={({ isActive }): string => (isActive ? 'nav-text activeLinkClass' : 'nav-text')}>
                <div style={{ padding: '12px 20px' }}>Log in</div>
              </NavLink>
              <div className='demo-btn'>
                <SecondaryButton2 style={{ height: '48px', padding: '12px 20px' }} onClick={(): void => { navigate('/requestdemo'); }}> Request demo </SecondaryButton2>
              </div>
            </div>

          </div>
        </div>
      </div>
    </nav>

  );
}
