import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PrimayButton from 'shared-components/components/PrimayButton';
import StandardButton from 'shared-components/components/StandardButton';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { RootState } from 'mainStore';
import { SwitchIcon } from './integrationIcons';
import { connectGoogle, disconnectGoogle } from './store';

export default function GoogleAuth(): JSX.Element {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const integrations = useSelector((state: RootState) => state.integrations);

  useQuery(
    'get/gmail-registeration',
    async () => axios({
      url: '/api/register-gmail-mail/',
      method: 'GET',
    }),
    {
      onSuccess: (res) => {
        if (res.data !== undefined && res.data.length !== 0) {
          const payload = {
            ...res.data[0],
            isConnected: true,
          };
          dispatch(connectGoogle(payload));
          return;
        }
        dispatch(disconnectGoogle());
      },
    },
  );
  const { mutate: login, isLoading: registering } = useMutation(async () => axios({
    url: 'api/gmail/login',
    method: 'get',
  }), {
    // eslint-disable-next-line @typescript-eslint/require-await
    onSuccess: async (res) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.location.href = res.data.gmail_login_uri;
    },
    onError: (): void => {
      enqueueSnackbar('Failed to connect to Google', { variant: 'error' });
    },
  });

  const { mutate: deleteIntegration, isLoading: deleting } = useMutation(async (id: number) => axios({
    url: `/api/register-gmail-mail/${id}`,
    method: 'delete',
  }), {
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries('get/gmail-registeration');
      // enqueueSnackbar('Google config saved');
    },
    onError: (): void => {
      enqueueSnackbar('Google config saving failed', { variant: 'error' });
    },
  });

  return (
    <div>
      <StradaLoader open={deleting || registering} />
      {integrations.google.isConnected ? (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <StandardButton onClick={(): void => { deleteIntegration(integrations.google.id); }} startIcon={<SwitchIcon />}>
          Disconnect
        </StandardButton>
      ) : (
        <div className='btn-wrapper'>

          <PrimayButton onClick={(): void => { login(); }}>
            Connect
          </PrimayButton>

        </div>
      )}
    </div>
  );
}
