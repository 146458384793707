/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Button, Grid, Checkbox,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Editor } from '@tinymce/tinymce-react';
import InputField from 'shared-components/inputs/InputField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import StradaLoader from 'shared-components/components/StradaLoader';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import type { DropResult } from 'react-beautiful-dnd';
import PublishIcon from '@mui/icons-material/Publish';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type {
  IContent, IDetail, IFormOptions,
} from '../types';

interface IRenderFormContentProps {
  templateData: IDetail | undefined;
  setTemplateData: (value: IDetail) => void;
  deletedItemsIDs: string[];
  setDeletedItemsIDs: (value: string[]) => void;
}

export default function RenderFormContent(props: IRenderFormContentProps): JSX.Element {
  const {
    templateData, setTemplateData, deletedItemsIDs, setDeletedItemsIDs,
  } = props;
  const [editorLoader, setEditorLoader] = useState<boolean>(true);
  const [selectedContentItem, setSelectedContentItem] = useState<IContent>();
  const [openDeleteContentBlock, setOpenDeleteContentBlock] = useState<boolean>(false);
  const [showDropdownInputIndex, setShowDropdownInputIndex] = useState<number>(-1);
  const [formItemkey, setFormItemkey] = useState<string>('');

  function handleChangeEditorContent(editor_content: string, editordata: IContent): void {
    if (templateData !== undefined) {
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === editordata.unique_position_key) {
          item.value = editor_content;
        }
      });
      setTemplateData(newData);
    }
  }
  // function handleEditorBlur(editordata: IContent): void {
  //   if (templateData !== undefined) {
  //     const newData = { ...templateData };
  //     newData.content.map((item: IContent) => {
  //       if (item.unique_position_key === editordata.unique_position_key && item.value !== undefined) {
  //         let cleanValue = item.value.replace(/<[^>]*>/g, '');
  //         cleanValue = cleanValue.replace(/&nbsp;/g, '');
  //         if (cleanValue.trim() === '') {
  //           item.value = '';
  //         }
  //       }
  //     });
  //     setTemplateData(newData);
  //   }
  // }
  function swapWithUpperContent(content_index: number): void {
    if (templateData !== undefined) {
      if (content_index !== 0) {
        const newData = { ...templateData };
        const temp = newData.content[content_index - 1];
        newData.content[content_index - 1] = newData.content[content_index];
        newData.content[content_index] = temp;
        setTemplateData(newData);
      }
    }
  }
  function swapWithLowerContent(content_index: number): void {
    if (templateData !== undefined) {
      if (content_index !== templateData.content.length - 1) {
        const newData = { ...templateData };
        const temp = newData.content[content_index + 1];
        newData.content[content_index + 1] = newData.content[content_index];
        newData.content[content_index] = temp;
        setTemplateData(newData);
      }
    }
  }
  function deleteTaskItem(itemData: IContent | undefined): void {
    if (templateData !== undefined) {
      if (itemData !== undefined) {
        const index = templateData.content.findIndex((item) => item.unique_position_key === itemData.unique_position_key);
        const newData = { ...templateData };
        newData.content.splice(index, 1);
        setTemplateData(newData);
        const newArray = [...deletedItemsIDs];
        newArray.push(itemData.unique_position_key);
        setDeletedItemsIDs(newArray);
        setOpenDeleteContentBlock(false);
      }
    }
  }
  function duplicateContentItem(seletedContent: IContent): void {
    if (templateData !== undefined) {
      const obj: IContent = JSON.parse(JSON.stringify(seletedContent));
      obj.unique_position_key = Date.now().toString();
      delete obj.id;
      if (obj.type === 'select') {
        obj.options?.map((item) => {
          item.unique_position_key = `${Date.now().toString()}${item.id}`;
          delete item.id;
        });
      }
      const newData = { ...templateData };
      const index = newData.content.findIndex((item) => item.unique_position_key === seletedContent.unique_position_key);
      newData.content.splice(index, 0, obj);
      setTemplateData(newData);
    }
  }

  function handleFormLabelChange(label: string, formItem: IContent): void {
    if (templateData !== undefined) {
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === formItem.unique_position_key) {
          item.label = label;
        }
      });
      setTemplateData(newData);
    }
  }
  function handleBlur(formItem: IContent): void {
    if (templateData !== undefined) {
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === formItem.unique_position_key && item.label !== undefined) {
          item.label = item.label.trim();
        }
      });
      setTemplateData(newData);
    }
  }
  function handleOption(label: string, formItem: IContent, singleOption: IFormOptions): void {
    if (templateData !== undefined) {
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === formItem.unique_position_key && item.options !== undefined) {
          item.options.map((option: IFormOptions) => {
            if (option.unique_position_key === singleOption.unique_position_key) {
              option.label = label;
            }
          });
        }
      });
      setTemplateData(newData);
    }
  }
  function addNewOption(dropdownItem: IContent, dropdownItemIndex: number): void {
    if (templateData !== undefined) {
      const obj = {
        unique_position_key: Date.now().toString(),
        label: '',
      };
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === dropdownItem.unique_position_key && item.options !== undefined) {
          item.options.splice(dropdownItemIndex + 1, 0, obj);
        }
      });
      setTemplateData(newData);
      setShowDropdownInputIndex(dropdownItemIndex + 1);
    }
  }
  function removeOption(dropdownItem: IContent, dropdownItemIndex: number): void {
    if (templateData !== undefined) {
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === dropdownItem.unique_position_key && item.options !== undefined && item.options.length > 1) {
          item.options.splice(dropdownItemIndex, 1);
        }
      });
      setTemplateData(newData);
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFilePicker = (callback: (arg0: ArrayBuffer | string | null, arg1: { alt: string }) => void) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/jpg, image/jpeg, image/png, image/webp');
    input.onchange = (): void => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const imageDataUrl = reader.result;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          callback(imageDataUrl, { alt: file.name });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  function reorder(list: IContent[], startIndex: number, endIndex: number | undefined): IContent[] {
    const result = Array.from(list);
    if (endIndex !== undefined) {
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
    }
    return result;
  }
  function onContentDragEnd(result: DropResult): void {
    if (templateData !== undefined) {
      if (result.destination !== undefined) {
        const newData = { ...templateData };
        newData.content = reorder(newData.content, result.source.index, result.destination?.index);
        setTemplateData(newData);
      }
    }
  }
  function handleIsRequiredCheckbox(contentItem: IContent): void {
    if (templateData !== undefined) {
      const newData = { ...templateData };
      newData.content.map((item: IContent) => {
        if (item.unique_position_key === contentItem.unique_position_key && item.is_required !== undefined) {
          item.is_required = !item.is_required;
        }
      });
      setTemplateData(newData);
    }
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className='task-form-wrapper'>
      {templateData !== undefined && templateData.content !== undefined && templateData.content.length > 0 ? (
        <DragDropContext onDragEnd={(result: DropResult): void => { onContentDragEnd(result); }}>
          <Droppable droppableId='11223344'>
            {(provided): JSX.Element => (
              <div ref={provided.innerRef}>
                {templateData.content.map((item: IContent, index) => (
                  <Draggable draggableId={String(item.unique_position_key)} key={String(item.unique_position_key)} index={index}>
                    {(provideed): JSX.Element => (
                      <div ref={provideed.innerRef} {...provideed.draggableProps} {...provideed.dragHandleProps}>
                        {item.type === 'defaultFields' ? (
                          <div className='task-form-item' key={item.unique_position_key}>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div className='editor-div'>
                                <InputField
                                  name='empty_data'
                                  label='Company Name'
                                  maxLength={150}
                                  onKeyDown={handleInputKeyDown}
                                  value=''
                                />

                                <InputField
                                  name='empty_data'
                                  label='Contact Name'
                                  onKeyDown={handleInputKeyDown}
                                  value=''
                                />

                                <Grid container spacing={3}>
                                  <Grid item sm={4}>
                                    <InputField
                                      name='empty_data'
                                      label='Phone'
                                      onKeyDown={handleInputKeyDown}
                                      value=''
                                    />
                                  </Grid>
                                  <Grid item sm={8}>
                                    <InputField
                                      name='empty_data'
                                      label='Email'
                                      onKeyDown={handleInputKeyDown}
                                      value=''
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item sm={4}>
                                    <InputField
                                      name='empty_data'
                                      label='Total Price'
                                      onKeyDown={handleInputKeyDown}
                                      value=''
                                    />
                                  </Grid>
                                  <Grid item sm={8} />
                                </Grid>
                              </div>
                              {/* <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div> */}
                            </div>
                          </div>
                        ) : item.type === 'text' ? (
                          <div className='task-form-item' key={item.unique_position_key}>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div className='editor-div'>
                                <div className='loader-div'><StradaLoader open={editorLoader} /></div>
                                <Editor
                                  apiKey='1y7zut3pxyomlx5vhlj7wuh2q7r7sd4w8x7oevrxn05o07fq'
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`index-${index}`}
                                  onLoadContent={(): void => { setEditorLoader(false); }}
                                  init={{
                                    height: 220,
                                    menubar: false,
                                    branding: false,
                                    toolbar_mode: 'scrolling',
                                    content_css: 'material-outline',
                                    plugins: [
                                      'advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help ',
                                    ],
                                    toolbar: 'undo redo | styleselect | fontsizeselect | backcolor | bold italic underline|  bullist numlist | outdent indent| image',
                                    file_picker_types: 'file image media',
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    file_picker_callback: handleFilePicker as any,
                                    content_style:
                                      'body { font-family: Arial, sans-serif; font-size: 14px; margin: 0; padding: 10px;line-height: 1.5; }',
                                  }}
                                  value={item.value}
                                  // onBlur={(): void => { handleEditorBlur(item); }}
                                  onEditorChange={(content: string): void => { handleChangeEditorContent(content, item); }}
                                />
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'textfield' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='form-disabled-input-wrap'>
                                  <InputField
                                    id='value'
                                    name='name'
                                    placeholder='Something will be typed here...'
                                    onKeyDown={handleInputKeyDown}
                                    type='text'
                                    value=''
                                  />
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'textarea' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='form-disabled-input-wrap'>
                                  <InputField
                                    id='value'
                                    name='name'
                                    placeholder='Something will be typed here...'
                                    onKeyDown={handleInputKeyDown}
                                    type='text'
                                    value=''
                                    multiline
                                    rows={2}
                                  />
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'day' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='file-upload-div'>
                                  <DateRangeIcon />
                                  <p>Date will be set here</p>
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'checkbox' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap d-flex' style={{ marginTop: '20px' }}>
                                  <div>
                                    <Checkbox
                                      disabled
                                    />
                                  </div>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'currency' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='form-disabled-input-wrap'>
                                  <InputField
                                    id='value'
                                    name='name'
                                    placeholder='Numbers will be typed here...'
                                    onKeyDown={handleInputKeyDown}
                                    type='text'
                                    value=''
                                  />
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'select' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='form-disabled-input-wrap'>
                                  <InputField
                                    id='value'
                                    name='name'
                                    placeholder='An option will be selected from this list:'
                                    onKeyDown={handleInputKeyDown}
                                    type='text'
                                    error={false}
                                    value=''
                                    endAdornment={<ArrowDropDownIcon />}
                                  />
                                </div>
                                <div className='form-options-div'>
                                  {item?.options?.map((option, indx) => (
                                    <div className='options-wrap'>
                                      <p className='option-index'>{indx + 1}</p>
                                      {showDropdownInputIndex === indx && (formItemkey === item.unique_position_key)
                                        ? (
                                          <ClickAwayListener onClickAway={(): void => { setShowDropdownInputIndex(-1); setFormItemkey(''); }}>
                                            <div className='option-input'>
                                              <InputField
                                                name='name'
                                                placeholder='Type here or press enter to add another option'
                                                type='text'
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleOption(e.target.value, item, option); }}
                                                value={option.label}
                                                inputProps={{ maxLength: 250 }}
                                                autoFocus
                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>): void => {
                                                  if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    addNewOption(item, indx);
                                                  }
                                                }}
                                              />
                                            </div>
                                          </ClickAwayListener>
                                        )
                                        : (
                                          <div
                                            className='option-value-div'
                                            style={{
                                              display: 'flex', justifyContent: 'space-between', alignItems: 'cemter', width: '100%', margin: '5px 0px',
                                            }}
                                            aria-hidden='true'
                                            onClick={(): void => { setShowDropdownInputIndex(indx); setFormItemkey(item.unique_position_key); }}
                                          >
                                            {option.label
                                              ? <div className='option-value'>{option.label}</div>
                                              : <div className='option-default-value'>Type here or press enter to add another option</div>}
                                            <div className='option-item-close' aria-hidden='true' onClick={(): void => { removeOption(item, indx); }}><CloseIcon /></div>
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </div>
                                <div className='form-is-required mt-2'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'file' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='file-upload-div'>
                                  <PublishIcon />
                                  <p>File will be uploaded here</p>
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : item.type === 'signature' ? (
                          <div className='task-form-item'>
                            <div className='editor-wrapper'>
                              <div className='swap-up-down-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithUpperContent(index); }}><KeyboardArrowUpIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { swapWithLowerContent(index); }}><KeyboardArrowDownIcon /></div>
                              </div>
                              <div style={{ width: '100%' }}>
                                <div className='form-input-wrap'>
                                  <InputField
                                    id='label'
                                    name='name'
                                    placeholder='Type Label here'
                                    type='text'
                                    inputProps={{ maxLength: 250 }}
                                    onKeyDown={handleInputKeyDown}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { handleFormLabelChange(e.target.value, item); }}
                                    onBlur={(): void => { handleBlur(item); }}
                                    value={item.label}
                                    required
                                  />
                                </div>
                                <div className='form-disabled-input-wrap'>
                                  <InputField
                                    id='value'
                                    name='name'
                                    placeholder='Signature will be draw here...'
                                    onKeyDown={handleInputKeyDown}
                                    type='text'
                                    value=''
                                    multiline
                                    rows={2}
                                  />
                                </div>
                                <div className='form-is-required'>
                                  <Checkbox
                                    checked={item.is_required}
                                    sx={{
                                      fill: 'rgba(33, 33, 33, 0.6);',
                                      '&.Mui-checked': {
                                        fill: '#00CFA1',
                                      },
                                    }}
                                    onChange={(): void => { handleIsRequiredCheckbox(item); }}
                                  />
                                  <span>Required</span>
                                </div>
                              </div>
                              <div className='delete-duplicate-wrapper'>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { setSelectedContentItem(item); setOpenDeleteContentBlock(true); }}><DeleteIcon /></div>
                                <div className='delete-div' aria-hidden='true' onClick={(): void => { duplicateContentItem(item); }}><FileCopyIcon /></div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className='checklist-task-no-content'>
          Click the buttons on the right to add content
        </div>
      )}
      <Dialog
        open={openDeleteContentBlock}
        onClose={(): void => {
          setOpenDeleteContentBlock(false);
        }}
      >
        <DialogContent style={{ width: 400, padding: '24px' }}>
          <div className='dialog-heading'>Delete this content block??</div>
          <span
            className='dialog-body'
            style={{
              fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)', fontWeight: '400', marginTop: '15px',
            }}
          >
            This content block will be deleted.
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenDeleteContentBlock(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button variant='contained' onClick={(): void => { deleteTaskItem(selectedContentItem); }} style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }} color='primary' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
