import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Dialog,
} from '@mui/material';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import './_hero.scss';
import SecondaryButton2 from 'shared-components/components/SecondaryButton2';

export default function RowAndColumnSpacing(): JSX.Element {
  const navigate = useNavigate();
  const [openPlayer, setOpenPlayer] = useState(false);
  const backgroundImageUrl = `${process.env.REACT_APP_ASSESTS_URL}bg_texture@2x.webp`;

  return (
    <div className='homepage-container'>
      <div className='homepage-new-header'>
        <div className='container'>
          <Box pt={5} style={{ position: 'relative' }}>
            <p className='intro-heading'>Here for the Property Managers</p>
            <p className='intro-desc pt-1'>
              Task management and automation for property management teams.
            </p>
            <div className='pt-3 home-button-wrap'>
              <SecondaryButton2
                style={{
                  fontSize: '16px',
                  padding: '16px 28px',
                }}
                onClick={(): void => { navigate('/requestdemo'); }}
              >
                {' '}
                Request demo
              </SecondaryButton2>
              <Button
                startIcon={<PlayCircleOutlineIcon />}
                className='video-play-button'
                onClick={(): void => { setOpenPlayer(true); }}
              >
                Play video
              </Button>
            </div>
            <img className='shape-1' src={`${process.env.REACT_APP_ASSESTS_URL}shape01@2x.webp`} alt='' />
            <img className='shape-2' src={`${process.env.REACT_APP_ASSESTS_URL}shape02@2x.webp`} alt='' />
            <img className='shape-6' src={`${process.env.REACT_APP_ASSESTS_URL}shape06@2x.webp`} alt='' />
            <img className='shape-3' src={`${process.env.REACT_APP_ASSESTS_URL}shape03@2x.webp`} alt='' />
          </Box>
        </div>
        <div className='hero-bg-img' style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
          <div className='hero-bg-wrapper'>
            <img className='shape-5' src={`${process.env.REACT_APP_ASSESTS_URL}shape05@2x.webp`} alt='' />
            <div className='hero-bg-data'>
              <div className='hero-ui-img'>
                <img src={`${process.env.REACT_APP_ASSESTS_URL}event_tracker_hero_ui@2x_1.svg`} alt='' />
              </div>
            </div>
            <img className='shape-4' src={`${process.env.REACT_APP_ASSESTS_URL}shape04@2x.webp`} alt='' />
          </div>
        </div>
      </div>
      <Dialog
        open={openPlayer}
        onClose={(): void => { setOpenPlayer(false); }}
        PaperProps={{
          elevation: 0,
          style: {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: 'fit-content',
            background: 'none',
            margin: '0px',

          },
        }}
      >
        <Player
          playsInline
          poster='/assets/poster.png'
          src={`${process.env.REACT_APP_ASSESTS_URL}StradaExplainer.mp4`}
          fluid
        />
      </Dialog>

    </div>
  );
}
