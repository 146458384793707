import React from 'react';
import EventScheduleContent from './EventScheduleContent';

export default function EventSchedule(): JSX.Element {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <EventScheduleContent />
    </div>
  );
}
