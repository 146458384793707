/* eslint-disable no-nested-ternary */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useQuery } from 'react-query';
import type { RootState } from 'mainStore';
import './_coisErrors.scss';
import type { Ipayload } from './types';

export default function ButtonAppBar(props: { type: string }): JSX.Element {
  const { type } = props;
  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);
  const navigate = useNavigate();

  const { data: deficiencyCount } = useQuery(
    ['get/cois-errors', currentWorkspace.id],
    async () => axios({
      url: `/api/coise-errors/?workspace=${currentWorkspace.id}`,
      method: 'get',
    }),
    {
      enabled: Boolean(currentWorkspace.id),
      select: (res: AxiosResponse<Ipayload>) => res.data.total_count,
    },
  );

  const { data: expiryCount } = useQuery(
    ['get/cois-expiry', currentWorkspace.id],
    async () => axios({
      url: `/api/coi/cois_expiry/?workspace=${currentWorkspace.id}`,
      method: 'get',
    }),
    {
      enabled: Boolean(currentWorkspace.id),
      select: (res: AxiosResponse<Ipayload>) => res.data.total_count,
    },
  );

  return (

    <div>
      {type === 'deficiency' ? (
        <div>
          { deficiencyCount !== undefined && deficiencyCount > 0 && (
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position='static' className='cois-error-bar' elevation={0}>
                <Toolbar>
                  <img src={`${process.env.REACT_APP_ASSESTS_URL}cois-error.webp`} alt='error' />
                  <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>

                    <div className='details'>
                      { `${deficiencyCount} deficient certification` }
                    </div>

                  </Typography>
                  <Typography
                    className='learn-more'
                    onClick={(): void => {
                      navigate('/workspace/cois/errors#deficiency');
                    }}
                  >

                    Learn More

                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          )}
        </div>
      ) : type === 'expiry' ? (
        <div>
          { expiryCount !== undefined && expiryCount > 0 && (
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position='static' className='cois-error-bar' elevation={0}>
                <Toolbar>
                  <img src={`${process.env.REACT_APP_ASSESTS_URL}cois-error.webp`} alt='error' />
                  <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>

                    <div className='details'>
                      { `${expiryCount} certification${expiryCount > 1 ? 's are' : ' is'} expiring soon` }
                    </div>

                  </Typography>
                  <Typography
                    className='learn-more'
                    onClick={(): void => {
                      navigate('/workspace/cois/errors#expiry');
                    }}
                  >

                    Learn More

                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          )}
        </div>
      ) : null}
    </div>
  );
}
