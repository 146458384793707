/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React, { useMemo, useState, useEffect } from 'react';
import {
  IconButton, Divider, Typography, Avatar,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import type { Cell } from 'react-table';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomLoader from 'shared-components/components/CustomLoader';
import CustomTable from 'shared-components/tables/CustomTable';
import PrimayButton from 'shared-components/components/PrimayButton';
import type { RootState } from 'mainStore';
import type { IDataObject } from 'formsTypes';
import { encrypt } from 'shared-components/hooks/useEncryption';
import EditIcon from '@mui/icons-material/Edit';
import StradaSearch from 'admin/buildingSection/budget-calendar/components/StradaSearch';
import type { ILeaseTemplatesData, IResponse } from './types';

export default function LeaseContent(): JSX.Element {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [leaseTemplatesData, setLeaseTemplatesData] = useState<ILeaseTemplatesData[]>([]);
  const [getNextData, setgetNextData] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [filteredDataLoading, setFilteredDataLoading] = useState<boolean>(false);

  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );

  const { mutate: deleteLeaseTemplate } = useMutation(
    async (id: number | string) => axios({
      url: `/api/lease-template/${id}`,
      method: 'DELETE',
    }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get/lease-template').then();
        setIsDeleteLoading(false);
        enqueueSnackbar('Deleted Successfully');
      },
      onError: () => {
        setIsDeleteLoading(false);
      },
    },
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'template_name',
        width: '50%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value, row } = cell;
          const { original } = row;
          const newVal: string = value;
          return (
            <div
              style={{ color: '#00CFA1' }}
              aria-hidden='true'
              onClick={(): void => {
                navigate(`/workspace/settings/lease-template/${encrypt(original.id)}`);
              }}
            >
              {newVal}
            </div>
          );
        },
      },
      {
        Header: 'Author',
        accessor: 'author',
        width: '30%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          return (
            <div className='d-flex align-items-center'>
              <Avatar
                sx={{ height: '28px', width: '28px' }}
                src={`${process.env.REACT_APP_IMAGE_URL}${value.avatar}`}
              />
              <span style={{ paddingLeft: '16px' }}>
                {value.name}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        width: '15%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          const newVal: string = value;
          return (
            <div>
              {moment(newVal).format('MMMM DD, YYYY')}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'account_new',
        width: '5%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          return (
            <div
              style={{
                textAlign: 'right',
                marginRight: '1rem',
              }}
            >
              <PopupState variant='popper' popupId='demo-popup-popper'>
                {(popupState): JSX.Element => (
                  <div>
                    <IconButton {...bindToggle(popupState)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }): JSX.Element => (
                        <ClickAwayListener
                          onClickAway={(): void => {
                            popupState.close();
                          }}
                        >
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper className='tasks-popover'>
                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  navigate(`/workspace/settings/lease-template/${encrypt(original.id)}`);
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <EditIcon className='edit-icon edit-delete-icon' />
                                <span className='edit-text'> Edit</span>
                              </div>

                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  if (original.id !== undefined) {
                                    setIsDeleteLoading(true);
                                    deleteLeaseTemplate(original.id);
                                  }
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <DeleteIcon className='edit-delete-icon' />
                                <span className='delete-text'>
                                  Delete
                                </span>
                              </div>
                            </Paper>
                          </Fade>
                        </ClickAwayListener>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </div>
          );
        },
      },
    ],
    [currentWorkspace],
  );

  const handleResetFilters = (): void => {
    setSearch('');
  };

  const {
    data: allLeaseTemplates = { next: null, previous: null, results: [] },
    isFetching: loadMore,
  } = useQuery(
    ['get/lease-template', search],
    async () => axios({
      url: `/api/lease-template/?workspace=${currentWorkspace.id}`,
      method: 'get',
      params: {
        search,
      },
    }),
    {
      enabled: currentWorkspace.id !== 0,
      select: (res: AxiosResponse<IResponse>) => res.data,
      onSuccess: (res) => {
        setCursor(res.next?.split('cursor=')[1]?.split('?')[0]);
        setLeaseTemplatesData(res.results);
        setIsLoading(false);
      },
    },
  );

  const { data: loadMoreData, isLoading: loadMoreCursor } = useQuery(
    ['get/loadmoretasks', cursor],
    async () => axios({
      url: `api/lease-template/?cursor=${cursor}`,
      method: 'GET',

    }),
    {
      enabled: getNextData && cursor !== null,
      select: (res: AxiosResponse<IResponse>) => res.data,

    },
  );

  const GetNextCursorData = (): void => {
    if (allLeaseTemplates.next !== null) {
      loadMoreData && loadMoreData.next !== null ? setCursor(loadMoreData.next.split('cursor=')[1]?.split('?')[0]) : '';
      setgetNextData(true);
      setFilteredDataLoading(true);
    } else {
      setgetNextData(false);
    }
  };

  useEffect(() => {
    if (getNextData && cursor !== null && loadMoreData !== undefined) {
      setLeaseTemplatesData([...leaseTemplatesData, ...loadMoreData.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreData]);

  return (
    <>
      {leaseTemplatesData.length === 0 && search === '' && !loadMore && !isLoading && !loadMoreCursor ? (
        <div className='empty-array-wrapper'>
          <p>There are no lease abstraction templates</p>
          <PrimayButton
            onClick={(): void => {
              navigate('/workspace/settings/lease-template/new');
            }}
          >
            Add lease abstraction template
          </PrimayButton>

        </div>
      ) : (

        <div className='lease-wrapper' style={{ marginTop: '78px' }}>
          <div className='header'>
            <Typography className='header-text'>Lease Abstraction</Typography>
            <div className='right-side'>
              <div className='search-wrapper'>
                <StradaSearch value={search} setSearch={setSearch} placeholder='Search' />
              </div>
              <div className='button-wrapper'>
                <PrimayButton
                  onClick={(): void => {
                    navigate('/workspace/settings/lease-template/new');
                  }}
                >
                  Add template
                </PrimayButton>
              </div>
            </div>
          </div>

          {leaseTemplatesData.length === 0 && search !== '' ? (
            <div className='empty-array-wrapper'>
              <p>No templates were found matching your search</p>
              <div className='create-new-button'>
                <PrimayButton
                  onClick={handleResetFilters}
                >
                  Reset Search
                </PrimayButton>
              </div>
            </div>

          ) : (
            <div className='vendor-table-wrapper'>
              <Divider sx={{ mt: 3, mb: 0.9 }} />
              {isLoading || isDeleteLoading || loadMore ? <div style={{ height: '70vh' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div>
                : (
                  <CustomTable
                    {...{
                      columns,
                      data: leaseTemplatesData,
                    }}

                  />
                )}
              <div className='mytasks-load-more'>
                {search === '' && leaseTemplatesData.length > 0 && !filteredDataLoading && loadMoreData?.next !== null && allLeaseTemplates.next !== null && <p aria-hidden='true' onClick={(): void => { GetNextCursorData(); }}> Load more </p>}
              </div>
            </div>

          )}
        </div>
      )}
    </>
  );
}
