import * as React from 'react';
import Sidebar from 'admin/sidebar';
import AddLeaseForm from './AddLeaseForm';

export default function AddOrEditRFP(): JSX.Element {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar variant='settings' activeLink='lease-templates' />
      <AddLeaseForm />
    </div>
  );
}
