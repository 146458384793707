import React from 'react';

export default function Header(): JSX.Element {
  return (
    <div className='bd-header'>
      <h2>
        Dashboard
      </h2>

    </div>
  );
}
