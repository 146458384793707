/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import {
  Box, Stack, Button, Grid, Tooltip, AppBar, Toolbar, Typography,
  Divider,
} from '@mui/material';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import SelectInput from 'shared-components/inputs/SelectInput';
import type { SelectChangeEvent } from '@mui/material/Select';
import './_dragDrop.scss';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { RootState } from 'mainStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import HookTextField from 'shared-components/hooks/HookTextField';
import type { IFormValues } from 'formsTypes';
import type { ISuccessResponse } from 'admin/AdminFormTypes';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import type { IAllListResponse } from '../../buildingSettings/leaseAbstraction/types';
import type {
  Data, DownloadFile, EditLeaseFiles, PDFGroup,
} from '../types';
import CombinedUpload from './CombinedUpload';

const defaultValues: { name: string; type: string } = {
  name: '',
  type: '',
};

const schema = yup.object().shape({});

const editSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
});

export default function DragDrop(): JSX.Element {
  const leaseData = useLocation().state as Data;
  const { type } = useParams();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [pdfFile, setpdfFile] = useState<File[]>([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<number>(0);
  const [leaseGroups, setLeaseGroups] = useState<PDFGroup[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [combinedLeaseLength, setCombinedLeaseLength] = useState(0);
  const [editLeaseFiles, setEditLeaseFiles] = useState<EditLeaseFiles>();

  const handleGroupChange = (updatedGroups: any[]): void => {
    setLeaseGroups(updatedGroups);
  };

  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);
  const MAX_FILES = 30;
  const MAX_SIZE_MB = 300 * 1024 * 1024;

  useEffect(() => {
    const calculateGroupSize = (groups: typeof leaseGroups): number => groups.reduce((totalSize, group) => {
      const groupSize = group.files.reduce((sizeAcc, file) => sizeAcc + file.size, 0);
      return totalSize + groupSize;
    }, 0);

    const calculateGroupLength = (groups: typeof leaseGroups): number => groups.reduce((totalLength, group) => totalLength + group.files.length, 0);

    const leaseGroupSize = calculateGroupSize(leaseGroups);
    const pdfFileSize = pdfFile.reduce((acc, file) => acc + file.size, 0);
    const totalSize = leaseGroupSize + pdfFileSize;

    const leaseGroupLength = calculateGroupLength(leaseGroups);
    setCombinedLeaseLength(leaseGroupLength);
    const totalLength = leaseGroupLength + pdfFile.length;

    const errors: string[] = [];
    if (totalLength > MAX_FILES) {
      errors.push(`You can only upload up to ${MAX_FILES} files.`);
    }
    if (totalSize > MAX_SIZE_MB) {
      errors.push('Total file size exceeds 300 MB.');
    }

    setErrorMessages(errors);
  }, [pdfFile, leaseGroups]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/pdf': ['.png', '.jpg', '.jpeg', '.pdf'],
    },
    multiple: true,
    onDrop: (accepted: File[]) => {
      setpdfFile([...pdfFile, ...accepted]);
    },
  });

  // const { refetch } =
  useQuery(
    ['download', pdfUrl],
    async () => axios({
      url: pdfUrl,
      method: 'get',
    }),
    {
      onSuccess: () => { setPdfUrl(''); },
      enabled: false,
    },
  );

  const handleFileDelete: (index: number) => void = (index) => {
    const newFiles = [...pdfFile];
    newFiles.splice(index, 1);
    setpdfFile(newFiles);
  };

  const { data: templateList = [] } = useQuery('get/lease-template', async () => axios({
    url: `/api/filter/lease-templates/?workspace=${currentWorkspace.id}`,
    method: 'GET',
  }), {
    select: (res: AxiosResponse<IAllListResponse>) => {
      let options = res.data.detail.results.map((item) => ({
        name: item.template_name,
        value: item.id,
      }));

      const defaultTemplate = { name: 'Default Template', value: 0 };

      if (options.length > 0) {
        options.unshift(defaultTemplate);
      } else {
        options = [defaultTemplate];
      }

      return options;
    },
  });

  const { mutate: uploadFiles, isLoading: uploading } = useMutation(async (data: FormData) => axios({
    url: type === 'edit' ? `/api/lease/${leaseData.id}/` : '/api/lease/',
    method: type === 'edit' ? 'put' : 'post',
    data,
  }), {
    onSuccess: (res: AxiosResponse<ISuccessResponse>) => {
      enqueueSnackbar(res.data.detail.message);
      setSelectedTemplate(0);
      navigate('/workspace/lease');
    },
    onError: () => {
      enqueueSnackbar('Upload Failed!', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  const {
    control, formState, watch, reset, setValue,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(type === 'edit' ? editSchema : schema),
  });
  const { errors } = formState;

  const checkDisabled = (): boolean => {
    let disabled = false;
    if (type === 'edit') {
      if ((pdfFile.length === 0) || !watch('name')) {
        disabled = true;
      }
    } else if ((pdfFile.length === 0 && combinedLeaseLength === 0) || errorMessages.length > 0) {
      disabled = true;
    }
    return disabled;
  };

  const onAddFiles: () => void = () => {
    const formData = new FormData();
    formData.append('workspace', currentWorkspace.id.toString());
    if (type === 'edit') {
      formData.append('tenant_name', watch('name'));
    }
    formData.append('template', selectedTemplate.toString());

    if (pdfFile.length > 0) {
      pdfFile.forEach((file) => {
        formData.append('pdf', file);
      });
    }

    if (leaseGroups.length > 0) {
      leaseGroups.forEach((fileSet, index) => {
        fileSet.files.forEach((file) => {
          formData.append(`combined_pdfs[${index}]`, file);
        });
      });
      formData.append('groups', leaseGroups.length.toString());
    }

    uploadFiles(formData);
  };

  React.useEffect(() => {
    if (type === 'edit' && leaseData !== undefined) {
      setValue('name', leaseData?.tenant_name, { shouldDirty: true });
      if (leaseData.template === null && leaseData.lease_type === 'default_lease') {
        setSelectedTemplate(0);
      } else {
        setSelectedTemplate(leaseData?.template);
      }

      setEditLeaseFiles({
        combined_file: {
          name: leaseData.pdf.split('/')?.reverse()[0],
          path: leaseData.pdf_url,
        },
        all_files: leaseData.combined_pdfs.map((file) => ({
          name: file.pdf.split('/')?.reverse()[0],
          path: file.pdf_url,
        })),
      });
    }
  }, [type, leaseData]);

  const formatFileSize = (fileSizeInBytes: number): string => {
    if (fileSizeInBytes < 1024 * 1024) {
      const sizeInKB = fileSizeInBytes / 1024;
      return `${sizeInKB.toFixed(2)} KB`;
    }

    const sizeInMB = fileSizeInBytes / (1024 * 1024);
    return `${sizeInMB.toFixed(2)} MB`;
  };

  const renderList = (
    fileList: (DownloadFile[] | File[]),
    showDownload: boolean,
    allowDelete = true,
  ): JSX.Element => (
    <div>
      {fileList.length > 0 && fileList.map((file, index) => {
        const isCustomFile = 'path' in file;

        return (
          <div className='input-wrap-lease mb-6' key={`${Math.random()} + ${file.name}`}>
            <div className='rows p-0'>
              <InsertDriveFileIcon fontSize='large' />
              <div className='attachment'>
                <div className='attachment-name'>
                  <span>
                    {file.name}
                    {!isCustomFile && (
                      <span style={{ fontSize: '0.85em' }}>
                        {' '}
                        (
                        {formatFileSize(file.size)}
                        )
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className='control-area cursor-pointer'>
                {showDownload && isCustomFile ? (
                  <span
                    aria-hidden='true'
                    onClick={(): void => {
                      // setPdfUrl(file.path);
                      // refetch().catch((e) => { throw e; });
                      window.open(`${process.env.REACT_APP_BASE_URL}${file.path}`, '_blank');
                    }}
                    className='download-csv'
                  >
                    Open
                  </span>
                )
                  : allowDelete && <DeleteIcon className='delete-icon' onClick={(): void => { handleFileDelete(index); }} />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <Stack className='lease-file-uplaod new-lease'>
      <div className='main-area'>
        <StradaLoader open={uploading} message='Uploading' />
        <section className='drap-section-cois mt-2'>
          <Stack spacing={1} direction='row' className='mb-2'>
            <div
              className='back-div'
            >
              <ArrowBackIcon
                className='back-icon'
                onClick={(): void => {
                  window.history.back();
                }}
              />
            </div>
            <div className='header'>
              {type === 'edit' ? <p className='template-heading'>Edit lease</p> : (
                <p className='template-heading'>
                  Upload New Lease
                </p>
              )}
            </div>
            <div className='ms-auto cursor-pointer'>
              <Tooltip title={(
                <>
                  max single file size: 30 MB
                  <br />
                  max total size: 300 MB
                  <br />
                  max no. of files: 30
                  <br />
                  In bulk Upload, an abstraction will be created separately for each File
                  <br />
                  In Combined Upload, an abstraction would be created for each group of files
                </>
              )}
              >
                <InfoIcon className='back-icon ' />
              </Tooltip>
            </div>
          </Stack>

          <form>
            <Grid container columnSpacing={2} mt={2}>

              {type === 'edit' && (
                <Grid item sm={12}>
                  <HookTextField
                    name='name'
                    label='Tenant Name'
                    control={control}
                    errors={errors}
                    maxLength={100}
                  />
                </Grid>
              )}

              <Grid item sm={12}>
                <SelectInput
                  value={JSON.stringify(selectedTemplate)}
                  name='type'
                  label='Type of lease report'
                  onChange={(obj: SelectChangeEvent): void => { setSelectedTemplate(Number(obj.target.value)); }}
                  options={templateList}
                  showPleaseSelect={false}
                />
              </Grid>

            </Grid>
          </form>

          {type === 'edit' ? (
            <>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Box className='preview-container-lease d-flex' sx={{ background: 'transparent', justifyContent: 'center', height: '8rem !important' }}>
                  Drag and drop, or click to select files
                </Box>
              </div>
              {renderList(pdfFile, false)}

              <Divider sx={{ mb: 2 }} />

              {editLeaseFiles !== undefined ? (
                <>
                  <h5 style={{ marginBottom: '2rem' }}>Files in this Abstraction</h5>

                  {editLeaseFiles?.all_files.length > 0 ? 'Combined Lease Document' : 'Lease Document'}
                  {renderList([editLeaseFiles.combined_file], true, false)}

                  {editLeaseFiles?.all_files.length > 0 ? 'Separate Files' : ''}
                  { renderList(editLeaseFiles?.all_files, true, false)}

                </>
              ) : null}
            </>
          ) : (
            <>
              <div>
                <h5>
                  Bulk Upload
                </h5>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <Box className='preview-container-lease d-flex' sx={{ background: 'transparent', justifyContent: 'center', height: '8rem !important' }}>
                    Drag and drop, or click to select files
                  </Box>
                </div>
                {renderList(pdfFile, false)}
              </div>
              <CombinedUpload onGroupsChange={handleGroupChange} formatFileSize={formatFileSize} />
            </>
          )}
        </section>

        {errorMessages.length > 0 ? errorMessages.map((errorMessage) => (
          <Box sx={{ flexGrow: 1 }} key={errorMessage}>
            <AppBar position='static' className='cois-error-bar' sx={{ marginTop: 'auto' }} elevation={0}>
              <Toolbar>
                <img src={`${process.env.REACT_APP_ASSESTS_URL}cois-error.webp`} alt='error' />
                <Typography component='div' sx={{ flexGrow: 1 }}>
                  <div>{errorMessage}</div>
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
        )) : null}

        <div className='add-lease'>
          <Button
            className='text-transform-none btn'
            onClick={(): void => {
              setpdfFile([]);
              reset();
              window.history.back();
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={onAddFiles}
            className='durdate-save-btn'
            style={{
              textTransform: 'inherit', width: 'fit-content', color: checkDisabled() ? 'rgba(33, 33, 33, 0.38)' : 'white', background: checkDisabled() ? '#E4E4E4' : '#00CFA1', cursor: checkDisabled() ? 'no-drop' : 'pointer', fontSize: '12px', borderRadius: '8px',
            }}
            variant='contained'
            disabled={checkDisabled()}
          >
            Upload
            {' '}
          </Button>
        </div>
      </div>

    </Stack>
  );
}
