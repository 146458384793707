/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import SecondaryButton2 from 'shared-components/components/SecondaryButton2';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';

export default function NewMobileNav(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const navbarToogle = useSelector((state: RootState) => state.workspaces.sideSheetData.navbarToogle);
  const [open, setOpen] = useState(false);
  let lastScrollTop = 0;
  const componentRef = useRef<HTMLDivElement>(null);
  const scrollHandle = (): void => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollTop < 10) {
      if (componentRef.current !== null) {
        componentRef.current.style.top = '0';
      }
    } else if (scrollTop > lastScrollTop) {
      if (componentRef.current !== null) {
        componentRef.current.style.top = '-72px';
      }
    } else if (componentRef.current !== null) {
      componentRef.current.style.top = '0';
      if (navbarToogle) {
        componentRef.current.style.background = 'white';
      } else {
        componentRef.current.style.background = '#D9F6F5';
      }
    }

    lastScrollTop = scrollTop;
  };

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/contact-us') {
      window.addEventListener('scroll', scrollHandle);
    }
    return () => {
      if (componentRef.current !== null) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        componentRef.current.style.top = '0';
        window.removeEventListener('scroll', scrollHandle);
      }
    };
  });

  return (
    <nav className='client-navbar-container' ref={componentRef} style={{ background: !open && (location.pathname === '/' || location.pathname === '/contact-us') ? '#D9F6F5' : 'white', borderBottom: !open && (location.pathname === '/' || location.pathname === '/contact-us') ? 'none' : '1px solid rgba(33, 33, 33, 0.12)' }}>
      <div className='client-main-navbar'>

        {/* Left side ==> */}
        <div className='left-side-nav'>
          <Link to='/' className='logo-wrapper'>
            <img src={`${process.env.REACT_APP_ASSESTS_URL}Logo.webp`} alt='strada-logo' />
          </Link>
        </div>

        <div className='right-side-nav'>
          <div className='client-nav-links' />
          {open ? <CloseIcon className='fa-solid fa-xmark mobile-hamburger-cross' style={{ fontSize: '18px', marginRight: '7px', marginTop: '2px' }} onClick={(): void => { setOpen(false); }} /> : <MenuRoundedIcon className='mobile-hamburger' onClick={(): void => { setOpen(true); }} /> }
        </div>
      </div>
      {open ? (
        <div className='client-mobile-list'>
          <div className='client-mobile-list-wrapper'>
            <Button
              style={{
                margin: '20px auto auto auto', width: '92%', height: '48px', fontWeight: 600, fontSize: '16px', borderRadius: '12px', color: '#18181B', background: 'white', textTransform: 'capitalize', border: '1px solid #E4E4E4',
              }}
              fullWidth
              onClick={(): void => { navigate('/signin'); setOpen(false); }}
            >
              {' '}
              Log in
            </Button>
            <SecondaryButton2
              style={{
                margin: '20px auto', width: '92%', height: '48px', fontSize: '16px', fontWeight: 600,
              }}
              fullWidth
              onClick={(): void => { navigate('/requestdemo'); }}
            >
              {' '}
              Request demo
            </SecondaryButton2>

          </div>
        </div>
      ) : ''}

    </nav>
  );
}
