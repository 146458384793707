/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import {
  Avatar, Button, IconButton, Tooltip, ThemeProvider, createTheme,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TitleIcon from '@mui/icons-material/Title';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import InputField from 'shared-components/inputs/InputField';
import AvatarGroup from '@mui/material/AvatarGroup';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type {
  ITasks, IContent, ISubTasks, Iassignee, DropResult,
} from './types';

interface IAllTaskProps {
  data: ITasks[] | undefined;
  setData: (value: ITasks[]) => void;
  focusedTask: ITasks | undefined;
  setFocusedTask: (value: ITasks) => void;
  setAddChanges: (value: boolean) => void;
  setOpenTaskAI: (value: boolean) => void;
  focusTaskIndex: number;
  setFocusTaskIndex: (value: number) => void;
  handleSocketClose: () => void;
  socketOpen: boolean;
  isFound: boolean;
  setIsFound: (value: boolean) => void;
  deletedItemsIDs: string[];
  setDeletedItemsIDs: (value: string[]) => void;
  taskId: string;
  disableTaskList: boolean;
  setTaskDetail: (value: string) => void;

}
const customTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          color: '#FFFFFFF',
          backgroundColor: '#3E3E3E',
          letterSpacing: '0.4px',
        },
      },
    },
  },
});

function HandIcon(): JSX.Element {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M17.2617 4.11328V15.0117C17.2617 15.832 16.957 16.5352 16.3477 17.1211C15.7617 17.707 15.0586 18 14.2383 18H8.78906C7.94531 18 7.23047 17.707 6.64453 17.1211L0.738281 11.1094C1.37109 10.5 1.69922 10.1953 1.72266 10.1953C1.88672 10.0547 2.08594 9.98438 2.32031 9.98438C2.48438 9.98438 2.63672 10.0195 2.77734 10.0898L6.01172 11.918V2.98828C6.01172 2.68359 6.11719 2.42578 6.32812 2.21484C6.5625 1.98047 6.83203 1.86328 7.13672 1.86328C7.44141 1.86328 7.69922 1.98047 7.91016 2.21484C8.14453 2.42578 8.26172 2.68359 8.26172 2.98828V8.26172H9V1.125C9 0.796875 9.10547 0.527344 9.31641 0.316406C9.52734 0.105469 9.79688 0 10.125 0C10.4531 0 10.7227 0.105469 10.9336 0.316406C11.1445 0.527344 11.25 0.796875 11.25 1.125V8.26172H11.9883V1.86328C11.9883 1.55859 12.0938 1.30078 12.3047 1.08984C12.5391 0.855469 12.8086 0.738281 13.1133 0.738281C13.418 0.738281 13.6758 0.855469 13.8867 1.08984C14.1211 1.30078 14.2383 1.55859 14.2383 1.86328V8.26172H15.0117V4.11328C15.0117 3.80859 15.1172 3.55078 15.3281 3.33984C15.5625 3.10547 15.832 2.98828 16.1367 2.98828C16.4414 2.98828 16.6992 3.10547 16.9102 3.33984C17.1445 3.55078 17.2617 3.80859 17.2617 4.11328Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}
function AIIcon(): JSX.Element {
  return (
    <svg width='24' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M20.0156 7C20.5469 7 21.0312 7.14062 21.4688 7.42188C21.9375 7.67188 22.3125 8.03125 22.5938 8.5C22.875 8.9375 23.0156 9.4375 23.0156 10C23.0156 10.5625 22.875 11.0781 22.5938 11.5469C22.3125 11.9844 21.9375 12.3438 21.4688 12.625C21.0312 12.875 20.5469 13 20.0156 13V16.9844C20.0156 17.5469 19.8125 18.0312 19.4062 18.4375C19.0312 18.8125 18.5625 19 18 19H6C5.4375 19 4.95312 18.8125 4.54688 18.4375C4.17188 18.0312 3.98438 17.5469 3.98438 16.9844V13C3.45312 13 2.95312 12.875 2.48438 12.625C2.04688 12.3438 1.6875 11.9844 1.40625 11.5469C1.125 11.0781 0.984375 10.5625 0.984375 10C0.984375 9.4375 1.125 8.9375 1.40625 8.5C1.6875 8.03125 2.04688 7.67188 2.48438 7.42188C2.95312 7.14062 3.45312 7 3.98438 7V4.98438C3.98438 4.45313 4.17188 4 4.54688 3.625C4.95312 3.21875 5.4375 3.01562 6 3.01562H9C9 2.45312 9.125 1.95312 9.375 1.51562C9.65625 1.04688 10.0156 0.6875 10.4531 0.4375C10.9219 0.15625 11.4375 0.015625 12 0.015625C12.5625 0.015625 13.0625 0.15625 13.5 0.4375C13.9688 0.6875 14.3281 1.04688 14.5781 1.51562C14.8594 1.95312 15 2.45312 15 3.01562H18C18.5625 3.01562 19.0312 3.21875 19.4062 3.625C19.8125 4 20.0156 4.45313 20.0156 4.98438V7ZM7.5 9.48438C7.5 9.92188 7.64062 10.2812 7.92188 10.5625C8.23438 10.8438 8.59375 10.9844 9 10.9844C9.40625 10.9844 9.75 10.8438 10.0312 10.5625C10.3438 10.2812 10.5 9.92188 10.5 9.48438C10.5 9.07812 10.3438 8.73438 10.0312 8.45312C9.75 8.14062 9.40625 7.98438 9 7.98438C8.59375 7.98438 8.23438 8.14062 7.92188 8.45312C7.64062 8.73438 7.5 9.07812 7.5 9.48438ZM15.9844 15.0156V13H8.01562V15.0156H15.9844ZM15 10.9844C15.4062 10.9844 15.75 10.8438 16.0312 10.5625C16.3438 10.2812 16.5 9.92188 16.5 9.48438C16.5 9.07812 16.3438 8.73438 16.0312 8.45312C15.75 8.14062 15.4062 7.98438 15 7.98438C14.5938 7.98438 14.2344 8.14062 13.9219 8.45312C13.6406 8.73438 13.5 9.07812 13.5 9.48438C13.5 9.92188 13.6406 10.2812 13.9219 10.5625C14.2344 10.8438 14.5938 10.9844 15 10.9844Z' fill='#212121' fillOpacity='0.6' />
    </svg>

  );
}

export default function AllTasks(props: IAllTaskProps): JSX.Element {
  const {
    data, setData, focusedTask, setFocusedTask, setAddChanges, setOpenTaskAI, focusTaskIndex, setFocusTaskIndex,
    handleSocketClose, socketOpen, isFound, setIsFound, deletedItemsIDs, setDeletedItemsIDs, disableTaskList, setTaskDetail,
  } = props;
  const [hideTaskIndex, setHideTaskIndex] = useState<boolean>(false);
  const [openDeleteTaskDialog, setOpenDeleteTaskDialog] = useState<boolean>(false);
  const [duplicatedIndexes, setDuplicatedIndexes] = useState([0]);

  useEffect(() => {
    const taskElement = document.getElementById(focusTaskIndex.toString());
    taskElement?.focus();
  }, [focusTaskIndex]);

  /// <This code can be used later.>
  // const [autoFocus, setAutoFocus] = useState<boolean>(true);
  // useEffect(() => {
  //   // const taskElement = document.getElementById(focusTaskIndex.toString());
  //   // taskId && data?.length === 2 && socketOpen ? taskElement?.focus() : "";
  //   // taskId && data?.length === 2 && socketOpen && setAutoFocus(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socketOpen, data]);

  useEffect(() => {
    let index = 1;
    if (data && !isFound) {
      const filteredData = data.map((task: ITasks): ITasks => {
        if (!task.is_heading) {
          task.task_index = index;
          index++;
        }
        return task;
      });
      setIsFound(true);
      setData(filteredData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let index = 1;
    if (data !== undefined) {
      const filteredData = data.map((task: ITasks): ITasks => {
        if (!task.is_heading) {
          task.task_index = index;
          index++;
        }
        return task;
      });
      setData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideTaskIndex, focusTaskIndex]);

  React.useEffect(() => {
    setIsFound(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFound]);

  function HandleTaskOrder(index: number): string {
    if (data !== undefined) {
      if (index === 0) {
        return 'task-input-first';
      }
      if (index === data.length - 1) {
        return 'task-input-last';
      }
    }
    return 'task-input-middle';
  }
  function HandleTaskName(event: React.ChangeEvent<HTMLInputElement>, index: number): void {
    if (data !== undefined && focusedTask !== undefined && event.target.value.length < 250) {
      setFocusedTask({ ...focusedTask, name: event.target.value });
      setData(data.map((task: ITasks, indx) => {
        if (index === indx) {
          task.name = event.target.value;
        }
        return task;
      }));
      setAddChanges(true);
    }
  }
  function addTaskOrHeading(isheading: boolean): void {
    if (data === undefined) {
      const obj: ITasks = {
        unique_position_key: Date.now().toString(),
        name: '',
        is_heading: isheading,
        assignees: [],
        content: [],
        due_is_after: true,
        due_is_weekday_only: false,
        due_minutes: 0,
        due_months: 0,
        due_days: 0,
        due_hours: 0,
        is_stop: false,
      };
      setData([obj]);
      setAddChanges(true);
      setFocusedTask(obj);
      setFocusTaskIndex(focusTaskIndex + 1);
    }
    if (data !== undefined) {
      const obj: ITasks = {
        unique_position_key: Date.now().toString(),
        name: '',
        is_heading: isheading,
        assignees: [],
        content: [],
        due_is_after: true,
        due_is_weekday_only: false,
        due_minutes: 0,
        due_months: 0,
        due_days: 0,
        due_hours: 0,
        is_stop: false,
      };
      data.splice(focusTaskIndex + 1, 0, obj);
      setData(data);
      setAddChanges(true);
      setFocusedTask(obj);
      setFocusTaskIndex(focusTaskIndex + 1);
    }
  }

  function duplicateTask(index: number): void {
    setDuplicatedIndexes([0, index + 1]);
    if (data !== undefined && focusedTask !== undefined) {
      const obj: ITasks = JSON.parse(JSON.stringify(focusedTask));
      obj.unique_position_key = Date.now().toString();
      delete obj.id;
      obj.content.map((item: IContent) => {
        delete item.id;
        if (item.type === 'subTask' && item.subTasks !== undefined) {
          item.subTasks.map((subtask: ISubTasks) => {
            delete subtask.id;
          });
        }
        if (item.type === 'file') {
          item.is_duplicate = true;
          item.is_new = false;
        }
      });
      const newData = [...data];
      newData.splice(index + 1, 0, obj);
      // newData.push(obj);
      setData(newData);
      setFocusedTask(newData[index + 1]);
      setFocusTaskIndex(index + 1);
      setAddChanges(true);
    }
  }
  function reorder(list: ITasks[], startIndex: number, endIndex: number): ITasks[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  function onDragEnd(result: DropResult): void {
    if (data !== undefined) {
      if (result.destination) {
        setData(reorder(data, result.source.index, result.destination.index));
        setHideTaskIndex(false);
        setAddChanges(true);
      }
    }
  }

  function deleteTask(): void {
    if (data !== undefined && focusedTask !== undefined) {
      const index = data.findIndex((task) => task.unique_position_key === focusedTask.unique_position_key);
      if (data.length !== 1) {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        setAddChanges(true);
        if (focusedTask.unique_position_key !== undefined) {
          const newArray = [...deletedItemsIDs];
          newArray.push(focusedTask.unique_position_key);
          setDeletedItemsIDs(newArray);
        }
        if (index !== 0) {
          setFocusedTask(newData[index - 1]);
          setFocusTaskIndex(index - 1);
        } else {
          setFocusedTask(newData[index]);
          setFocusTaskIndex(index);
        }
        setOpenDeleteTaskDialog(false);
      }
    }
  }

  return (
    <div className={disableTaskList ? 'task-wrap-div task-wrap-div-disable' : 'task-wrap-div'}>
      <div className='task-wrap'>
        <DragDropContext onDragEnd={(result: DropResult): void => { onDragEnd(result); }} onDragStart={(): void => { setHideTaskIndex(true); }}>
          <Droppable droppableId='12345678'>

            {(provided): JSX.Element => (
              <div ref={provided.innerRef}>
                {
                  data !== undefined ? data.map((task: ITasks, index) => (
                    <Draggable draggableId={String(task.unique_position_key)} key={task.unique_position_key} index={index}>
                      {(provideed): JSX.Element => (
                        <div ref={provideed.innerRef} {...provideed.draggableProps} {...provideed.dragHandleProps}>
                          <div className='task-input d-flex align-items-center' aria-hidden='true' onClick={(): void => { setFocusedTask(task); setFocusTaskIndex(index); }}>
                            <div className='task-index' style={{ color: focusedTask !== undefined && focusedTask.unique_position_key === task.unique_position_key ? '#00CFA1' : '#212121' }}>
                              <p>{task.task_index}</p>
                              {task.is_stop && <div className={focusedTask !== undefined && focusedTask.unique_position_key === task.unique_position_key ? 'hand-icon-focus' : 'hand-icon'}><HandIcon /></div>}
                            </div>
                            <div className={HandleTaskOrder(index)}>
                              <div className={task.is_heading ? 'headingInput' : 'taskInput'}>
                                <InputField
                                  name='name'
                                  id={index.toString()}
                                  type='text'
                                  onChange={(event): void => { HandleTaskName(event, index); }}
                                  value={task.name}
                                  maxLength={150}
                                  required
                                  autoFocus={duplicatedIndexes.includes(index)}
                                  endAdornment={(
                                    <>
                                      <AvatarGroup max={2}>
                                        {task.assignees.map((assignee: Iassignee) => (
                                          <Avatar alt={assignee.name} key={assignee.id} src={`${process.env.REACT_APP_IMAGE_URL}${assignee.avatar}`} sx={{ height: '28px', width: '28px' }} />
                                        ))}
                                      </AvatarGroup>
                                      <PopupState variant='popper' popupId='demo-popup-popper'>
                                        {(popupState): JSX.Element => (
                                          <div>
                                            <IconButton {...bindToggle(popupState)}>
                                              <MoreHorizIcon />
                                            </IconButton>
                                            <Popper {...bindPopper(popupState)} transition>
                                              {({ TransitionProps }): JSX.Element => (
                                                <ClickAwayListener
                                                  onClickAway={(): void => {
                                                    popupState.close();
                                                  }}
                                                >
                                                  <Fade {...TransitionProps} timeout={350}>
                                                    <Paper className='checklist-list-popover'>
                                                      <div style={{ display: socketOpen ? 'none' : 'block' }}>
                                                        {focusedTask !== undefined && focusedTask.name !== '' && focusedTask.name !== null
                                                          ? (
                                                            <div
                                                              className='chart-btn'
                                                              aria-hidden='true'
                                                              onClick={(): void => {
                                                                setTaskDetail('');
                                                                setOpenTaskAI(true);
                                                                popupState.close();
                                                              }}
                                                            >
                                                              <AIIcon />
                                                              <span className='edit-delete-text '> Generate task</span>
                                                            </div>
                                                          )
                                                          : (
                                                            <ThemeProvider theme={customTheme}>
                                                              <Tooltip title={(
                                                                <span>
                                                                  Can’t generate task with AI.
                                                                  <br />
                                                                  {' '}
                                                                  You need to enter a
                                                                  {' '}
                                                                  <span style={{ color: '#00CFA1' }}>task name</span>
                                                                  .
                                                                </span>
                                                              )}
                                                              >
                                                                <div
                                                                  className='chart-btn'
                                                                  style={{ color: '#ABABAB', cursor: 'no-drop' }}
                                                                >
                                                                  <AIIcon />
                                                                  <span className='edit-delete-text '> Generate task</span>
                                                                </div>
                                                              </Tooltip>
                                                            </ThemeProvider>
                                                          )}
                                                      </div>
                                                      <div
                                                        className='chart-btn'
                                                        onClick={(): void => {
                                                          duplicateTask(index);
                                                          popupState.close();
                                                        }}
                                                        aria-hidden='true'
                                                      >
                                                        <FileCopyIcon className='edit-delete-icon' />
                                                        <span className='edit-delete-text '> Duplicate</span>
                                                      </div>
                                                      {!(index === 0 && (task.name === '' || task.name === null || data.length === 1)) && (
                                                        <div
                                                          className='chart-btn'
                                                          onClick={(): void => {
                                                            setOpenDeleteTaskDialog(true);
                                                            popupState.close();
                                                          }}
                                                          aria-hidden='true'
                                                        >
                                                          <DeleteIcon className='edit-delete-icon' />
                                                          <span className='edit-delete-text '>
                                                            Delete
                                                          </span>
                                                        </div>
                                                      )}
                                                    </Paper>
                                                  </Fade>
                                                </ClickAwayListener>
                                              )}
                                            </Popper>
                                          </div>
                                        )}
                                      </PopupState>
                                    </>
                                  )}
                                  placeholder={task.is_heading ? 'Type heading name here' : 'Type task name here'}
                                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>): void => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      addTaskOrHeading(false);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )) : null
                }
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* {renderTasks(filterData, setData, hideTaskIndex, setHideTaskIndex, focusedTask, setFocusedTask, focusTaskIndex, setFocusTaskIndex, setAddChanges)} */}
        <PopupState variant='popper' popupId='demo-popup-popper'>
          {(popupState): JSX.Element => (
            <div>
              <div className='addTaskDiv' {...bindToggle(popupState)}>
                <div className='d-flex justify-content-center align-items-center'>
                  <AddIcon />
                  <p>Add</p>
                </div>
              </div>
              <Popper {...bindPopper(popupState)} transition>
                {({ TransitionProps }): JSX.Element => (
                  <ClickAwayListener
                    onClickAway={(): void => {
                      popupState.close();
                    }}
                  >
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper className='checklist-list-popover'>
                        <div
                          className='chart-btn'
                          aria-hidden='true'
                          onClick={(): void => {
                            addTaskOrHeading(false);
                            popupState.close();
                          }}
                        >
                          <AddBoxIcon className='edit-delete-icon' />
                          <span className='edit-delete-text '> Create task</span>
                        </div>
                        <div
                          className='chart-btn'
                          aria-hidden='true'
                          onClick={(): void => {
                            addTaskOrHeading(true);
                            popupState.close();
                          }}

                        >
                          <TitleIcon className='edit-delete-icon' />
                          <span className='edit-delete-text '> Create heading</span>
                        </div>
                      </Paper>
                    </Fade>
                  </ClickAwayListener>
                )}
              </Popper>
            </div>
          )}
        </PopupState>
        {socketOpen && (
          <div className='gpt-bar' style={{ width: '100%', paddingLeft: '1rem' }}>
            <div className='d-flex justify-content-between p-2 align-center'>
              <h5 className='questions-p'>
                Generating
                <span className='host'>
                  <span className='loading-gpt-res loading-0' />
                  <span className='loading-gpt-res loading-1' />
                  <span className='loading-gpt-res loading-2' />
                </span>
              </h5>
              <div
                className='generate-task-wrap'
                style={{ color: '#f44a41', padding: '6px 16px' }}
                aria-hidden='true'
                onClick={(): void => { handleSocketClose(); }}
              >
                <p style={{ marginLeft: '0px' }}>
                  Stop
                  {' '}

                </p>
              </div>
            </div>

          </div>
        )}
      </div>
      <Dialog
        open={openDeleteTaskDialog}
        keepMounted
      >
        <DialogContent style={{ width: 500, padding: '24px' }}>
          <div className='dialog-heading'>Delete selected task?</div>
          <span
            className='dialog-body'
            style={{
              fontSize: '14px', color: 'rgba(33, 33, 33, 0.6)', fontWeight: '400', marginTop: '15px',
            }}
          >
            This task and all of its content will be deleted
          </span>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button
            style={{ textTransform: 'inherit' }}
            onClick={(): void => {
              setOpenDeleteTaskDialog(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button variant='contained' onClick={(): void => { deleteTask(); }} style={{ textTransform: 'inherit', color: 'white', background: '#00CFA1' }} color='primary' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
