/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import * as React from 'react';
import { useSelector } from 'react-redux';
import './_coisErrors.scss';
import type { AxiosResponse } from 'axios';
import { useQueryClient, useQuery } from 'react-query';
import axios from 'axios';
import PrimayButton from 'shared-components/components/PrimayButton';
import type { RootState } from 'mainStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/Warning';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Ipayload, Ierror } from './types';
import EmailTemplate from './emailTempalteCOIs/EmailTemplate';

interface LocationState {
  id?: number;
}

export default function ErrorTemplateCOI(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = useLocation().state as LocationState;
  const [emailData, setEmailData] = React.useState<Ierror | null>(null);
  const [emailType, setEmailType] = React.useState('deficiency');
  const [highlightedId, setHighlightedId] = React.useState<number | null>(null);
  const recordRefs = React.useRef<Record<string, HTMLDivElement | null>>({});

  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);

  let url = `/api/coise-errors/?workspace=${currentWorkspace.id}`;
  if (location.hash === '#expiry') {
    url = `/api/coi/cois_expiry/?workspace=${currentWorkspace.id}`;
  }

  React.useEffect(() => {
    if (location.hash === '#expiry') {
      setEmailType('expiry');
    }
  }, [location]);

  const { data: coisErrors = [] } = useQuery(
    ['get/cois-error', location.hash, currentWorkspace.id],
    async () => axios({
      url,
      method: 'get',
    }),
    {
      enabled: Boolean(currentWorkspace.id),
      select: (res: AxiosResponse<Ipayload>) => res.data.results,
    },
  );

  React.useEffect(() => {
    if (emailData !== null) return;
    async function fetchData(): Promise <void> {
      await queryClient.invalidateQueries('get/cois-error').catch()
        .then();
    }
    fetchData().catch((e) => { throw e; });
  }, [emailData]);

  React.useEffect(() => {
    const recordId = stateData?.id;

    if (recordId) {
      const matchingRecord = coisErrors.find((record) => record.cois_id === recordId);

      if (matchingRecord) {
        setHighlightedId(recordId);

        recordRefs.current[recordId]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

        setTimeout(() => { setHighlightedId(null); }, 3000);
      }
    }
  }, [location.state, coisErrors]);

  return (
    <>
      { !emailData && (
        <div className='coi-warning-container'>
          <div className='heading-container d-flex justify-content-start'>
            <ArrowBackIcon className='back-icon' onClick={(): void => { navigate('/workspace/cois'); }} />
            {' '}
            <p className='heading'>{location.hash === '#expiry' ? 'Certifications Expiring Soon' : 'Deficient Certifications'}</p>
          </div>
          <div className='certificates'>
            {coisErrors?.map((item) => item.errors.length > 0 && (
              <div
                ref={(el): void => {
                  if (el) recordRefs.current[item.cois_id] = el;
                }}
                className={`record ${highlightedId === item.cois_id ? 'blinking card' : 'card'}`}
                key={item.cois_id}
              >
                <div className='title'>{item.insured}</div>
                <div className='rows'>

                  {item.errors.map((error) => (
                    <div className='warning-row d-flex align-items-center' key={`${Math.random()} ${error.error_text}`}>
                      <div className='alert-sign'>
                        <WarningIcon className='fas fa-exclamation-triangle' />
                      </div>
                      <div className='desc pt-2'>{error.error_text}</div>
                    </div>
                  ))}
                </div>
                <div className='bottom-line'>

                  <div className='button d-flex'>
                    <PrimayButton onClick={(): void => {
                      setEmailData(item);
                    }}
                    >
                      Send

                    </PrimayButton>
                  </div>
                  {location.hash === '#expiry'
                    ? item.sent_expiry_notification && <p>Notice Sent for Expiry.</p>
                    : item.sent_notes && <p>Notice Sent.</p>}

                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {emailData && <EmailTemplate emailType={emailType} emailData={emailData} setEmailData={(): void => { setEmailData(null); }} /> }
    </>
  );
}
