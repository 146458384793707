/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable  @typescript-eslint/strict-boolean-expressions */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import {
  Typography, Grid, Button, IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import type { SubmitHandler } from 'react-hook-form';
import {
  useQuery,
  useMutation, useQueryClient,
} from 'react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import HookTextField from 'shared-components/hooks/HookTextField';
import PrimayButton from 'shared-components/components/PrimayButton';
import CustomLoader from 'shared-components/components/CustomLoader';
import type { IFormValues } from 'formsTypes';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import { decrypt } from 'shared-components/hooks/useEncryption';
import CheckboxLeaseTree from './CheckboxLeaseTree';
import type { ILeasePayload, IHeadingsResponse, Heading } from '../types';

const schema = yup.object().shape({
  template_name: yup.string().trim().required('Please enter template name'),
});

const defaultInput = {
  template_name: '',
};

function AddLeaseForm(): JSX.Element {
  const { tempId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [data, setData] = useState<Heading[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leftSelectedItems, setLeftSelectedItems] = useState<string[]>([]);
  const [rightSelectedItems, setRightSelectedItems] = useState<string[]>([]);
  const [finalItems, setFinalItems] = useState<string[]>([]);
  const [indeterminateStates, setIndeterminateStates] = useState<{
    left: Record<string, boolean>;
    right: Record<string, boolean>;
  }>({ left: {}, right: {} });

  const currentWorkspace = useSelector(
    (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
  );

  const {
    control, formState, handleSubmit, setValue, getValues,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: defaultInput,
    resolver: yupResolver(schema),
  });

  useQuery(
    ['get/headigs', tempId],
    async () => axios({
      url: tempId === 'new' ? 'api/lease-template/template_headings' : `api/lease-template/${decrypt(tempId)}?workspace=${currentWorkspace.id}`,
      method: 'get',
    }),
    {
      select: (res: AxiosResponse<IHeadingsResponse>) => res.data.detail,
      onSuccess: (res) => {
        setData(res.headings);
        setIsLoading(false);

        if (tempId !== 'new') {
          setValue('template_name', res.template_name);
        }

        // Initialize finalItems with both checked subheadings and headings
        const initialFinalItems = res.headings
          .flatMap((heading) => [
            ...heading.sub_headings.filter((sub) => sub.is_checked).map((sub) => `${heading.id}-${sub.id}`),
            heading.is_checked ? `${heading.id}` : null,
          ])
          .filter((item): item is string => item !== null); // Filter out null values

        setFinalItems(initialFinalItems);
      },
      onError: (err: { data: { detail: string } }) => {
        setIsLoading(false);
        enqueueSnackbar(err.data.detail || 'Error Occured. Try again later', {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });
      },
    },
  );

  const { mutate: createUpdateLeaseTemplate } = useMutation(
    async (leasePayload: ILeasePayload) => axios({
      url: tempId === 'new' ? '/api/lease-template/' : `/api/lease-template/${decrypt(tempId)}/`,
      method: tempId === 'new' ? 'POST' : 'PUT',
      data: leasePayload,
    }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries('get/lease-template').then();
        enqueueSnackbar(`Template ${getValues('template_name')} ${tempId === 'new' ? 'created' : 'updated'}.`);
        navigate('/workspace/settings/lease-abstraction');
      },
      onError: (err: { data: { detail: string } }) => {
        enqueueSnackbar(err.data.detail || 'Error Occured. Try again later', {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });
      },
    },
  );

  const { errors } = formState;

  const leftContainerItems = data
    .map((heading) => ({
      ...heading,
      sub_headings: heading.sub_headings.filter((sub) => !finalItems.includes(`${heading.id}-${sub.id}`)),
    }))
    .filter((heading) => heading.sub_headings.length > 0 || !finalItems.includes(heading.id.toString()));

  const rightContainerItems = data
    .map((heading) => ({
      ...heading,
      sub_headings: heading.sub_headings.filter((sub) => finalItems.includes(`${heading.id}-${sub.id}`)),
    }))
    .filter((heading) => heading.sub_headings.length > 0 || finalItems.includes(heading.id.toString()));

  useEffect(() => {
    const calculateIndeterminateStates = (selectedItems: string[], containerItems: Heading[]): Record<string, boolean> => {
      const newIndeterminateStates: Record<string, boolean> = {};
      containerItems.forEach((heading) => {
        const someChecked = heading.sub_headings.some((sub) => selectedItems.includes(`${heading.id}-${sub.id}`));
        const allChecked = heading.sub_headings.every((sub) => selectedItems.includes(`${heading.id}-${sub.id}`));
        newIndeterminateStates[heading.id.toString()] = someChecked && !allChecked;
      });
      return newIndeterminateStates;
    };

    const leftIndeterminateStates = calculateIndeterminateStates(leftSelectedItems, leftContainerItems);
    const rightIndeterminateStates = calculateIndeterminateStates(rightSelectedItems, rightContainerItems);

    setIndeterminateStates({
      left: leftIndeterminateStates,
      right: rightIndeterminateStates,
    });
  }, [data, leftSelectedItems, rightSelectedItems]);

  const handleCheckboxChange = (id: string, isSubHeading: boolean, container: 'left' | 'right'): void => {
    if (isSubHeading) {
      const headingIdStr = id.split('-')[0];
      const headingId = parseInt(headingIdStr, 10);

      if (container === 'left') {
        setLeftSelectedItems((prevSelected) => {
          let updatedSelectedItems = [...prevSelected];
          const heading = leftContainerItems.find((h) => h.id === headingId);

          if (prevSelected.includes(id)) {
            updatedSelectedItems = updatedSelectedItems.filter((item) => item !== id); // remove id

            if (heading) {
              const subHeadingsSelected = heading.sub_headings.some((sh) => updatedSelectedItems.includes(`${heading.id}-${sh.id}`));

              if (!subHeadingsSelected) {
                updatedSelectedItems = updatedSelectedItems.filter((item) => item !== headingIdStr); // remove parent id if no sub headings remain
              }
            }
            return updatedSelectedItems;
          }

          updatedSelectedItems = [...updatedSelectedItems, id]; // add id
          if (heading) {
            const allSubHeadingsSelected = heading.sub_headings.every((sh) => updatedSelectedItems.includes(`${heading.id}-${sh.id}`));

            if (allSubHeadingsSelected && !updatedSelectedItems.includes(headingIdStr)) {
              updatedSelectedItems = [...updatedSelectedItems, headingIdStr];
            }
          }

          return updatedSelectedItems;
        });
      } else {
        setRightSelectedItems((prevSelected) => {
          let updatedSelectedItems = [...prevSelected];
          const heading = rightContainerItems.find((h) => h.id === headingId);

          if (prevSelected.includes(id)) {
            updatedSelectedItems = updatedSelectedItems.filter((item) => item !== id); // remove id

            if (heading) {
              const subHeadingsSelected = heading.sub_headings.some((sh) => updatedSelectedItems.includes(`${heading.id}-${sh.id}`));

              if (!subHeadingsSelected) {
                updatedSelectedItems = updatedSelectedItems.filter((item) => item !== headingIdStr); // remove parent id if no sub headings remain
              }
            }
            return updatedSelectedItems;
          }

          updatedSelectedItems = [...updatedSelectedItems, id]; // add id
          if (heading) {
            const allSubHeadingsSelected = heading.sub_headings.every((sh) => updatedSelectedItems.includes(`${heading.id}-${sh.id}`));

            if (allSubHeadingsSelected && !updatedSelectedItems.includes(headingIdStr)) {
              updatedSelectedItems = [...updatedSelectedItems, headingIdStr];
            }
          }

          return updatedSelectedItems;
        });
      }
    } else {
      let containerItems = rightContainerItems;
      if (container === 'left') {
        containerItems = leftContainerItems;
      }
      const heading = containerItems.find((item) => item.id.toString() === id);

      if (heading) {
        const subHeadingIds = heading.sub_headings.map((sub) => `${heading.id}-${sub.id}`);
        if (container === 'left') {
          setLeftSelectedItems((prevSelected) => {
            const allSelected = subHeadingIds.length === 0
              ? prevSelected.includes(id)
              : subHeadingIds.every((subId) => prevSelected.includes(subId));
            if (allSelected) {
              return prevSelected.filter((item) => !subHeadingIds.includes(item) && item !== id);
            }
            return [...prevSelected, ...subHeadingIds.filter((subId) => !prevSelected.includes(subId)), id];
          });
        } else {
          setRightSelectedItems((prevSelected) => {
            const allSelected = subHeadingIds.length === 0
              ? prevSelected.includes(id)
              : subHeadingIds.every((subId) => prevSelected.includes(subId));
            if (allSelected) {
              return prevSelected.filter((item) => !subHeadingIds.includes(item) && item !== id);
            }
            return [...prevSelected, ...subHeadingIds.filter((subId) => !prevSelected.includes(subId)), id];
          });
        }
      }
    }
  };

  const moveToRight = (): void => {
    setFinalItems((prevFinal) => [...prevFinal, ...leftSelectedItems]);
    setLeftSelectedItems([]);
  };

  const moveToLeft = (): void => {
    setFinalItems((prevFinal) => prevFinal.filter((item) => !rightSelectedItems.includes(item)));
    setRightSelectedItems([]);
  };

  const generatePayload = (): any => data.map((heading) => ({
    id: tempId === 'new' ? heading.id : heading.lease_template_heading,
    is_checked: heading.sub_headings.length === 0 ? finalItems.includes(heading.id.toString()) : null,
    checked_sub_headings: heading.sub_headings
      .filter((sub) => finalItems.includes(`${heading.id}-${sub.id}`))
      .map((sub) => (tempId === 'new' ? sub.id : sub.lease_template_sub_heading)),
    unchecked_sub_headings: heading.sub_headings
      .filter((sub) => !finalItems.includes(`${heading.id}-${sub.id}`))
      .map((sub) => (tempId === 'new' ? sub.id : sub.lease_template_sub_heading)),
  }));

  const onSubmit: SubmitHandler<IFormValues> = (values: { template_name?: string | undefined }) => {
    if (finalItems.length === 0) {
      enqueueSnackbar('Please select at least one heading or subheading.', { variant: 'warning' });
      return;
    }
    const payload = {
      workspace: currentWorkspace.id,
      template_name: values.template_name !== undefined ? values.template_name : '',
      headings: generatePayload(),
    };
    createUpdateLeaseTemplate(payload);
  };

  const leftContainerEmpty = leftContainerItems.length === 0;
  const rightContainerEmpty = rightContainerItems.length === 0;

  return (
    <div className='add-template-container'>
      <Stack spacing={1} direction='row' className='stack-header'>
        <div className='back-div'>
          <ArrowBackIcon
            className='back-icon'
            onClick={(): void => {
              navigate('/workspace/settings/lease-abstraction');
            }}
          />
        </div>
        {tempId === 'new' ? (
          <Typography className='template-heading'>Add New Template</Typography>
        ) : (
          <Typography className='template-heading'>Edit Template</Typography>
        )}
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={3}>
          <Grid item sm={12} md={12} sx={{ paddingLeft: '0px !important' }}>
            <HookTextField
              name='template_name'
              label='Name'
              control={control}
              errors={errors}
              maxLength={150}
            />
          </Grid>
          { isLoading ? <div style={{ height: '600px', width: '100%' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div>
            : (
              <>
                <Grid item sx={{ flexBasis: '45.8333%', maxWidth: '45.8333%', paddingLeft: '0px !important' }}>
                  <div className='template-section'>
                    {leftContainerEmpty ? (
                      <Typography className='empty-section'>No items to select</Typography>
                    ) : (
                      <CheckboxLeaseTree
                        data={leftContainerItems}
                        selectedItems={leftSelectedItems}
                        handleCheckboxChange={(id, isSubHeading): void => { handleCheckboxChange(id, isSubHeading, 'left'); }}
                        indeterminateStates={indeterminateStates.left}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={1} sx={{ paddingLeft: '0px !important' }} container direction='column' alignItems='center' justifyContent='center'>
                  <IconButton onClick={moveToRight} disabled={leftSelectedItems.length === 0}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <IconButton onClick={moveToLeft} disabled={rightSelectedItems.length === 0}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
                <Grid item sx={{ flexBasis: '45.8333%', maxWidth: '45.8333%', paddingLeft: '0px !important' }}>
                  <div className='template-section'>
                    {rightContainerEmpty ? (
                      <Typography className='empty-section'>0 selection</Typography>
                    ) : (
                      <CheckboxLeaseTree
                        data={rightContainerItems}
                        selectedItems={rightSelectedItems}
                        handleCheckboxChange={(id, isSubHeading): void => { handleCheckboxChange(id, isSubHeading, 'right'); }}
                        indeterminateStates={indeterminateStates.right}
                      />
                    )}
                  </div>
                </Grid>
              </>
            )}
        </Grid>
        <Grid className='d-flex justify-content-end' my={3}>
          <div className='action-btn'>
            <Button
              variant='outlined'
              onClick={(): void => {
                window.history.back();
              }}
              style={{
                textTransform: 'inherit',
                color: '#00CFA1',
                border: '0',
                marginRight: '15px',
              }}
              color='primary'
            >
              Cancel
            </Button>
            <PrimayButton type='submit' disabled={finalItems.length === 0}>{tempId === 'new' ? 'Save' : 'Save Changes'}</PrimayButton>
          </div>
        </Grid>
      </form>
    </div>
  );
}

export default AddLeaseForm;
