/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface Itypes {
  data: [];
  allBuildingListing: { name: string; value: number }[];
  sideSheetBuildingListing: { name: string; value: number }[];
}
const initialState: Itypes = {
  data: [],
  allBuildingListing: [],
  sideSheetBuildingListing: [],
};
const workspacesSlice = createSlice({
  name: 'buildings/data',
  initialState,
  reducers: {
    setAllWorkspaces: (state, { payload }) => {
      state.data = payload;
    },
    setAllBuildingsListing: (state, { payload }) => {
      state.allBuildingListing = payload;
    },
    setSideSheetBuildingListing: (state, { payload }) => {
      state.sideSheetBuildingListing = payload;
    },
  },
});

export const { setAllWorkspaces, setAllBuildingsListing, setSideSheetBuildingListing } = workspacesSlice.actions;

export default workspacesSlice.reducer;
