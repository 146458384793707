import React from 'react';
import './_hero.scss';
import Footer from '../footer/Footer';
import Hero from './Hero';
import Feature from './Feature';

export default function Home(): JSX.Element {
  // const navigate = useNavigate();
  return (
    <>
      <Hero />
      <Feature />
      <Footer />
      {/* <!-- Start of HubSpot Embed Code --> */}
      <script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/7523573.js' />
      {/* <!-- End of HubSpot Embed Code --> */}
    </>
  );
}
