import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PrimayButton from 'shared-components/components/PrimayButton';
import StandardButton from 'shared-components/components/StandardButton';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { RootState } from 'mainStore';
import { connectOutlook, disconnectOutlook } from './store/index';
import { SwitchIcon } from './integrationIcons';

export default function OutlookAuth(): JSX.Element {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const integrations = useSelector((state: RootState) => state.integrations);

  useQuery('get/outlook', async () => axios({
    url: 'api/register-outlook-mail/',
    method: 'GET',
  }), {
    onSuccess: (res) => {
      if (Boolean(res.data) && res.data.length !== 0) {
        const payload = {
          ...res.data[0],
          isConnected: true,
        };
        dispatch(connectOutlook(payload));
      } else {
        dispatch(disconnectOutlook());
      }
    },
  });

  const { mutate: deleteOutlookMethod, isLoading: deleting } = useMutation(async (id: number) => axios({
    url: `api/register-outlook-mail/${id}`,
    method: 'DELETE',
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('get/outlook').catch()
        .then();
      enqueueSnackbar('Disconnected Successfully');
    },
  });

  const { mutate: login, isLoading: registering } = useMutation(async () => axios({
    url: 'api/outlook/login',
    method: 'get',
  }), {
    // eslint-disable-next-line @typescript-eslint/require-await
    onSuccess: async (res) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.location.href = res.data.outlook_login_uri;
    },
    onError: (): void => {
      enqueueSnackbar('Failed to connect to Outlook', { variant: 'error' });
    },
  });

  return (
    <div>
      <StradaLoader open={registering || deleting} />
      {integrations.outlook.isConnected ? (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <StandardButton onClick={(): void => { deleteOutlookMethod(integrations.outlook.id); }} startIcon={<SwitchIcon />}>
          Disconnect
        </StandardButton>
      ) : (
        <div className='btn-wrapper'>

          <PrimayButton onClick={(): void => { login(); }}>
            Connect
          </PrimayButton>

        </div>
      )}

    </div>
  );
}
