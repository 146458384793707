/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
// import { Grid } from '@mui/material';
import './_privacyPolicy.scss';
import { Link, Element } from 'react-scroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Content(): JSX.Element {
  const [collapseItem, setCollapseItem] = useState({
    applicability: true,
    info: true,
    legal: true,
    share: true,
    retention: true,
    security: true,
    age: true,
    privacy: true,
    california: true,
    contacting: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='privacy-content'>
      <div className='container d-flex'>
        <div className='left-wrapper'>
          <div className='heading-table'>
            <div className='heading-inner-table-box'>
              <Link
                activeClass='active'
                to='Applicability of This Privacy Policy'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p> Applicability of This Privacy Policy</p>
              </Link>
              <Link
                activeClass='active'
                to='Information We Collect And Receive'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p>Information We Collect And Receive</p>
              </Link>
              <Link
                activeClass='active'
                to='How We Process Your Information and our Legal Bases for Doing So'
                smooth
                duration={80}
                offset={-110}
                isDynamic
                delay={0}
                spy
              >
                <p>How We Process Your Information and our Legal Bases for Doing So</p>
              </Link>
              <Link
                activeClass='active'
                to='How We Share and Disclose Information'
                smooth
                duration={80}
                offset={-110}
                isDynamic
                delay={0}
                spy
              >
                <p>How We Share and Disclose Information</p>
              </Link>
              <Link
                activeClass='active'
                to='Data Retention'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p>Data Retention </p>
              </Link>
              <Link
                activeClass='active'
                to='Security'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p>Security </p>
              </Link>
              <Link
                activeClass='active'
                to='Age Limitations'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p>Age Limitations </p>
              </Link>
              <Link
                activeClass='active'
                to='Changes To This Privacy Policy'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p>Changes To This Privacy Policy </p>
              </Link>
              <Link
                activeClass='active'
                to='Your California Privacy Rights'
                smooth
                duration={80}
                offset={-100}
                isDynamic
                delay={0}
                spy
              >
                <p>Your California Privacy Rights</p>
              </Link>
              <Link
                activeClass='active'
                to='Contacting Strada'
                smooth
                duration={80}
                offset={-130}
                isDynamic
                delay={0}
                spy
              >
                <p>Contacting Strada</p>
              </Link>
            </div>
          </div>
        </div>
        <div className='right-wrapper'>
          {/*  section-1 */}
          <p className='privacy-policy-heading'>Privacy Policy</p>
          <p className='privacy-policy-paragraph'>
            This Privacy Policy describes how Strada collects, uses, and discloses information associated with an identified or identifiable individual (referred to in this Privacy Policy as “Personal Data”) and what choices you have around this activity. If you have any questions, please don’t hesitate to contact us.
          </p>

          {/*   section-2 */}
          <Element name='Applicability of This Privacy Policy'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Applicability of This Privacy Policy</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, applicability: !collapseItem.applicability }); }}>
                {collapseItem.applicability ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.applicability || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph'>
                  This Privacy Policy applies to Strada’s online workplace productivity tools and platform, (collectively, the “Services”) strada.ai and other Strada websites (collectively, the “Websites”) and other interactions (e.g., customer service inquiries, user conferences, etc.) you may have with Strada. If you do not agree with this Privacy Policy, then do not access or use the Services, Websites or any other aspect of Strada’s business. For the avoidance of doubt, this is the only privacy policy that applies to Strada.
                </p>
                <p className='privacy-policy-paragraph'>
                  This Privacy Policy does not apply to any third-party applications or software that integrate with the Services through the Strada platform (“Third-Party Services”), or any other third-party products, services or businesses who will provide their services under their own terms of service and privacy policy. In addition, a separate agreement governs delivery, access and use of the Services (the “Customer Agreement”), including the processing of data such as messages, files or other content submitted through Services accounts (collectively, “Customer Data”). The organization (e.g., your employer or another entity or person) that entered into the Customer Agreement (“Customer”) controls its instance of the Services (its “Workspace”) and any associated Customer Data.
                </p>
                <p className='privacy-policy-paragraph'>
                  California Notice of Collection of Personal Information: We collect the information described below under “Information We Collect and Receive” for the business and commercial purposes described below under “Information Use.” To learn more about exercising your California Privacy Rights please review the “California Privacy Rights” section below.
                </p>
              </>
            )}

          </Element>
          {' '}
          {/*   section-3 */}
          <Element name='Information We Collect And Receive'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>
                Information We Collect And Receive
              </p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, info: !collapseItem.info }); }}>
                {collapseItem.info ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.info || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph '>
                  Strada will collect and receive information through operating the Services and Websites, and through other interactions with Strada. Such information will include Customer Data and other information and data (“Other Information”) in a variety of ways:
                </p>
                <ul>
                  <li className='privacy-policy-paragraph-no-space'>
                    <b>Customer Data.</b>
                    {' '}
                    Customers or individuals granted access to a Workspace by a Customer (“Authorized Users”) routinely submit Customer Data (such as messages, files or other content submitted through Services accounts) to Strada when using the Services.

                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    <b> Other Information.</b>
                    {' '}
                    Strada also collects, generates and/or receives the following categories of Other Information:
                    <ol type='a'>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Workspace and account information:</b>
                        {' '}
                        To create or update a Workspace account, you or our Customer (e.g. your employer) supply Strada with an email address, phone number, password, domain, and/or other account set up details. In addition, Customers that purchase a paid version of the Services provide Strada (or its payment processors) with billing details such as credit card information, banking information, and/or a billing address.
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Usage information:</b>
                        {' '}
                        <ul className='inner-li'>
                          <li className='privacy-policy-paragraph-no-space'>
                            Services metadata. When an Authorized User interacts with the Services, metadata is generated that provides additional context about the way that an Authorized User uses the Services. For example, Strada logs the Workspaces, channels, people, features, content, and links you view or interact with, the types of files shared and what Third-Party Services are used (if any)
                          </li>
                          <li className='privacy-policy-paragraph-no-space'>
                            Log data. As with most websites and technology services delivered over the Internet, our servers automatically collect information when you access or use our Websites or Services and record it in log files. This log data may include your Internet Protocol (IP) address, the address of the web page you visited before using the Website or Services, browser type and settings, the date and time the Services were used, information about browser configuration and plugins, and your language preferences.
                          </li>
                          <li className='privacy-policy-paragraph-no-space'>
                            Device information. Strada collects information about devices accessing the Services, including type of device, what operating system is used, device settings, application IDs, unique device identifiers and crash data. Whether we collect some or all of this Other Information often depends on the type of device used and its settings.
                          </li>
                          <li className='privacy-policy-paragraph-no-space'>
                            Location information. We receive information from you, our Customers and other third-parties that hes approximate your location. We may, for example, use a business address submitted by your employer (who is our Customer) or an IP address received from your browser or device to determine approximate location to assist with localization or for security purposes.
                          </li>
                        </ul>
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Cookie information:</b>
                        {' '}
                        Strada uses a variety of cookies and similar technologies in our Websites and Services to help us collect Other Information.
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Third-Party Services information:</b>
                        {' '}
                        A Customer can choose to permit or restrict Third-Party Services for its Workspace and Strada can receive personal data from such Third-Party Services. Typically, Third-Party Services are software that integrate with our Services, and a Customer can permit its Authorized Users to enable and disable these integrations for its Workspace. Strada may also develop and offer Strada applications that connect the Services with a Third-Party Service. Once enabled, the provider of a Third-Party Service may share certain information with Strada. For example, if a cloud storage application you are using is enabled to permit files to be imported to a Workspace, we may receive the user name and email address of Authorized Users, along with additional information that the application makes available to Strada to facilitate the integration. Authorized Users should check the privacy settings and notices in these Third-Party Services to understand what data may be disclosed to Strada. When a Third-Party Service is enabled, Strada is authorized to connect and access Other Information made available to Strada in accordance with our agreement with the provider of the Third-Party Service and any permission(s) granted by our Customer (including, by its Authorized User(s)). Examples of information which Strada may receive in this manner include whether you successfully created a new account or interacted with a third-party application in a way that is attributable to Strada usage activity. We do not, however, receive or store passwords for any of these Third-Party Services when connecting them to the Services.
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Contact information:</b>
                        {' '}
                        In accordance with the consent process provided by your device or other third-party API, any contact information that an Authorized User chooses to import (such as importing an address book to find coworkers and Strada Connect contacts or calendar from a device or API), forward or upload to the Services (for example, when sending emails to the Services) is collected when using the Services.
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Third-Party data:</b>
                        {' '}
                        Strada may receive data about organizations, industries, lists of companies that are customers, Website visitors, marketing campaigns and other matters related to our business from parent corporation(s), affiliates and subsidiaries, our partners, or others that we use to make our own information better or more useful. This data may be combined with Other Information we collect and might include aggregate-level data, such as which IP addresses correspond to zip codes or countries. Or it might be more specific: for example, how well an online marketing or email campaign performed.
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        <b>Additional information provided to Strada:</b>
                        {' '}
                        We also receive Other Information when submitted to our Websites or in other ways, such as responses or opinions you provide if you participate in a focus group, contest, activity or event, feedback you provide about our products or services, information you provide if you apply for a job with Strada, enroll in a certification program or other educational program hosted by Strada or a vendor, request support, interact with our social media accounts or otherwise communicate with Strada.
                      </li>
                    </ol>
                  </li>
                </ul>
                <p className='privacy-policy-paragraph'>
                  Generally, no one is under a statutory or contractual obligation to provide any Customer Data or Other Information (collectively, “Information”). However, certain Information is collected automatically and, if some Information, such as Workspace setup details, is not provided, we may be unable to provide the Services.
                </p>
              </>
            )}
          </Element>
          {' '}
          {/*   section-4 */}
          <Element name='How We Process Your Information and our Legal Bases for Doing So'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>How We Process Your Information and our Legal Bases for Doing So</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, legal: !collapseItem.legal }); }}>
                {collapseItem.legal ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.legal || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph'>
                  Customer Data will be used by Strada in accordance with a Customer’s instructions, including to provide the Services, any applicable terms in the Customer Agreement, a Customer’s use of Services functionality, and as required by applicable law. Strada is a processor of Customer Data and the Customer is the controller. Customer may, for example, use the Services to grant and remove access to a Workspace, assign roles and configure settings, access, modify, export, share, and remove Customer Data, and otherwise apply its policies to the Services.
                </p>
                <p className='privacy-policy-paragraph'>
                  Strada uses Other Information to operate our Services, Websites, and business. More specifically, Strada uses Other Information for the following purposes:
                </p>
                <p className='privacy-policy-sub-heading'>
                  Compliance With A Legal Obligation:
                </p>
                <p className='privacy-policy-paragraph'>
                  Strada processes Other Information when we comply with a legal obligation including, for example, to access, preserve or disclose certain information if there is a valid legal request from a regulator, law enforcement or others. For example, a search warrant or production order from law enforcement to provide information in relation to an investigation, such as your profile picture or IP address.
                </p>
                <p className='privacy-policy-paragraph'>
                  We use Workspace and account information, Usage information, Cookie information, Third-Party Services Information, Contact information, Third-Party data, Audio and video metadata, and Additional information provided to Strada for compliance with a legal obligation.
                </p>
                <p className='privacy-policy-sub-heading'>
                  Legitimate Interests:
                </p>
                <p className='privacy-policy-paragraph'>
                  We rely on our legitimate interests or the legitimate interests of a third-party where they are not outweighed by your interests or fundamental rights and freedoms ("legitimate interests").
                </p>
                <p className='privacy-policy-paragraph'>
                  We use Workspace and account information, Usage information, Cookie information, Third-Party Services Information, Contact information, Third-Party data, and Additional information provided to Strada for the following legitimate interests:
                </p>
                <ol type='1'>
                  <li className='privacy-policy-paragraph-no-space'>
                    To provide, update, maintain and protect our Services, Websites and business.This includes the use of Other Information to support delivery of the Services under a Customer Agreement, prevent or address service errors, security or technical issues, analyze and monitor usage, trends and other activities, or at an Authorized User’s request.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our and your interests to provide, update, maintain and protect our Services, Websites, and business.
                      </li>
                    </ul>
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    To develop and provide search, learning and productivity tools and additional features.Strada tries to make the Services as useful as possible for specific Workspaces and Authorized Users. For example, we may:
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        improve search functionality by using Other Information to help determine and rank the relevance of content, channels or expertise to an Authorized User;
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        make Services or Third-Party Service suggestions based on historical use and predictive models;
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        identify organizational trends and insights;
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        customize a Services experience; or
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        create new productivity features and products.
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our interest and in the interest of Customers and Authorized Users to continuously improve and develop the customer support we provide.
                      </li>
                    </ul>
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    To investigate and help prevent security issues and abuse.We may process, including in an automated fashion, Other Information to better understand how Strada is used or to prevent spam or abuse.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our interest to keep the Service secure and to detect, prevent, and address abuse (such as spam) and to investigate and take action in respect of suspicious activity on the Services.
                      </li>
                    </ul>
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    To aggregate or de-identify information.In some cases, we aggregate or de-identify information we have associated with you and use the resulting information, for example, to improve the Services.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our interest to research and improve the Services;
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in the interests of Customers and Authorized Users to practice data minimisation and privacy by design in respect of their information.
                      </li>
                    </ul>
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Share information with others including law enforcement and to respond to legal requests.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our interest and the interest of the general public to prevent and address fraud, unauthorized use of Strada, violations of our terms or other harmful or illegal activity; to protect ourselves, our users or others, including as part of investigations or regulatory enquiries; or to prevent death or imminent bodily harm.
                      </li>
                    </ul>
                  </li>
                </ol>
                <p className='privacy-policy-paragraph'>
                  We use Workspace and account information, Third-Party Services Information, Third-Party data, and Additional information provided to Strada for the following legitimate interests:
                </p>
                <ol type='1'>
                  <li className='privacy-policy-paragraph-no-space'>
                    To communicate with you by responding to your requests, comments and questions.If you contact us, we may use your Other Information to respond.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our, our Customers’ and Authorized Users’ interests to facilitate communication (for example to answer questions from Customers).
                      </li>
                    </ul>
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    To send service emails and other communications.For example, we may:
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        send you service, technical and other administrative emails, messages, and other types of communications; or
                      </li>
                      <li className='privacy-policy-paragraph-no-space'>
                        contact you to inform you about changes in our Services, our Services offerings, and important Services-related notices, such as security and fraud notices. These communications are considered part of the Services and you may not opt out of them. It is in our Customers and Authorized Users’ interests to address service related issues.
                      </li>
                    </ul>
                  </li>
                </ol>
                <p className='privacy-policy-paragraph'>
                  We use Workspace and account information and Usage information for the following legitimate interests:
                </p>
                <ol type='1'>
                  <li className='privacy-policy-paragraph-no-space'>
                    For billing, account management and other administrative matters.Strada may need to contact you for invoicing, account management, and similar reasons and we use account data to administer accounts and keep track of billing and payments.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our interest to facilitate the effective provision and administration of the Websites and Services.
                      </li>
                    </ul>
                  </li>
                </ol>
                <p className='privacy-policy-paragraph'>
                  We use Workspace and account information, Usage Information, Cookie information, Third-Party Services Information, Third-Party data, and Additional information provided to Strada for the following legitimate interests:
                </p>
                <ol type='1'>
                  <li className='privacy-policy-paragraph-no-space'>
                    To send marketing emails and other communications.We sometimes send emails about new product features, promotional communications or other news about Strada. These are marketing messages so you can control whether you receive them. If you have additional questions about a message you have received from Strada please get in touch through the contact mechanisms described below.
                    <ul className='inner-li'>
                      <li className='privacy-policy-paragraph-no-space'>
                        It is in our interest to promote the Websites and Services and send our direct marketing.
                      </li>
                    </ul>
                  </li>
                </ol>
              </>
            )}
          </Element>
          {/*   section-5 */}
          <Element name='How We Share and Disclose Information'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>
                How We Share and Disclose Information
              </p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, share: !collapseItem.share }); }}>
                {collapseItem.share ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>
            {(!collapseItem.share || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph'>
                  This section describes how Strada may share and disclose Information, as described in the section entitled 'Information We Collect and Receive' above. Customers determine their own policies and practices for the sharing and disclosure of Information to third parties. Strada does not control how a Customer or any third party chooses to share or disclose Information.
                </p>
                <ul>
                  <li className='privacy-policy-paragraph-no-space'>
                    The Customer’s Instructions. Strada may share and disclose Information in accordance with a Customer’s instructions and with appropriate consent, including any applicable terms in the Customer Agreement and the Customer’s use of Services functionality and in compliance with applicable law and legal process. Some sharing at a Customer’s request may incur additional fees.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Displaying the Services. When an Authorized User submits Information, it may be displayed or discoverable to other Authorized Users in the same or connected Workspaces or Strada Connect instances. For example, an Authorized User’s email address may be displayed with their Workspace profile, or other profile and organizational information may be displayed to Authorized Users.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Collaborating with Others. The Services provide different ways for Authorized Users working in independent Workspaces to collaborate, such as email interoperability. Information, such as an Authorized User’s profile and organizational information, may be shared, subject to the policies and practices of the Workspace(s) you use. For example, depending on the settings of your Workspace, to enable connections with other Authorized Users, your profile may be shared or searchable or discoverable by Authorized Users or other users outside of Workspace(s) you belong to, or shared via email when you invite an Authorized User or other user to collaborate via Strada. In many instances, Strada includes either administrator controls or user controls, depending on the use case, with respect to external collaboration. Authorized Users may also decide to expand the visibility of certain content and Customer Data, such as files.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Customer access. Owners, administrators, Authorized Users, and other Customer representatives and personnel may be able to access, modify, or restrict access to Information. This may include, for example, your employer using features of the Services to access or modify your profile details, or to export logs of Workspace activity.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Third-Party service providers and partners. We may engage third-party companies or individuals as service providers or business partners to process Information and support our business. These third parties may, for example, provide virtual computing and storage services, assist Strada with verifying Owners and Customers, or we may share business information to develop strategic partnershith Third-Party service providers to support our common customers. In this respect, depending on the Third-Party service provided, Strada may share your Information.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Third-Party Services. A Customer may enable, or permit Authorized Users to enable, Third-Party Services. We require each Third-Party Service provider to disclose all permissions for information accessible through the Services, but we do not guarantee that they do so. When Third-Party Services are enabled by a Customer or an Authorized User, Strada may share Information with Third-Party Services. Third-Party Services are not owned or controlled by Strada and third parties that have been granted access to Information may have their own policies and practices for its collection, use, and sharing. Please check the permissions, privacy settings, and notices for these Third-Party Services or contact the provider for any questions.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Organizers and sponsors of Events/Webinars. If you attend an event or webinar organized by Strada, we may share your profile and organizational information with the event or webinar sponsors when you register, have your badge scanned, or join a breakout room. If required by applicable law, you may consent to such sharing via the registration form or by allowing your attendee badge to be scanned at a sponsor booth. In these circumstances, your information will be subject to the sponsors’ privacy statements. For more information, please refer to the terms provided when you register for such an event or webinar.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Professional advisers. We may share your Information with professional advisers acting as service providers, processors, controllers, or joint controllers - including lawyers, bankers, auditors, and insurers who provide consultancy, banking, legal, insurance and accounting services, and to the extent we are legally obliged to share or have a legitimate interest in sharing your Information containing personal data.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Corporate Affiliates. Strada may share Information with our corporate affiliates, parents and/or subsidiaries.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    During a Change to Strada’s Business. If Strada engages in a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all of Strada’s assets or stock, financing, public offering of securities, acquisition of all or a portion of our business, a similar transaction or proceeding, or stn contemplation of such activities, some or all of the Information described in the ‘Information We Collect and Receive’ section may be shared or transferred, subject to standard confidentiality arrangements.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Aggregated or De-identified Data. We may disclose or use aggregated or de-identified Information for any purpose. For example, we may share aggregated or de-identified Information with prospects or partners for business or research.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Law Enforcement and Regulators. If we receive a request for information, we may disclose Other Information if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation or legal process.
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    To enforce our rights, prevent fraud, and for safety. To protect and defend the rights, property, or safety of Strada, its users, or third parties, including enforcing its contracts or policies, or in connection with investigating and preventing illegal activity, fraud, or security issues, including to prevent death or imminent bodily harm.
                  </li>
                </ul>
                <p className='privacy-policy-paragraph'>
                  With Consent. Strada may share Information with third parties when we have consent to do so or as otherwise permitted in this Privacy Policy. For Workspaces registered to corporate entities, Strada may share Information with consent of the Workspace primary owner or authorized corporate officer, or their designee. For workplaces created without a formal affiliation, Strada may require user consent.
                </p>
              </>
            )}
          </Element>
          {/*   section-6 */}
          <Element name='Data Retention'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>
                Data Retention
              </p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, retention: !collapseItem.retention }); }}>
                {collapseItem.retention ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>

            {(!collapseItem.retention || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph'>
                  Strada will retain Customer Data in accordance with a Customer’s instructions (including to perform any applicable terms in the Customer Agreement and through Customer’s use of Services functionality) and as required by applicable law. Customer may customize its retention settings and, depending on the Services plan, apply those customized settings at the Workspace level, channel level or other level. The Customer may also apply different settings to messages, files or other types of Customer Data. The deletion of Customer Data and other use of the Services by the Customer may result in the deletion and/or de-identification of certain associated Other Information.
                </p>
                <p className='privacy-policy-paragraph'>
                  Strada may retain Other Information pertaining to you for as long as necessary for the purposes described in this Privacy Policy (such as to provide the Services, including any optional features you use, and to provide customer support). This may include keeping your Other Information after you have deactivated your account for the period of time needed for Strada to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with) legal obligations, resolve disputes, and enforce our agreements.
                </p>
              </>
            )}
          </Element>
          {/*   section-7 */}
          <Element name='Security'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Security</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, security: !collapseItem.security }); }}>
                {collapseItem.security ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>

            {(!collapseItem.security || window.innerWidth > 768)
            && (
              <p className='privacy-policy-paragraph'>
                Strada takes security of data very seriously. Strada works hard to protect Information you provide from loss, misuse, and unauthorized access or disclosure. These stake into account the sensitivity of the Information we collect, process and store, and the current state of technology. Given the nature of communications and information processing technology, Strada cannot guarantee that Information during transmission through the Internet or while stored on our systems or otherwise in our care will be absolutely safe from intrusion by others. When you click a link to a third-party site, you will be leaving our site and we don’t control or endorse what is on third-party sites.
              </p>
            )}
          </Element>
          {/*   section-8 */}
          <Element name='Age Limitations'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>
                Age Limitations
              </p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, age: !collapseItem.age }); }}>
                {collapseItem.age ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>

            {(!collapseItem.age || window.innerWidth > 768)
            && (
              <p className='privacy-policy-paragraph'>
                Strada does not allow use of our Services and Websites by anyone younger than 16 years old, to the extent prohibited by applicable law. If you learn that anyone younger than 16 has unlawfully provided us with personal data, please contact us and we will take sto delete such information.
              </p>
            )}
          </Element>
          {/*   section-9 */}
          <Element name='Changes To This Privacy Policy'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Changes To This Privacy Policy</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, privacy: !collapseItem.privacy }); }}>
                {collapseItem.privacy ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>

            {(!collapseItem.privacy || window.innerWidth > 768)
            && (
              <p className='privacy-policy-paragraph'>
                Strada may change this Privacy Policy from time to time. Laws, regulations, and industry standards evolve, which may make those changes necessary, or we may make changes to our services or business. We will post the changes to this page and encourage you to review our Privacy Policy to stay informed. If we make changes that materially alter your privacy rights, Strada will provide additional notice, such as via email or through the Services. If you disagree with the changes to this Privacy Policy, you should deactivate your Services account. Contact the Customer if you wish to request the removal of Personal Data under their control.
              </p>
            )}
          </Element>
          {/*   section-10 */}
          <Element name='Your California Privacy Rights'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>Your California Privacy Rights</p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, california: !collapseItem.california }); }}>
                {collapseItem.california ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>

            {(!collapseItem.california || window.innerWidth > 768)
            && (
              <>
                <p className='privacy-policy-paragraph'>
                  This section provides additional details about the personal information we collect about California consumers and the rights afforded to them under the California Consumer Privacy Act or “CCPA,” as amended by the California Privacy Rights Act or “CPRA”.

                </p>
                <p className='privacy-policy-paragraph'>
                  California law requires that we detail the categories of personal information that we collect and disclose for certain “business purposes,” such as to service providers that assist us with securing our services or marketing our products, and to such other entities as described in earlier sections of Privacy Policy. In addition to the information provided above in the ‘Information We Collect And Receive’ section, we collect the following categories of personal information from you, your employer, data analytics providers, data brokers, and Third-Party Services for our business purposes:
                </p>
                <ul>
                  <li className='privacy-policy-paragraph-no-space'>
                    Identifiers/contact information;
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Commercial information;
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Internet or electronic network activity information;
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Financial information;
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Geolocation information;
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Professional or employment-related information;
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    In limited circumstances where allowed by law, information that may be protected under California or United States law; and
                  </li>
                  <li className='privacy-policy-paragraph-no-space'>
                    Inferences drawn from any of the above categories.
                  </li>
                </ul>
              </>
            )}
          </Element>
          {/*   section-11 */}
          <Element name='Contacting Strada'>
            <div aria-hidden='true' className='privacy-policy-collapse-heading'>
              <p className='privacy-policy-sub-heading'>
                Contacting Strada
              </p>
              <div className='collapse-icon' aria-hidden='true' onClick={(): void => { setCollapseItem({ ...collapseItem, contacting: !collapseItem.contacting }); }}>
                {collapseItem.contacting ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
            </div>

            {(!collapseItem.contacting || window.innerWidth > 768)
            && (
              <p className='privacy-policy-paragraph'>
                Please also feel free to contact Strada if you have any questions about this Privacy Policy or Strada’s practices, or if you are seeking to exercise any of your statutory rights. Strada will respond within a reasonable timeframe. You may contact us at
                {' '}
                <a
                  href='mailto:feedback@strada.ai'
                  className='privacy-policy-email-link'
                >
                  feedback@strada.ai
                </a>
              </p>
            )}
          </Element>
        </div>
      </div>
    </div>
  );
}

export default Content;
