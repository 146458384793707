import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import PrimayButton from 'shared-components/components/PrimayButton';
import axios from 'axios';
import './_accountSetting.scss';

function Signature(): JSX.Element {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [editorContent, setEditorContent] = useState<string | undefined>('');
  const [isNotChanged, setIsNotChanged] = useState<boolean>(true);

  const handleEditorChange = (content: string): void => {
    setEditorContent(content);
    setIsNotChanged(false);
  };
  useQuery(
    'get/signature-value',
    async () => axios({
      url: '/api/users/',
      method: 'GET',
    }),
    {
      onSuccess: (res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setEditorContent(res.data[0].email_signature);
      },
    },
  );

  const { mutate: handleSendSignature } = useMutation(async () => axios({
    url: 'api/users/set_signature/',
    method: 'patch',
    data: { email_signature: editorContent },
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('get/notification-value');
      enqueueSnackbar('Signature has been Saved Successfully');
      setIsNotChanged(true);
    },
    onError: () => {
      enqueueSnackbar('Error in Sending Signature', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFilePicker = (callback: (arg0: ArrayBuffer | string | null, arg1: { alt: string }) => void) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/jpg, image/jpeg, image/png, image/webp');
    input.onchange = (): void => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const imageDataUrl = reader.result;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          callback(imageDataUrl, { alt: file.name });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  return (
    <div className='toggler-container'>
      <Typography className='notification-header mb-3'>
        Signature
      </Typography>
      <Editor
        apiKey='1y7zut3pxyomlx5vhlj7wuh2q7r7sd4w8x7oevrxn05o07fq'
        value={editorContent}
        init={{
          height: 250,
          menubar: false,
          branding: false,
          toolbar_mode: 'scrolling',
          plugins: [
            'advlist lists image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help ',
          ],
          toolbar: 'undo redo | styleselect | fontsizeselect | backcolor | bold italic underline|  bullist numlist | outdent indent| image',
          file_picker_types: 'file image media',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          file_picker_callback: handleFilePicker as any,
          content_style:
            'body { font-family: Arial, sans-serif; font-size: 14px; margin: 0; padding: 10px;line-height: 1; }',
        }}
        onEditorChange={handleEditorChange}
      />
      <Box className='mt-3' style={{ width: '140px' }}>
        <PrimayButton
          disabled={isNotChanged}
          onClick={(): void => { handleSendSignature(); }}
        >
          Save signature
        </PrimayButton>
      </Box>
    </div>
  );
}

export default Signature;
