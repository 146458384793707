/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Box, Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import type { PDFGroup } from '../types';

interface ICombinedUploadProps {
  onGroupsChange: (groups: PDFGroup[]) => void;
  formatFileSize: (size: number) => string;
}

function CombinedUpload(props: ICombinedUploadProps): JSX.Element {
  const { onGroupsChange, formatFileSize } = props;

  const [pdfGroups, setPdfGroups] = useState<PDFGroup[]>([]);
  const [nextGroupId, setNextGroupId] = useState(1); // Unique counter for group IDs

  useEffect(() => {
    onGroupsChange(pdfGroups);
  }, [pdfGroups, onGroupsChange]);

  const onDrop = useCallback((acceptedFiles: File[], groupId: number) => {
    setPdfGroups((prevGroups) => prevGroups.map((group) => {
      if (group.id === groupId) {
        return { ...group, files: [...group.files, ...acceptedFiles] };
      }
      return group;
    }));
  }, []);

  const handleFileDelete = (groupId: number, fileIndex: number): void => {
    setPdfGroups((prevGroups) => prevGroups.map((group) => {
      if (group.id === groupId) {
        const updatedFiles = group.files.filter((_, index) => index !== fileIndex);
        return { ...group, files: updatedFiles };
      }
      return group;
    }));
  };

  const handleAddGroup = (): void => {
    setPdfGroups([...pdfGroups, { id: nextGroupId, files: [] }]);
    setNextGroupId(nextGroupId + 1); // Increment ID for next group
  };

  const handleDeleteGroup = (groupId: number): void => {
    setPdfGroups(pdfGroups.filter((group) => group.id !== groupId));
  };

  function DropzoneArea({ groupId }: { groupId: number }): JSX.Element {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: {
        'text/pdf': ['.png', '.jpg', '.jpeg', '.pdf'],
      },
      maxSize: 30000000,
      onDrop: (acceptedFiles) => { onDrop(acceptedFiles, groupId); },
    });

    return (
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        <Box className='preview-container-lease d-flex' sx={{ background: 'transparent', justifyContent: 'center', height: '8rem !important' }}>
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Drag and drop, or click to select files</p>
          )}
        </Box>
      </div>
    );
  }

  return (
    <div className='combined-upload'>
      <div className='d-flex justify-content-between'>
        <h5>
          Combined Upload
        </h5>
        {pdfGroups.length === 0 ? (
          <Button
            variant='outlined'
            className='text-transform-none text-dark'
            style={{ border: '1px solid #e4e4e4', padding: '5px 10px' }}
            onClick={handleAddGroup}
          >
            <FolderIcon style={{ color: '#388e3c', marginInlineEnd: '10px' }} />
            Add Lease Files
          </Button>
        ) : null}
      </div>

      {pdfGroups.map((group, index) => (
        <div key={group.id} className='pdf-group'>
          <div className='group-header d-flex justify-content-between'>
            <span className='mb-2'>{`Lease ${index + 1}`}</span>
            <div className='control-area cursor-pointer'>
              <DeleteIcon className='delete-icon' onClick={(): void => { handleDeleteGroup(group.id); }} />
            </div>
          </div>

          <DropzoneArea groupId={group.id} />

          {group.files.length > 0 && group.files.map((file, fileIndex) => (
            <div className='input-wrap-lease mb-6' key={`${group.id}-${file.lastModified}-${Math.random()}`}>
              <div className='rows p-0'>
                <InsertDriveFileIcon fontSize='medium' />
                <div className='attachment'>
                  <div className='attachment-name'>
                    <span>
                      {file.name}
                      {' '}
                      <span style={{ fontSize: '0.85em' }}>
                        (
                        {formatFileSize(file.size)}
                        )
                      </span>
                    </span>
                  </div>
                </div>
                <div className='control-area cursor-pointer'>
                  <HighlightOffIcon className='delete-icon' onClick={(): void => { handleFileDelete(group.id, fileIndex); }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}

      {pdfGroups.length >= 1 ? (
        <Button
          variant='outlined'
          className='text-transform-none text-dark'
          style={{ border: '1px solid #e4e4e4', padding: '5px 10px' }}
          onClick={handleAddGroup}
        >
          <FolderIcon style={{ color: '#388e3c', marginInlineEnd: '10px' }} />
          Add Lease Files
        </Button>
      ) : null}
    </div>
  );
}

export default CombinedUpload;
