interface DateRange {
  startDate: Date;
  endDate: Date;
}

const addYearsToDate = (date: Date, years: number): Date => {
  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + years);
  return newDate;
};

const getThreeYearsAheadAndBehind = (): DateRange => {
  const currentDate = new Date();
  const startDate = addYearsToDate(currentDate, -5);
  const endDate = addYearsToDate(currentDate, 3);

  return {
    startDate,
    endDate,
  };
};

export const useThreeYearDateRange = (): DateRange => getThreeYearsAheadAndBehind();
